@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,700&family=Libre+Franklin:wght@100;200;300;400;500;600;700;800;900&family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,700&family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&family=Heebo:wght@100;200;300;400;500;600;700;800;900&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,700&display=swap");

.root {
  background: #18172a;
  position: relative;
  z-index: 9999;
}

.slider {
  background-image: url(../../public/icons/slider-bg.jpg);
  padding: 100px 0;
  background-repeat: no-repeat;
}

.slider .slider-heading span {
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  background: linear-gradient(
    270deg,
    #99663b 27.69%,
    #c28134 53.45%,
    #f6d265 78.24%,
    #fde978 97.57%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 14px;
}

.navbar-collapse {
  justify-content: end;
  padding-right: 50px;
}

.slider .slider-heading h3 {
  font-weight: 500;
  font-size: 40px;
  line-height: 58px;
  margin-bottom: 0;
  color: #ffffff;
  margin-bottom: 30px;
}

.slider .slider-heading .launching-btn {
  background: #d9ae69;
  mix-blend-mode: normal;
  border-radius: 60px;
  display: inline-block;
  position: relative;
}

.slider .slider-heading .launching-btn a {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  align-items: center;
  text-decoration: none;
  padding: 10px 15px 10px 60px;
  color: #ffffff;
  display: block;
}

.slider .slider-heading .launching-btn::before {
  content: "";
  background-image: url(../../public/icons/arrow-right.png);
  height: 28px;
  width: 28px;
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 6%;
  transform: translateY(-50%);
}

.slider .slider-heading .launching-btn::after {
  content: "";
  background: #ca9344;
  filter: blur(2px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 5%;
  left: 2%;
}

.unicoin {
  padding: 100px 0 0;
}

.unicoin .unicoin-heading h3 {
  color: #b6904a;
  font-weight: 600;
  font-size: 100px;
  margin-bottom: 0;
}

.unicoin .unicoin-heading img {
  width: 50%;
}

.unicoin .unicoin-heading h3 span {
  font-weight: 600;
  font-size: 30px;
  position: absolute;
  vertical-align: top;
}

.unicoin .unicoin-heading h6 {
  font-weight: 600;
  font-size: 45px;
  color: #fff;
  margin-bottom: 0;
}

.unicoin-box {
  background: #2d2a62;
  border-radius: 20px;
  position: relative;
  padding: 80px 30px 20px 30px;
  margin-bottom: 140px;
}

.unicoin-box img {
  position: absolute;
  top: -59%;
  left: 50%;
  transform: translateX(-50%);
}

.unicoin-box p {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 0;
}

.unicon-title {
  display: flex;
  border-top: 3px solid #d9ae69;
  align-items: center;
  padding: 36px 0;
}

.unicon-title h4 {
  font-weight: 600;
  font-size: 24px;
  font-family: "Quicksand";
  color: #e49311;
  margin-bottom: 0;
  width: 40%;
}

.unicon-title p {
  font-weight: 400;
  font-family: "Quicksand";
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 0;
}

.unicoin-store .unicoin-store-heading h3 {
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
  margin-top: 25%;
  margin-bottom: 50px;
}

.unicoin-store .unicoin-store-heading h3::before {
  content: "Unicoin as Store of Value";
  font-weight: 600;
  font-size: 180px;
  color: #ffffff;
  opacity: 0.05;
  position: absolute;
  left: -4%;
  overflow: hidden;
  white-space: nowrap;
  width: 104%;
  margin-top: -9%;
}

.unicoin-store .unicoin-physical-heading h3::before {
  content: "Unicoin as Digital Cash";
}

.unicoin-store .unicoin-electronic-heading h3::before {
  content: "Unicoin as Electronic Cash";
}

.unicoin-store .unicoin-bitcoin-heading h3::before {
  content: "Unicoin vs Bitcoin";
}

.unicoin-store .best-innovation-heading h3::before {
  content: "Best Innovation of the Year";
}

.unicoin-store .roadmap-heading p {
  font-family: "Quicksand";
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 160px;
  color: #ffffff;
}

.unicoin-store .roadmap-heading h3::before {
  content: "Development Roadmap";
}

.unicoin-store .trophy-heading {
  font-weight: 400;
  font-size: 40px;
  margin: 50px 0;
  color: #a23c29;
}

.unicoin-store .unicoin-store-text p a {
  color: #96b9da;
  text-decoration: none;
}

.unicoin-store .unicoin-store-text p {
  font-family: "Quicksand";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 50px;
  width: 94%;
}

.roadmap-flow p {
  font-family: "Quicksand";
  font-weight: 400;
  font-size: 23px;
  line-height: 32px;
  color: #ffffff;
  width: 46%;
  margin: auto;
}

.roadmap-flow h6 {
  font-family: "Quicksand";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  width: 46%;
  text-align: center;
  position: relative;
  color: #d9ae69;
  margin: auto;
}

.roadmap-flow h6::after {
  content: "";
  background-image: url(../../public/icons/Version-line.png);
  width: 260%;
  height: 160px;
  position: absolute;
  left: -72%;
  top: 34%;
  background-repeat: no-repeat;
  background-size: contain;
}

.second {
  justify-content: center;
}

.second p {
  width: 58%;
}

.second h6::after {
  left: -82%;
}

.roadmap-bg {
  position: relative;
  overflow: hidden;
}

.roadmap-bg::after {
  content: "";
  background-image: url(../../public/icons/Version-line-1.png);
  position: absolute;
  width: 165px;
  height: 1613px;
  top: 40%;
  background-repeat: no-repeat;
  left: 50%;
  transform: translateX(-50%);
  background-size: contain;
}

.footer {
  background-color: #2c2a62;
  margin-top: 100px;
  padding: 30px 0 35px;
}

.footer .footer-text h5 {
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
}

.footer .footer-text h5 span {
  font-weight: 600;
  font-size: 10px;
  vertical-align: top;
}

.footer .footer-text a {
  font-weight: 400;
  font-size: 20px;
  text-decoration: none;
  color: #e49311;
  margin: 25px 0 40px;
  display: block;
}

.footer .footer-text p {
  font-weight: 400;
  font-size: 20px;
  color: #78bee6;
  margin-bottom: 0;
}

.difference {
  padding: 150px 0 300px;
}

.difference .difference-table {
  background: #ffffff;
  border: 2px solid #1a2b4a;
  border-radius: 20px;
  padding: 120px 45px 45px;
  position: relative;
}

.difference .difference-table .difference-table-box .difference-table-icon {
  width: 180px;
  height: 180px;
  text-align: center;
  border-radius: 50%;
  line-height: 104px;
  background: linear-gradient(
    89.81deg,
    #99663b 2.12%,
    #c28134 32.49%,
    #f6d265 61.72%,
    #fde978 84.51%
  );
  margin: auto;
  margin-top: -56%;
  margin-bottom: 30px;
}

.difference .difference-table .difference-table-box .difference-table-icon h3 {
  font-family: "Quicksand";
  font-weight: 600;
  width: 55%;
  margin: auto;
  font-size: 24px;
  margin-bottom: 0;
  color: #18172a;
}

.retings-star-icon:last-child {
  margin-bottom: 70px;
}

.retings-star-icon {
  margin-bottom: 75px;
}

.retings-star-icon-1 {
  margin-bottom: 87px;
}

.difference .difference-table .difference-table-box h4 {
  font-family: "Quicksand";
  font-weight: 600;
  font-size: 24px;
  color: #b6904a;
}

.difference .difference-table .border::after {
  content: "";
  width: 2px;
  height: 100%;
  background: #1a2b4a;
  position: absolute;
  top: 0;
  right: -9%;
}

.difference .difference-table .border::before {
  content: "";
  width: 2px;
  height: 100%;
  background: #1a2b4a;
  position: absolute;
  top: 0;
  left: -9%;
}

.difference .difference-table .border {
  border-right: 0px solid #1a2b4a !important;
  border-left: 0px solid #1a2b4a !important;
  /*padding: 0 22px;*/
  border-bottom: 0 !important;
  border-top: 0 !important;
  position: relative;
}

.difference .difference-table .difference-table-box p {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1a2b4a;
}

.ratings .ratings-box {
  background-color: #28271d;
  border-radius: 20px;
}

.ratings .ratings-box .ratings-box-icon {
  background: linear-gradient(109.42deg, #9e6831 2.58%, #fff07f 99.86%);
  border-radius: 20px 0px 0px 20px;
  height: 100%;
  text-align: center;
  padding: 30px 25px;
}

.ratings .ratings-box .ratings-box-icon img {
  margin-bottom: 20px;
}

.ratings .ratings-box .ratings-box-icon h3 {
  font-weight: 500;
  font-family: "Quicksand";
  font-size: 32px;
  color: #28271d;
  margin-bottom: 0;
}

.ratings .ratings-box .ratings-box-text {
  padding: 25px 0;
}

.ratings .ratings-box .ratings-box-text h5 {
  font-family: "Quicksand";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 12px;
}

.ratings-star .ratings-star-bg {
  background: #ffffff;
  border: 1px solid #cecece;
  padding: 40px;
  border-radius: 20px;
}

.ratings-star .ratings-star-bg .unicoin-rating h3 {
  font-family: "Quicksand";
  font-weight: 500;
  font-size: 32px;
  color: #232323;
  margin-bottom: 8px;
}

.ratings-star .ratings-star-bg .unicoin-rating img {
  margin-bottom: 89px;
}

.ratings-star .bitcoin .unicoin-rating img {
  margin-bottom: 99px;
}

.ratings {
  position: relative;
  margin: 450px 0 350px;
}

.difference-table-box {
  padding: 25px;
}

.difference .container {
  max-width: 1540px;
}

.ratings .ratings-star {
  position: absolute;
  top: -38%;
  right: 4%;
}

.slider-img {
  position: relative;
  z-index: 9;
}

.slider-img::after {
  content: "";
  background-image: url(../../public/icons/slider-mobile-bg.png);
  height: 732px;
  width: 1433px;
  position: absolute;
  z-index: -9;
  top: -30%;
  right: -40%;
}

@media (max-width: 767px) {
  .slider {
    padding: 50px 0;
    background-position: top;
  }

  .difference-table-box {
    padding: 0px;
  }

  .difference .difference-table .border::after {
    width: 0px;
  }

  .ratings-star .bitcoin .unicoin-rating img {
    margin-bottom: 30px !important;
  }

  .difference .difference-table .border::before {
    width: 0px;
  }

  .slider .slider-heading span {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .slider .slider-heading h3 {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 18px;
  }

  .slider .slider-heading .launching-btn a {
    font-size: 15px;
    line-height: 24px;
    padding: 8px 8px 8px 50px;
  }

  .slider .slider-heading .launching-btn::after {
    width: 40px;
    height: 40px;
  }

  .slider-img img {
    width: 100%;
  }

  .slider-img::after {
    height: 292px;
    width: 510px;
    top: -37%;
    right: 0;
    background-size: cover;
    opacity: 0.4;
  }

  .unicoin .unicoin-heading h3 {
    font-size: 50px;
  }

  .unicoin .unicoin-heading h3 span {
    font-size: 20px;
  }

  .unicoin .unicoin-heading h6 {
    font-size: 31px;
  }

  .unicoin .unicoin-heading img {
    width: 100%;
  }

  .unicoin {
    padding: 50px 0;
  }

  .unicoin-box .coine-img {
    width: 100%;
    top: -31%;
  }

  .unicoin-box img {
    top: -44%;
  }

  .unicoin-box {
    padding: 80px 20px 20px 20px;
    margin-bottom: 70px;
  }

  .unicon-title h4 {
    font-size: 12px;
    width: 50%;
  }

  .unicon-title p {
    font-size: 12px;
    display: flex;
  }

  .unicon-title img {
    width: 12%;
    height: 12%;
  }

  .unicon-title {
    border-top: 2px solid #d9ae69;
    padding: 20px 0;
  }

  .unicoin-store .unicoin-store-heading h3 {
    font-size: 22px;
    margin-top: 20%;
    margin-bottom: 40px;
  }

  .unicoin-store .unicoin-store-heading h3::before {
    font-size: 32px;
  }

  .unicoin-store .unicoin-store-text p {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  .sov-img {
    width: 100%;
  }

  .physical-cash-img {
    width: 100%;
  }

  .electronic-cash-img {
    width: 100%;
  }

  .ratings {
    margin: 50px 0;
  }

  .ratings .ratings-box .ratings-box-icon h3 {
    font-size: 28px;
  }

  .ratings .ratings-box .ratings-box-icon {
    border-radius: 20px 20px 0px 0px;
  }

  .ratings .ratings-box .ratings-box-text h5 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .ratings .ratings-box .ratings-box-text {
    padding: 25px 25px;
  }

  .ratings-star .ratings-star-bg {
    padding: 4px;
  }

  .ratings .ratings-star {
    position: unset;
    top: unset;
    right: unset;
  }

  .ratings-star .ratings-star-bg .unicoin-rating img {
    margin-bottom: 20px;
  }

  .difference {
    padding: 50px 0;
  }

  .difference .difference-table .difference-table-box .difference-table-icon {
    width: 150px;
    height: 150px;
    line-height: 89px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .difference
    .difference-table
    .difference-table-box
    .difference-table-icon
    h3 {
    font-size: 20px;
  }

  .difference .difference-table {
    padding: 30px 12px;
  }

  .difference .difference-table .border {
    border-right: unset !important;
    border-left: unset !important;
    padding: 0;
    border-bottom: 0 !important;
    border-top: 0 !important;
  }

  .tads-img {
    width: 100%;
  }

  .trophy-img {
    width: 100%;
  }

  .unicoin-store .trophy-heading {
    font-size: 17px;
    margin: 25px 0;
  }

  .unicoin-store .roadmap-heading p {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .roadmap-bg::after {
    display: none;
  }

  .roadmap-flow p {
    font-size: 18px;
    line-height: 28px;
    width: 100%;
    margin-right: 0;
    margin-top: 0;
  }

  .roadmap-flow h6::after {
    left: 50%;
    top: -11px;
    transform: translateX(-50%);
    width: 100%;
  }

  .roadmap-flow h6 {
    font-size: 22px;
    line-height: 0;
    width: unset;
    margin-bottom: 30px;
    margin-top: 20px;
    margin-right: 0px;
    display: inline-block;
    margin-bottom: 70px;
  }

  .roadmap-flow img {
    width: 100%;
  }

  .second h6 {
    margin-bottom: 70px;
  }

  .third img {
    margin-left: 0;
  }

  .footer {
    margin-top: 50px;
  }

  .difference .difference-table .difference-table-box h4 {
    font-size: 17px;
  }

  .difference .difference-table .difference-table-box p {
    font-size: 14px;
    line-height: 22px;
  }

  .footer .footer-text h5 {
    font-size: 18px;
  }

  .footer .footer-text a {
    font-size: 18px;
    margin: 20px 0 40px;
  }

  .footer .footer-text p {
    font-size: 18px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .slider-img::after {
    height: 512px;
    width: 877px;
    top: -34%;
    right: 0;
  }

  .slider .slider-heading .launching-btn::after {
    width: 35px;
    height: 35px;
  }

  .unicoin-box img {
    top: -65%;
  }

  .unicon-title h4 {
    font-size: 16px;
  }

  .unicon-title p {
    font-size: 14px;
    display: flex;
  }

  .unicon-title img {
    width: unset;
    height: unset;
  }

  .unicoin-store .unicoin-store-heading h3::before {
    left: 0%;
    width: 100%;
    margin-top: -3%;
  }

  .difference .difference-table {
    padding: 30px 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider .slider-heading span {
    font-size: 18px;
  }

  .difference-table-box {
    padding: 0px;
  }

  .difference .difference-table .border::after {
    right: -3%;
  }

  .difference .difference-table .border::before {
    left: -3%;
  }

  .slider .slider-heading h3 {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 16px;
  }

  .slider .slider-heading .launching-btn a {
    font-size: 14px;
    padding: 8px 8px 8px 50px;
  }

  .slider .slider-heading .launching-btn::after {
    width: 42px;
    height: 42px;
    top: 8%;
  }

  .slider-img img {
    width: 100%;
  }

  .slider-img::after {
    width: 577px;
    top: -28%;
    right: -13%;
    height: 352px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .slider {
    padding: 70px 0;
    background-position: top;
  }

  .unicoin .unicoin-heading h3 {
    font-size: 100px;
  }

  .unicoin .unicoin-heading h6 {
    font-size: 45px;
  }

  .unicoin .unicoin-heading img {
    width: 100%;
  }

  .unicoin {
    padding: 70px 0;
  }

  .unicoin-box {
    padding: 70px 20px 20px 20px;
    margin-bottom: 70px;
  }

  .unicoin-box img {
    top: -48%;
  }

  .unicon-title {
    padding: 24px 0;
  }

  .unicon-title h4 {
    font-size: 14px;
    width: 50%;
  }

  .unicon-title p {
    font-size: 14px;
  }

  .unicoin-store .unicoin-store-heading h3::before {
    font-size: 60px;
    width: 100%;
    margin-top: -6%;
    left: 0;
  }

  .unicoin-store .unicoin-store-heading h3 {
    font-size: 42px;
    margin-bottom: 40px;
    margin-top: 15%;
  }

  .unicoin-store .unicoin-store-text p {
    /* font-family: 'Quicksand'; */
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 10px;
    width: 100%;
  }

  .sov-img {
    width: 100%;
  }

  .physical-cash-img {
    width: 100%;
  }

  .electronic-cash-img {
    width: 100%;
  }

  .ratings .ratings-box .ratings-box-icon h3 {
    font-size: 26px;
  }

  .ratings .ratings-box .ratings-box-icon {
    padding: 38px 25px;
  }

  .ratings .ratings-box .ratings-box-text h5 {
    font-size: 16px;
    line-height: 22px;
  }

  .ratings .ratings-box .ratings-box-text {
    padding: 41px 0;
  }

  .ratings-star .ratings-star-bg {
    padding: 15px;
  }

  .star-icon img {
    width: 15%;
  }

  .ratings .ratings-star {
    top: -26%;
    right: -7%;
  }

  .retings-star-icon {
    margin-bottom: 109px;
  }

  .ratings-star .ratings-star-bg .unicoin-rating img {
    margin-bottom: 50px;
  }

  .retings-star-icon-1 {
    margin-bottom: 126px;
  }

  .ratings-star .bitcoin .unicoin-rating img {
    margin-bottom: 60px;
  }

  .ratings .ratings-star .text-center {
    width: 40%;
  }

  .ratings {
    margin: 200px 0 70px;
  }

  .difference
    .difference-table
    .difference-table-box
    .difference-table-icon
    img {
    width: 30%;
    margin-top: 20px;
  }

  .difference .difference-table .difference-table-box .difference-table-icon {
    width: 150px;
    height: 150px;
    line-height: 85px;
    margin-top: -200px;
  }

  .difference
    .difference-table
    .difference-table-box
    .difference-table-icon
    h3 {
    font-size: 16px;
  }

  .difference .difference-table .border {
    padding: 0px 14px;
  }

  .difference .difference-table .difference-table-box h4 {
    font-size: 15px;
  }

  .difference .difference-table .difference-table-box p {
    font-size: 12px;
    line-height: 20px;
  }

  .unicoin-store .roadmap-heading p {
    font-size: 22px;
    margin-bottom: 40px;
  }

  .roadmap-flow p {
    font-size: 18px;
    line-height: 28px;
    width: 100%;
    margin-right: 0px;
    margin-top: 0px;
  }

  .roadmap-flow h6 {
    font-size: 22px;
    line-height: 28px;
    width: 90%;
    margin-top: 0px;
    margin-right: 0px;
  }

  .roadmap-flow h6::after {
    width: 150%;
    left: -28%;
    top: 26px;
    background-size: contain;
  }

  .roadmap-flow img {
    width: 100%;
  }

  .roadmap-bg::after {
    width: 80px;
    top: 38%;
    background-size: contain;
    height: 597px;
  }

  .third img {
    margin-left: 0px;
  }

  .unicoin-store .trophy-heading {
    font-size: 35px;
    margin: 40px 0;
  }

  .footer .footer-text h5 {
    font-size: 18px;
  }

  .footer .footer-text a {
    font-size: 18px;
    margin: 15px 0 30px;
  }

  .footer .footer-text p {
    font-size: 18px;
  }

  .footer {
    margin-top: 70px;
    padding: 30px 0 35px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .slider .slider-heading span {
    font-size: 24px;
  }

  .ratings-star .ratings-star-bg .unicoin-rating img {
    margin-bottom: 40px;
  }

  .ratings-star .bitcoin .unicoin-rating img {
    margin-bottom: 50px;
  }

  .slider .slider-heading h3 {
    font-size: 35px;
    line-height: 44px;
    margin-bottom: 25px;
  }

  .slider-img img {
    width: 100%;
  }

  .slider-img::after {
    height: 532px;
    top: -25%;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 683px;
  }

  .slider {
    background-attachment: fixed;
    background-size: cover;
  }

  .unicoin .unicoin-heading h3 {
    font-size: 100px;
  }

  .unicoin .unicoin-heading img {
    width: 100%;
  }

  .unicoin .unicoin-heading h6 {
    font-size: 40px;
  }

  .unicoin {
    padding: 100px 0 0;
  }

  .unicon-title h4 {
    font-size: 22px;
    width: 45%;
  }

  .unicon-title p {
    font-size: 16px;
  }

  .unicon-title {
    padding: 32px 0;
  }

  .unicoin-box {
    padding: 80px 20px 20px 20px;
    margin-bottom: 70px;
  }

  .unicoin-box p {
    font-size: 16px;
    line-height: 22px;
  }

  .unicoin-box img {
    top: -55%;
  }

  .unicoin-store .unicoin-store-heading h3::before {
    font-size: 106px;
  }

  .unicoin-store .unicoin-store-heading h3 {
    margin-top: 18%;
    margin-bottom: 70px;
  }

  .sov-img {
    width: 100%;
  }

  .physical-cash-img {
    width: 100%;
  }

  .electronic-cash-img {
    width: 100%;
  }

  .roadmap-flow p {
    font-size: 22px;
    width: 75%;
  }

  .roadmap-flow img {
    width: 100%;
  }

  .roadmap-flow h6 {
    font-size: 22px;
    width: 64%;
  }

  .roadmap-bg::after {
    width: 109px;
    height: 862px;
    top: 37%;
    background-size: cover;
  }

  .ratings .ratings-box .ratings-box-text h5 {
    font-size: 16px;
  }

  .ratings .ratings-star {
    top: -27%;
    right: -3%;
  }

  .ratings-star .ratings-star-bg {
    padding: 14px;
  }

  .difference .difference-table .difference-table-box h4 {
    font-size: 18px;
  }

  .difference .difference-table .difference-table-box p {
    font-size: 14px;
    line-height: 22px;
  }

  .difference .difference-table .difference-table-box .difference-table-icon {
    margin-top: -214px;
  }

  .difference-table-box {
    padding: 0;
  }

  .difference .difference-table .border::before {
    left: -5%;
  }

  .difference .difference-table .border::after {
    right: -4%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .slider .slider-heading span {
    font-size: 29px;
  }

  .difference-table-box {
    padding: 0px;
  }

  .difference .difference-table .border::before {
    left: -4%;
  }

  .difference .difference-table .border::after {
    right: -3.5%;
  }

  .slider .slider-heading h3 {
    font-size: 38px;
    line-height: 62px;
  }

  .slider-img img {
    width: 100%;
  }

  .slider-img::after {
    height: 452px;
    width: 833px;
    background-size: contain;
    right: 0;
    top: -26%;
  }

  .electronic-cash-img {
    width: 100%;
  }

  .slider {
    background-size: cover;
  }

  .ratings .ratings-star {
    right: -2%;
    top: -36%;
  }

  .unicoin .unicoin-heading h3 {
    font-size: 160px;
  }

  .unicoin .unicoin-heading h6 {
    font-size: 54px;
  }

  .unicon-title h4 {
    font-size: 22px;
    width: 38%;
  }

  .unicon-title p {
    font-size: 22px;
  }

  .unicoin-box {
    padding: 80px 20px 20px 20px;
    margin-bottom: 110px;
  }

  .unicoin-store .unicoin-store-heading h3::before {
    font-size: 130px;
  }

  .difference .difference-table .difference-table-box h4 {
    font-size: 23px;
  }

  .difference .difference-table .difference-table-box .difference-table-icon {
    margin-top: -218px;
  }

  .roadmap-flow p {
    width: 62%;
  }

  .roadmap-flow h6 {
    width: 57%;
  }

  .roadmap-flow img {
    width: 100%;
  }

  .roadmap-bg::after {
    width: 135px;
  }
}

@media (min-width: 1700px) and (max-width: 1899px) {
  .slider-img::after {
    width: 1338px;
    right: -24%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .slider-img::after {
    height: 446px !important;
    width: 901px !important;
    background-size: contain !important;
    top: -19% !important;
    right: 0;
  }

  .slider .slider-heading h3 {
    width: 90%;
  }
}

@media (min-width: 1600px) and (max-width: 1699px) {
  .slider-img::after {
    height: 562px;
    width: 1329px;
    top: -40%;
    right: -14%;
  }

  .slider .slider-heading h3 {
    width: 90%;
  }
}

@media (min-width: 1700px) and (max-width: 1899px) {
  .slider-img::after {
    height: 562px;
    width: 1289px;
    top: -32%;
    right: -14%;
  }

  .slider .slider-heading h3 {
    width: 90%;
  }
}

@media (min-width: 1300px) and (max-width: 1699px) {
  .slider-img img {
    width: 100%;
  }

  .difference-table-box {
    padding: 0px;
  }

  .difference .difference-table .border::before {
    left: -4.5%;
  }

  .difference .difference-table .border::after {
    right: -3%;
  }

  .difference .difference-table .difference-table-box .difference-table-icon {
    margin-top: -217px;
  }

  .slider {
    background-size: cover;
  }

  .unicoin-box {
    padding: 80px 20px 20px 20px;
  }

  .unicoin-store .unicoin-store-heading h3::before {
    font-size: 136px;
  }

  .roadmap-flow p {
    width: 62%;
  }

  .roadmap-flow h6 {
    width: 52%;
  }

  .roadmap-flow img {
    width: 100%;
  }

  .roadmap-bg::after {
    width: 135px;
  }

  .header .navbar {
    padding: 18px 20px 18px 75px !important;
  }

  .header .navbar .navbar-brand {
    margin-right: 60px !important;
  }
}

.header a {
  color: #ffffff;
  text-decoration: none;
}

.U-1-img {
  width: 40%;
}

.navigation {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 48px;
  align-items: center;
  letter-spacing: 0.05em;
}

.table-balances thead {
  height: 60px;
  left: 0px;
  right: 0px;
  top: 0px;
  font-family: "Source Sans Pro";
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.account-type {
  width: 40%;
  background: #b4f5be;
  border-radius: 20px;
  color: #4d8e58;
}

.header .navbar {
  padding: 18px 0px 18px 105px;
}

.header .navbar .navbar-brand {
  padding: 0;
  margin-right: 100px;
}

.navbar-expand-xl .navbar-nav .nav-link {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 24px !important;
  padding-right: 24px !important;
  align-items: center;
  display: flex;
  font-family: "Oxygen";
  transition: all 0.5s;
  position: relative;
}

.sidebar {
  border-right: 1px solid #808080;
  padding: 10px 0 30px 15px;
  height: 100%;
  overflow-y: auto;
}

.issuers .accordion-header img {
  width: 20px;
}

.sidebar-main .sidebar-main-label h3 {
  margin-left: 20px;
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.03em;
  margin-bottom: 0;
  color: #808080;
}
/*.sidebar-main .spot,
    .sidebar-main .rates,
    .sidebar-main .authorizations,
    .sidebar-main .money,
    .sidebar-main .ledgers,
    .sidebar-main .token,
    .sidebar-main .wallets {
        margin-top: 35px;
    }*/
.sidebar-main .spot ul {
  padding-left: 51px !important;
}

.sidebar-main .ledgers ul {
  padding-left: 46px !important;
}

.sidebar-main .authorizations ul {
  padding-left: 54px !important;
}

.sidebar-main .token ul {
  padding-left: 54px !important;
}

.sidebar-main .rates ul,
.sidebar-main .money ul {
  padding-left: 52px !important;
}

.sidebar-main .wallets ul {
  padding-left: 52px !important;
}

.sidebar-main .issuers ul {
  padding-left: 47px;
  margin-bottom: 0;
}

.sidebar-main .issuers ul li {
  list-style: none;
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0.03em;
  color: #808080;
  margin-bottom: 24px;
}

.sidebar-main .issuers ul li a {
  list-style: none;
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0.03em;
  color: #808080;
  margin-bottom: 24px;
  text-decoration: none;
}

.sidebar-main .issuers ul li a.active {
  font-weight: 400;
  color: #000;
}

.issuers .accordion-header {
  padding: 6px 0 6px 15px;
  position: relative;
}

.issuers .accordion-header::after {
  content: "";
  width: 0px;
  height: 48px;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
  background: #aa8247;
  border-radius: 0px 2px 2px 0px;
  position: absolute;
}

.issuers .accordion-header.active::after {
  width: 3px;
}

.issuers .accordion-header.active {
  background: #f0f1f2;
  border-radius: 8px 0px 0px 8px;
  display: flex;
  margin-bottom: 10px;
}

.issuers .accordion-header.active h3 {
  color: #000;
}

.issuers .accordion-header.active .none {
  display: none;
}

.issuers .accordion-header.active .show {
  display: block;
}

.issuers .accordion-header .show {
  display: none;
}

.balance-page .balance-head {
  margin-top: 35px;
}

.balance-page .balance-head h3 {
  margin-bottom: 22px;
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 38px;
  letter-spacing: 0.05em;
  color: #000000;
}

.balance-page .balance-head p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
}

.balance-box {
  background: #ffffff;
  border: 1px solid #aa8247;
  padding: 8px 35px 28px 35px;
  text-align: center;
}

.balance-box .yellow {
  background: #f4f5b4 !important;
}

.balance-box .red {
  background: #ffadad !important;
}

.balance-box .yellow h6 {
  color: #999428 !important;
}

.balance-box .red h6 {
  color: #a52e2e !important;
}

.balance-box .account {
  background: #b4f5be;
  border-radius: 20px;
  width: 115px;
  padding: 5px;
  margin-bottom: 15px;
  margin-left: -27px;
}

.balance-box .account h6 {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 12px;
  color: #4d8e58;
  margin-bottom: 0;
}

.balance-box h5 {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.03em;
  margin: 10px 0;
  color: #000000;
}

.balance-box h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #aa8247;
  margin-bottom: 46px;
}

.balance-box .account-details {
  display: flex;
  justify-content: space-around;
}

.balance-box .account-details .light .round {
  background: #ece9e9 !important;
}

.balance-box .account-details .account-details-round .round {
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 42px;
  border-radius: 50%;
  background: #aa8247;
  margin: auto;
}

.balance-box .account-details .light p {
  color: rgba(0, 0, 0, 0.4) !important;
}

.balance-box .account-details .account-details-round p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 12px;
  color: #aa8247;
  margin-top: 4px;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .balance-box h3 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .balance-page .balance-head h3 {
    margin-bottom: 4px;
    font-size: 32px;
  }
}

.transactions-table .transactions-table-box .transactions-units p {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 0;
}

.transactions-table .transactions-table-box .transactions-units h4 {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 4px;
}

.transactions-table .transactions-table-box .transactions-to p {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 0;
}

.transactions-table .transactions-table-box .transactions-to h4 {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
}

.transactions-table .transactions-table-box .successfull p:before {
  background: #00e425 !important;
}

.transactions-table .transactions-table-box .cancelled p:before {
  background: #e40000 !important;
}

.transactions-table .transactions-table-box .transactions-date p:before {
  content: "";
  width: 10px;
  height: 10px;
  background: #e4db00;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -10%;
  transform: translateY(-50%);
}

.transactions-table .transactions-table-box .successfull p {
  color: #00e425 !important;
}

.transactions-table .transactions-table-box .cancelled p {
  color: #e40000 !important;
}

.transactions-table .transactions-table-box .transactions-date p {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #e4db00;
  list-style: circle;
  position: relative;
  margin-left: 10%;
  margin-bottom: 0;
}

.transactions-table .transactions-table-box .transactions-date h4 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 10px;
}

.transactions-table .successfull-boder {
  border-left: 6px solid #00e425 !important;
}

.transactions-table .cancelled-boder {
  border-left: 6px solid #e40000 !important;
}

.transactions-table .transactions-table-box {
  background: rgba(8, 13, 53, 0.04);
  border-left: 6px solid #e4db00;
  border-radius: 4px 0px 0px 4px;
  padding: 18px 30px;
}

.transactions-table h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 12px;
  color: #000000;
}

@media (max-width: 767px) {
  .transactions-table .transactions-table-box .transactions-to p {
    font-size: 13px;
  }

  .transactions-print {
    text-align: center;
  }

  .transactions-table .transactions-table-box .transactions-units p {
    font-size: 13px;
  }

  .transactions-table .transactions-table-box {
    padding: 18px 30px 0;
  }

  .table thead tr th {
    font-size: 12px !important;
    padding: 12px 0 !important;
  }

  .header .navbar {
    padding: 18px 14px;
  }

  .header .navbar .navbar-brand {
    padding: 0;
    margin-right: 0;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 10px;
  }

  .navbar-expand-xl .navbar-nav .nav-link.active::after {
    width: 182px;
  }

  .navbar-expand-xl .navbar-nav .nav-link::after {
    top: 5px;
    left: 110px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .transactions-table .transactions-table-box {
    padding: 18px 30px 0;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 20px;
  }

  .navbar-expand-xl .navbar-nav .nav-link.active::after {
    width: 182px;
  }

  .navbar-expand-xl .navbar-nav .nav-link::after {
    top: 5px;
    left: 110px;
  }

  .header .navbar {
    padding: 18px 54px 18px 64px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .transactions-table .transactions-table-box .transactions-date h4 {
    font-size: 14px;
  }

  .sidebar-main .issuers ul li {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .sidebar-main .issuers ul li a {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .transactions-table .transactions-table-box .transactions-date p {
    margin-left: 14%;
  }

  .transactions-table .transactions-table-box .transactions-date p:before {
    left: -14%;
  }

  .transactions-table .transactions-table-box .transactions-to p {
    font-size: 12px;
  }

  .transactions-table .transactions-table-box .transactions-units p {
    font-size: 12px;
  }

  .header .navbar {
    padding: 18px 30px 18px 86px;
  }

  .header .navbar .navbar-brand {
    margin-right: 0px;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 20px;
  }

  .navbar-expand-xl .navbar-nav .nav-link.active::after {
    width: 182px;
  }

  .navbar-expand-xl .navbar-nav .nav-link::after {
    top: 5px;
    left: 110px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .transactions-table .transactions-table-box .transactions-date h4 {
    font-size: 16px;
  }

  .transactions-table .transactions-table-box .transactions-date p {
    margin-left: 14%;
  }

  .transactions-table .transactions-table-box .transactions-date p:before {
    left: -14%;
  }

  .transactions-table .transactions-table-box .transactions-to p {
    font-size: 15px;
  }

  .transactions-table .transactions-table-box .transactions-units p {
    font-size: 15px;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 11px;
    white-space: nowrap;
  }

  .navbar-expand-xl .navbar-nav .nav-link::after {
    top: -37px;
  }

  .header .navbar .navbar-brand {
    padding: 0;
    margin-right: 65px;
  }

  .header .navbar {
    padding: 18px 10px 18px 100px;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 13px !important;
    padding-right: 13px !important;
    font-size: 12px;
  }
}

@media (min-width: 1700px) and (max-width: 1919px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 18px !important;
    padding-right: 18px !important;
    white-space: nowrap;
  }
}

.table tbody td .btn {
  background: #aa8247;
  border-radius: 4px;
  width: 138px;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.table tbody td {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  vertical-align: middle;
  text-align: center;
  padding: 12px;
}

.table thead tr th {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  padding: 0;
  background: rgba(8, 13, 53, 0.04);
  padding: 12px;
  text-align: center;
}

.table > :not(:first-child) {
  border-top: 1px solid rgba(8, 13, 53, 0.28) !important;
}

.navbar-toggler {
  border: 1px solid #fff !important;
  border-radius: 5px !important;
}

.navbar-toggler-icon {
  background-image: url(../../public/icons/toggel.png) !important;
}

.accordion-button:not(.collapsed) {
  color: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
}

.accordion-button:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.accordion-button::after {
  flex-shrink: unset !important;
  width: unset !important;
  height: unset !important;
  margin-left: unset !important;
  content: "";
  background-image: unset !important;
  background-repeat: unset !important;
  background-size: unset !important;
  transition: unset !important;
}

.accordion-button {
  position: relative;
  display: unset !important;
  align-items: unset !important;
  width: 100%;
  padding: unset !important;
  font-size: unset;
  color: unset !important;
  text-align: unset !important;
  background-color: unset !important;
  border: 0;
  border-radius: unset !important;
  /*overflow-anchor: unset !important;*/
  transition: unset !important;
}

.main-heading {
  padding: 30px 0;
}

.main-heading h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.05em;
  color: #000000;
  margin-bottom: 0;
}

.main-heading h6 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 22px;
  letter-spacing: 0.05em;
  color: #000000;
  margin-top: 20px;
}

.main-heading p {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 0px;
}

.wallet-balances-box p {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 20px;
}

.wallet-balances-box h4 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}

.wallet-balances-box .table tbody td ul li {
  background: #aa8247;
  width: 24px;
  height: 24px;
  list-style: none;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
}

.wallet-balances-box .table tbody td {
  padding: 8px 20px;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.wallet-balances-box .table tbody td,
.wallet-balances-box .table thead tr th {
  text-align: start;
}
.wallet-balances-box .table thead tr th select {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5) !important;
  white-space: nowrap;
}
.wallet-balances-box .table thead tr th .form-select {
  height: unset !important;
  border-radius: 0 !important;
  color: #000;
  padding: 0 !important;
  border: 0 !important;
}
.wallet-balances-box .table {
  background: #ffffff;
  border-radius: 4px;
  margin-top: -8px;
}

.wallet-balances-box .table {
  background: #ffffff;
  border-radius: 4px;
  margin-top: -8px;
}

.search-box {
  z-index: -2;
  position: relative;
}

.line-input {
  box-shadow: none !important;
  border: 0 !important;
  border-bottom: 1px solid #797979 !important;
}

.wallet-balances-box .table tbody td:last-child,
.wallet-balances-box .table thead tr th:last-child {
  padding-left: 50px;
}

.wallet-balances-box .table thead tr th {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  background: #fff;
  color: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  padding: 25px 20px;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background: #f9f8f8;
  --bs-table-accent-bg: unset !important;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0px !important;
}

.wallet-balances-box .input-group {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.wallet-balances-box .input-group-text {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  color: #767676;
  background-color: unset;
  border: unset;
  border-radius: unset;
}

.wallet-balances-box .form-control {
  width: 100%;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  border: unset;
  border-radius: unset;
  transition: unset;
}

.wallet-balances-box .btn-primary:hover {
  background-color: #5b5b5b !important;
  color: #fff !important;
}
.wallet-balances-box .btn-primary {
  background: #aa8247 !important;
  border-radius: 4px !important;
  font-family: "Source Sans Pro";
  font-weight: 600 !important;
  font-size: 16px !important;
  letter-spacing: 0.05em !important;
  color: #ffffff !important;
  margin-left: 15px;
  padding: 8px 30px;
  text-transform: uppercase;
  transition: all 0.5s;
}
.wallet-balances-box .btn-primary .nav-link {
  color: #ffffff;
  transition: all 0.5s;
  padding: 0;
}

.wallet-balances-box .btn {
  background: #f9f8f8;
  border-radius: 0px 4px 4px 0px;
  border: 0;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px !important;
  color: #000000;
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 30px !important;
  vertical-align: 0.055em;
  content: "";
  width: 10px;
  height: 10px;
  border-top: 2px solid #000 !important;
  border-left: 2px solid #000 !important;
  transform: rotate(225deg);
  border-right: unset !important;
  border-bottom: unset !important;
}

@media (max-width: 767px) {
  .sidebar-main .sidebar-main-label h3 {
    font-size: 16px;
  }

  .main-heading h3 {
    font-size: 24px;
  }

  .main-heading h6 {
    font-size: 20px;
    margin-top: 14px;
  }

  .main-heading p {
    font-size: 14px;
  }

  .sidebar-main .issuers ul li {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .sidebar-main .issuers ul li a {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .sidebar-main .spot,
  .sidebar-main .rates,
  .sidebar-main .authorizations,
  .sidebar-main .money,
  .sidebar-main .ledgers,
  .sidebar-main .token,
  .sidebar-main .wallets {
    margin-top: 20px;
  }

  .wallet-balances-box h4 {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .wallet-balances-box .btn-primary {
    font-size: 12px !important;
    margin-left: 0px;
    height: 32px;
    padding: 0 28px;
  }

  .wallet-balances-box .table thead tr th {
    font-size: 12px !important;
    padding: 15px 12px !important;
  }

  .wallet-balances-box .table tbody td {
    padding: 12px 12px;
    font-size: 14px;
    white-space: nowrap;
  }

  .wallet-balances-box .table tbody td:last-child,
  .wallet-balances-box .table thead tr th:last-child {
    padding-left: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar {
    padding: 40px 0px;
  }

  .sidebar-main .issuers ul {
    padding-left: 20px;
  }

  .sidebar-main .issuers ul li a {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .sidebar-main .issuers ul li {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .sidebar-main .sidebar-main-label h3 {
    margin-left: 13px;
    font-size: 15px;
  }

  .sidebar-main .wallets ul {
    padding-left: 20px !important;
  }

  .sidebar-main .token ul {
    padding-left: 20px !important;
  }

  .sidebar-main .ledgers ul {
    padding-left: 20px !important;
  }

  .sidebar-main .authorizations ul {
    padding-left: 20px !important;
  }

  .sidebar-main .rates ul,
  .sidebar-main .money ul {
    padding-left: 20px !important;
  }

  .sidebar-main .spot ul {
    padding-left: 20px !important;
  }

  .sidebar-main-label img {
    width: 15%;
  }

  .wallet-balances-box h4 {
    font-size: 22px;
    margin-bottom: 12px;
  }

  .wallet-balances-box .table tbody td {
    font-size: 15px;
  }

  .wallet-balances-box .btn-primary {
    font-size: 12px !important;
    margin-left: 12px;
    padding: 0 8px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar {
    padding: 30px 0 30px 5px;
  }

  .wallet-balances-box .btn-primary {
    font-size: 14px !important;
    padding: 0 25px;
  }

  .wallet-balances-box .table thead tr th {
    padding: 25px 18px;
  }

  .wallet-balances-box h4 {
    font-size: 22px;
    margin-bottom: 12px;
  }

  .wallet-balances-box .table tbody td {
    font-size: 15px;
  }
}

.wallet-information-box {
  margin: 0 70px 0 80px;
}

.custom-file-button .form-control {
  display: block !important;
  font-family: "Oxygen";
  padding: 0.375rem 0.75rem !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #333 !important;
  border-right: 0 !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border-radius: 5px 0 0 5px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  letter-spacing: 0.05em;
}

.wallet-information-box
  .wallet-information-form
  .input-box
  .custom-file-button
  .input-group-text {
  display: flex !important;
  font-family: "Oxygen";
  align-items: center !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.05em;
  color: #fff !important;
  text-align: center !important;
  white-space: nowrap !important;
  background-color: #333 !important;
  border: 1px solid #333 !important;
  border-radius: 0 5px 5px 0 !important;
  margin: 0 !important;
}

.wallet-information-box
  .wallet-information-form
  .input-group
  .input-group-text {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.03em;
  color: #000000;
  padding-bottom: 10px;
  background-color: unset !important;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0;
}

.wallet-information-box .wallet-information-form .input-group {
  width: 90%;
}

.wallet-information-box .wallet-information-form .input-group .form-control {
  width: 40%;
}

.wallet-information-box .wallet-information-form label {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.5);
}

.wallet-information-box .wallet-information-form .form-check-input {
  border-radius: 10px !important;
  width: 48px !important;
  height: 24px !important;
}

.form-check-input:focus {
  border-color: unset !important;
  outline: unset !important;
  box-shadow: unset !important;
  border-color: #bfbfbf !important;
}

.form-switch .form-check-input:checked {
  background-position: right center;
}

.wallet-information-box .wallet-information-form .form-check-input:checked {
  background-color: #81ccef;
  border-color: #81ccef;
}

.wallet-information-box .wallet-information-form .form-control {
  display: block;
  width: 90%;
  padding: 0;
  padding-bottom: 10px;
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 23px;
  letter-spacing: 0.03em !important;
  color: rgba(0, 0, 0, 1) !important;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0;
  transition: unset;
}

.wallet-information-box h4 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 22px;
  color: #000000;
  margin-bottom: 32px;
}

.form-select:focus {
  border-color: unset;
  outline: unset;
  box-shadow: unset !important;
}

.form-control:focus {
  color: unset;
  background-color: unset;
  border-color: unset;
  outline: unset;
  box-shadow: unset !important;
}

.wallet-information-box .cancel-btn {
    background: #aa8247;
    color: #fff !important;
    border-radius: 4px;
    transition: all 0.5s;
}
.next-btn {
    position: relative;
    top: 0;
    transition: all 0.2s;
}
    .next-btn:hover {
        background-color: #0055cf !important;
        color: #fff !important;
        top: 5px;
        text-decoration: underline;
        box-shadow: 0 0 10px rgba(0,0,0,0.7);
    }
.wallet-information-box .next-btn {
  background: #aa8247;
  border-radius: 4px;
  transition: all 0.5s;
  color: #fff;
}

.wallet-information-box .btn {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  width: 138px;
  padding: 7px 0;
}

@media (max-width: 767px) {
  .wallet-information-box {
    margin: 0;
  }

  .wallet-information-box h4 {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .wallet-information-box .wallet-information-form .form-control {
    width: 100%;
    padding-bottom: 8px;
    font-size: 15px !important;
    line-height: 20px;
  }

  .wallet-information-box .wallet-information-form label {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 3px;
  }

  .issuers .accordion-header::after {
    left: -24px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .wallet-information-box {
    margin: 0 6px;
  }

  .wallet-information-box h4 {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .wallet-information-box .wallet-information-form .form-control {
    padding-bottom: 8px;
    font-size: 15px !important;
    line-height: 20px;
  }

  .issuers .accordion-header::after {
    left: 0px;
  }

  .review-wallet .sidebar {
    height: 1810px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .issuers .accordion-header::after {
    left: -5px;
  }

  .review-wallet .sidebar {
    height: 1620px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .wallet-information-box .wallet-information-form .form-control {
    width: 100%;
  }

  .wallet-information-box {
    margin: 0 17px;
  }

  .issuers .accordion-header::after {
    left: -24px;
  }

  .review-wallet .sidebar {
    height: 1580px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1899px) {
  .issuers .accordion-header::after {
    left: -24px;
  }

  .review-wallet .sidebar {
    height: 1580px !important;
  }
}

.link-wallet-box {
  margin-left: 95px;
}

.link-wallet-box h4 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.link-wallet-box p {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 30px;
}

.link-wallet-box .link-wallet-tabs .nav-link img {
  display: block;
  margin: auto;
  margin-bottom: 10px;
}

.link-wallet-box .link-wallet-tabs .nav-link.active .none {
  display: none;
}

.link-wallet-box .link-wallet-tabs .nav-link.active .show {
  display: block;
}

.link-wallet-box .link-wallet-tabs .nav-link .show {
  display: none;
}

.link-wallet-box .link-wallet-tabs .nav-link {
  width: 160px;
  height: 100px;
  line-height: 14px;
  border-radius: 4px;
  margin-right: 40px;
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #aa8247;
  padding: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background: #aa8247 !important;
}

.link-wallet-box .tab-content label {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0px;
}

.link-wallet-box .tab-content .form-control {
  display: block;
  width: 80%;
  padding: 0;
  padding-bottom: 10px;
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 22px;
  letter-spacing: 0.03em !important;
  color: rgba(0, 0, 0, 1) !important;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0;
  transition: unset;
}

.link-wallet-box .tab-content .cancel-btn {
  background-color: #5b5b5b !important;
  color: #fff !important;
  border-radius: 4px;
  transition: all 0.5s;
}

.link-wallet-box .tab-content .next-btn {
  background: #aa8247;
  border-radius: 4px;
  color: #fff !important;
  transition: all 0.5s;
}

.link-wallet-box .tab-content .btn {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  width: 138px;
  padding: 7px 0;
}

@media (max-width: 767px) {
  .link-wallet-box {
    margin-left: 0px;
  }

  .link-wallet-box h4 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .link-wallet-box p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px;
  }

  .link-wallet-box .link-wallet-tabs .nav-link {
    width: 129px;
    height: 90px;
    margin-right: 18px;
    font-size: 14px;
  }

  .link-wallet-box .tab-content label {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .link-wallet-box .tab-content .form-control {
    width: 100%;
    padding-bottom: 6px;
    font-size: 14px !important;
    line-height: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .link-wallet-box {
    margin-left: 0;
  }

  .link-wallet-box h4 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .link-wallet-box p {
    font-size: 16px;
    margin-bottom: 24px;
  }

  .link-wallet-box .link-wallet-tabs .nav-link {
    width: 90px;
    height: 90px;
    margin-right: 2px !important;
    font-size: 11px;
  }

  .link-wallet-box .tab-content .form-control {
    width: 100%;
    padding-bottom: 6px;
    font-size: 16px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .link-wallet-box {
    margin-left: 0px;
  }

  .link-wallet-box .link-wallet-tabs .nav-link {
    width: 107px;
    height: 90px;
    margin-right: 15px;
    font-size: 13px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .link-wallet-box {
    margin-left: 60px;
  }

  .link-wallet-box .link-wallet-tabs .nav-link {
    width: 120px;
    margin-right: 18px;
    font-size: 14px;
  }

  .link-wallet-box .tab-content .form-control {
    width: 100%;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
  .link-wallet-box .link-wallet-tabs .nav-link {
    width: 149px;
    margin-right: 6px;
  }
}

.review-wallet-box {
  margin-left: 95px;
}

.review-wallet-box h4 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 28px;
  color: #000000;
  margin-bottom: 35px;
}

.review-wallet-box .review-wallet-info-box {
  background: #ffffff;
  border: 1px solid #aa8247;
  border-radius: 4px;
  padding: 22px 22px 8px;
}

.review-wallet-box .review-wallet-info-box .heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}

.review-wallet-box .review-wallet-info-box .heading h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 24px;
  color: #aa8247;
  margin-bottom: 0;
}

.review-wallet-box .review-wallet-info-box hr {
  background: #aa8247;
  opacity: 1;
}

.review-wallet-box .review-wallet-info-box .heading i {
  font-size: 24px;
  color: #f29b2e;
}

.review-wallet-box .review-wallet-info-box .wallet-list {
  display: flex;
}

.review-wallet-box .review-wallet-info-box .wallet-list h4 {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0px;
}

.review-wallet-box .review-wallet-info-box .wallet-list p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 24px;
  color: #000000;
}

.review-wallet-box .review-wallet-info-box .wallet-list i {
  width: 16px;
  height: 16px;
  background: #aa8247;
  font-size: 16px;
  border-radius: 50%;
  color: #fff;
  line-height: 16px;
  text-align: center;
  margin-right: 8px;
}

.review-wallet-box .review-wallet-info-box h6 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #aa8247;
  margin-bottom: 16px;
}

.review-wallet-box-btn .cancel-btn {
    background-color: #b8883b !important;
    color: #fff !important;
    border-radius: 4px;
    transition: all 0.5s;
}

.review-wallet-box-btn .next-btn {
    background: #b8883b;
    border-radius: 4px;
    color: #fff;
    transition: all 0.5s;
}

.review-wallet-box-btn .btn {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  width: 138px;
  padding: 7px 0;
}

.review-wallet .sidebar {
  height: 1580px;
}

@media (max-width: 767px) {
  .review-wallet .sidebar {
    height: 630px;
  }

  .review-wallet-box {
    margin-left: 0px;
  }

  .review-wallet-box h4 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .review-wallet-box .review-wallet-info-box {
    padding: 15px 15px 0px;
  }

  .review-wallet-box .review-wallet-info-box .heading h3 {
    font-size: 18px;
  }

  .review-wallet-box .review-wallet-info-box .heading i {
    font-size: 20px;
  }

  .review-wallet-box .review-wallet-info-box .heading {
    margin-bottom: 16px;
  }

  .review-wallet-box .review-wallet-info-box h6 {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .review-wallet-box {
    margin-left: 0px;
  }

  .review-wallet-box h4 {
    font-size: 24px;
    margin-bottom: 24px;
  }

  .review-wallet-box .review-wallet-info-box {
    padding: 22px 22px 0px;
  }

  .review-wallet-box .review-wallet-info-box .heading {
    margin-bottom: 20px;
  }

  .review-wallet-box .review-wallet-info-box .heading h3 {
    font-size: 22px;
  }

  .review-wallet-box .review-wallet-info-box .heading i {
    font-size: 22px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .review-wallet-box {
    margin-left: 0px;
  }

  .review-wallet-box h4 {
    font-size: 25px;
    margin-bottom: 25px;
  }

  .review-wallet-box .review-wallet-info-box {
    padding: 24px 24px 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .review-wallet-box {
    margin-left: 0px;
  }

  .review-wallet-box .review-wallet-info-box {
    padding: 28px 20px 4px;
  }
}

.wallet-information-box .wallet-information-form h6 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #aa8247;
  margin-bottom: 14px;
}

.ledger-information .sidebar {
  height: 1550px;
}

.token-information .sidebar {
  height: 1100px;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.wallet-information-box .wallet-information-form .input-box {
  position: relative;
}

.wallet-information-box .wallet-information-form i {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 10% !important;
  color: #46a4b6;
  font-size: 24px;
}

.review-ledger .sidebar {
  height: 1200px;
}

.create-page h5 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px !important;
  color: #000 !important;
  margin-bottom: 44px;
}

.create-page h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 24px !important;
  letter-spacing: 0.03em;
  color: #000 !important;
  margin-bottom: 14px;
}
.originator-m-t {
  margin-right: 95px;
}
.beneficiary-m-t {
  margin-right: 106px;
}

@media (max-width: 767px) {
  .ledger-information .sidebar {
    height: 550px;
  }
  .originator-m-t {
    margin-right: 36px;
  }
  .beneficiary-m-t {
    margin-right: 41px;
  }
  .token-information .sidebar {
    height: 550px;
  }

  .token-information .sidebar {
    height: 550px;
  }

  .wallet-information-box .wallet-information-form i {
    right: 0%;
    font-size: 18px;
  }

  .review-ledger .sidebar {
    height: 600px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ledger-information .sidebar {
    height: 2430px;
  }

  .token-information .sidebar {
    height: 950px !important;
  }

  .review-ledger .sidebar {
    height: 1740px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ledger-information .sidebar {
    height: 2530px;
  }
  .originator-m-t {
    margin-right: 85px;
  }
  .beneficiary-m-t {
    margin-right: 95px;
  }
  .token-information .sidebar {
    height: 1030px;
  }

  .token-information .sidebar {
    height: 1030px;
  }

  .review-ledger .sidebar {
    height: 1540px;
  }

  .review-wallet-box .review-wallet-info-box h6 {
    font-size: 18px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .ledger-information .sidebar {
    height: 1990px;
  }
  .originator-m-t {
    margin-right: 155px;
  }
  .beneficiary-m-t {
    margin-right: 166px;
  }
  .wallet-information-box .wallet-information-form h6 {
    font-size: 16px;
    margin-bottom: 22px;
  }

  .wallet-information-box .wallet-information-form h6 {
    font-size: 16px;
    margin-bottom: 22px;
  }

  .wallet-information-box .wallet-information-form i {
    right: 0%;
    font-size: 22px;
  }

  .review-ledger .sidebar {
    height: 1280px;
  }

  .review-wallet-box .review-wallet-info-box h6 {
    font-size: 17px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .review-wallet-box .review-wallet-info-box h6 {
    font-size: 19px;
  }

  .wallet-information-box .wallet-information-form h6 {
    font-size: 16px !important;
  }

  .review-ledger .sidebar {
    height: 1280px;
  }
}

.deposit-funds-tabs ul .originator-m {
  margin-right: 60px;
}

.deposit-funds-tabs ul .beneficiary-m {
  margin-right: 85px;
}

.deposit-funds-tabs ul .money-m {
  margin-right: 104px;
}

.nav-tabs .nav-link.active h6 {
  color: #aa8247;
}

.deposit-funds-tabs .nav-tabs .nav-item.show .nav-link,
.deposit-funds-tabs .nav-tabs .nav-link.active {
  color: unset;
  background-color: unset;
  border-color: #ffffff;
}

.deposit-funds-tabs .nav-tabs .nav-link:focus,
.deposit-funds-tabs .nav-tabs .nav-link:hover {
  border-color: #fff !important;
}

.deposit-funds-tabs .nav-tabs .nav-link {
  margin-bottom: 0;
  background: 0 0;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  cursor: default;
}

.nav-tabs .nav-link.active .money,
.nav-tabs .nav-link.active .beneficiary,
.nav-tabs .nav-link.active .review,
.nav-tabs .nav-link.active .originator {
  color: #fff !important;
  border-color: unset !important;
  border: 3px solid #aa8247 !important;
  background: #aa8247 !important;
}

.deposit-funds-tabs ul li .nav-link {
  display: block;
  padding: 0;
  transition: unset;
}

.deposit-funds-tabs ul li h6 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #cecccc;
  margin-top: 16px;
}

.deposit-funds-tabs .nav-tabs {
  border-bottom: 0;
}

.deposit-funds-tabs {
  margin-left: 95px;
}

.deposit-funds-tabs .money::after,
.deposit-funds-tabs .beneficiary::after,
.deposit-funds-tabs .originator::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 3px;
  background: #cecccc;
  left: 55px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999999;
}

.deposit-funds-tabs .money i,
.deposit-funds-tabs .beneficiary i,
.deposit-funds-tabs .review i,
.deposit-funds-tabs .originator i {
  display: none;
}

.nav-tabs .nav-link.active .money h3,
.nav-tabs .nav-link.active .beneficiary h3,
.nav-tabs .nav-link.active .review h3,
.nav-tabs .nav-link.active .originator h3 {
  color: #fff;
}

.deposit-funds-tabs .money h3,
.deposit-funds-tabs .beneficiary h3,
.deposit-funds-tabs .review h3,
.deposit-funds-tabs .originator h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0.05em;
  color: #cecccc;
  line-height: 42px;
  margin-bottom: 0;
}

.deposit-funds-tabs .money i,
.deposit-funds-tabs .beneficiary i,
.deposit-funds-tabs .review i,
.deposit-funds-tabs .originator i {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 34px;
  letter-spacing: 0.05em;
  color: #cecccc;
  line-height: 46px;
  margin-bottom: 0;
}

.deposit-funds-tabs .money,
.deposit-funds-tabs .beneficiary,
.deposit-funds-tabs .review,
.deposit-funds-tabs .originator {
  width: 50px;
  height: 50px;
  border: 3px solid #cecccc;
  border-radius: 50%;
  margin: auto;
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25));
  z-index: 999;
  position: relative;
}

.deposit-funds .sidebar {
  height: 990px;
}

.originating-account h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 22px;
  color: #000;
  margin-top: 50px;
  margin-bottom: 12px;
}

.originating-account .originating-account {
  background: #ffffff;
  border: 1px solid #aa8247;
  border-radius: 4px;
}

.originating-account .originating-account h5 {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #000000;
  padding: 16px 24px;
  margin-bottom: 0;
}

.originating-account .originating-account .table thead tr th {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  padding: 0;
  background: #aa8247;
  padding: 12px 24px;
  text-align: start;
  color: #ffffff;
  white-space: nowrap;
}

.originating-account .table tbody td {
  text-align: start;
  white-space: nowrap;
  padding: 5px 24px;
}

.subscription .form-check-input:checked,
.originating-account
  .originating-account
  .table
  tbody
  td
  .form-check-input:checked {
  background-color: #aa8247;
  border-color: #aa8247;
}

.subscription .form-check-input,
.originating-account
  .originating-account
  .table
  tbody
  td
  .form-check-input:focus {
  border-color: #aa8247 !important;
}

.subscription .form-check .form-check-input {
  border-radius: 100%;
}

.originating-account
  .originating-account
  .table
  tbody
  td
  .form-check-input:active {
  filter: unset;
}

.originating-account .originating-account .table tbody td .form-check-input {
  width: 24px;
  height: 24px;
  border: 1px solid #aa8247;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .deposit-funds .sidebar {
    height: 300px;
  }

  .deposit-funds-tabs {
    margin-left: 0px;
  }

  .deposit-funds-tabs .review,
  .deposit-funds-tabs .money,
  .deposit-funds-tabs .beneficiary,
  .deposit-funds-tabs .originator {
    width: 30px;
    height: 30px;
    border: 2px solid #cecccc;
    font-size: 12px;
    line-height: 27px;
  }

  .deposit-funds-tabs .money h3,
  .deposit-funds-tabs .beneficiary h3,
  .deposit-funds-tabs .review h3,
  .deposit-funds-tabs .originator h3 {
    font-size: 12px;
    line-height: 27px;
  }

  .deposit-funds-tabs .money i,
  .deposit-funds-tabs .beneficiary i,
  .deposit-funds-tabs .review i,
  .deposit-funds-tabs .originator i {
    font-size: 18px;
    line-height: 27px;
  }

  .deposit-funds-tabs ul li h6 {
    font-size: 12px;
    margin-top: 9px;
  }

  .deposit-funds-tabs ul .originator-m {
    margin-right: 10px;
  }

  .deposit-funds-tabs ul .beneficiary-m {
    margin-right: 27px;
  }

  .deposit-funds-tabs ul .money-m {
    margin-right: 36px;
  }

  .deposit-funds-tabs .money::after,
  .deposit-funds-tabs .beneficiary::after,
  .deposit-funds-tabs .originator::after {
    width: 46px;
    height: 2px;
    left: 31px;
  }

  .originating-account h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 12px;
  }

  .originating-account .originating-account .table tbody td {
    padding: 8px 8px;
  }

  .originating-account .originating-account .table tbody td {
    font-size: 12px;
  }

  .originating-account .originating-account h5 {
    font-size: 16px;
    padding: 8px 8px;
  }

  .originating-account .originating-account .table thead tr th {
    font-size: 12px;
    padding: 8px 8px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .deposit-funds .sidebar {
    height: 960px;
  }

  .originating-account .originating-account .table thead tr th {
    font-size: 12px;
    padding: 12px 12px;
  }

  .originating-account .table tbody td {
    text-align: start;
    font-size: 12px;
    padding: 12px 12px;
  }

  .deposit-funds-tabs .money h3,
  .deposit-funds-tabs .beneficiary h3,
  .deposit-funds-tabs .review h3,
  .deposit-funds-tabs .originator h3 {
    font-size: 24px;
    line-height: 35px;
  }

  .deposit-funds-tabs .money i,
  .deposit-funds-tabs .beneficiary i,
  .deposit-funds-tabs .review i,
  .deposit-funds-tabs .originator i {
    font-size: 24px;
    line-height: 35px;
  }

  .deposit-funds-tabs .money,
  .deposit-funds-tabs .beneficiary,
  .deposit-funds-tabs .review,
  .deposit-funds-tabs .originator {
    width: 40px;
    height: 40px;
    border: 2px solid #cecccc;
  }

  .deposit-funds-tabs ul li h6 {
    font-size: 18px;
    margin-top: 14px;
  }

  .deposit-funds-tabs {
    margin-left: 0px;
  }

  .deposit-funds-tabs ul .originator-m {
    margin-right: 50px;
  }

  .deposit-funds-tabs ul .beneficiary-m {
    margin-right: 80px;
  }

  .deposit-funds-tabs ul .money-m {
    margin-right: 91px;
  }

  .deposit-funds-tabs .money::after,
  .deposit-funds-tabs .beneficiary::after,
  .deposit-funds-tabs .originator::after {
    width: 110px;
    height: 2px;
    left: 44px;
  }

  .originating-account h3 {
    font-size: 26px;
    margin-top: 40px;
    margin-bottom: 22px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .deposit-funds .sidebar {
    height: 900px;
  }

  .deposit-funds-tabs .money h3,
  .deposit-funds-tabs .beneficiary h3,
  .deposit-funds-tabs .review h3,
  .deposit-funds-tabs .originator h3 {
    font-size: 24px;
    line-height: 35px;
  }

  .deposit-funds-tabs .money i,
  .deposit-funds-tabs .beneficiary i,
  .deposit-funds-tabs .review i,
  .deposit-funds-tabs .originator i {
    font-size: 24px;
    line-height: 35px;
  }

  .deposit-funds-tabs .money,
  .deposit-funds-tabs .beneficiary,
  .deposit-funds-tabs .review,
  .deposit-funds-tabs .originator {
    width: 40px;
    height: 40px;
    border: 2px solid #cecccc;
  }

  .deposit-funds-tabs ul li h6 {
    font-size: 18px;
    margin-top: 14px;
  }

  .deposit-funds-tabs {
    margin-left: 0px;
  }

  .deposit-funds-tabs ul .originator-m {
    margin-right: 44px;
  }

  .deposit-funds-tabs ul .beneficiary-m {
    margin-right: 74px;
  }

  .deposit-funds-tabs ul .money-m {
    margin-right: 91px;
  }

  .deposit-funds-tabs .money::after,
  .deposit-funds-tabs .beneficiary::after,
  .deposit-funds-tabs .originator::after {
    width: 110px;
    height: 2px;
    left: 40px;
  }

  .originating-account .originating-account .table thead tr th {
    font-size: 14px;
    padding: 12px 16px;
  }

  .originating-account .originating-account h5 {
    padding: 16px 16px;
  }

  .originating-account .originating-account .table tbody td {
    padding: 12px 16px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .deposit-funds-tabs ul .originator-m {
    margin-right: 105px;
  }

  .deposit-funds-tabs .money::after,
  .deposit-funds-tabs .beneficiary::after,
  .deposit-funds-tabs .originator::after {
    width: 135px;
    height: 3px;
    left: 66px;
  }

  .deposit-funds-tabs ul .beneficiary-m {
    margin-right: 143px;
  }

  .deposit-funds-tabs ul .money-m {
    margin-right: 143px;
  }

  .originating-account .originating-account .table thead tr th {
    padding: 12px 15px;
  }

  .originating-account .originating-account h5 {
    padding: 16px 15px;
  }

  .originating-account .originating-account .table tbody td {
    padding: 12px 15px;
  }
}

@media (min-width: 1400px) and (max-width: 1532px) {
  .originating-account .originating-account .table thead tr th {
    padding: 12px 12px;
    font-size: 13px;
  }

  .originating-account .originating-account h5 {
    padding: 16px 12px;
  }

  .originating-account .originating-account .table tbody td {
    padding: 12px 12px;
  }

  .sidebar-main .sidebar-main-label h3 {
    margin-left: 15px;
    font-size: 18px;
  }
}

.deposit-funds-page h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 28px;
  margin: 70px 0 30px;
  color: #aa8247;
}

.deposit-funds-page .money-deposit-box {
  background: #ffffff;
  border: 1px solid #aa8247;
  border-radius: 4px;
  padding: 16px 16px 16px 24px;
}

.deposit-funds-page
  .money-deposit-box
  .deposit-amount-box
  .your-balance-box
  .service-fee {
  display: flex;
  align-items: center;
  margin-top: 14px;
}

.deposit-funds-page
  .money-deposit-box
  .deposit-amount-box
  .your-balance-box
  .service-fee
  p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
}

.deposit-funds-page
  .money-deposit-box
  .deposit-amount-box
  .your-balance-box
  .service-fee
  h4 {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: #aa8247;
  margin-bottom: 0;
  margin: 0 15px;
}

.deposit-funds-page
  .money-deposit-box
  .deposit-amount-box
  .your-balance-box
  .service-fee
  .line {
  width: 62.86px;
  height: 0;
  border: 1px solid #aa8247;
}

.deposit-funds-page
  .money-deposit-box
  .deposit-amount-box
  .your-balance-box
  .your-balance
  .line {
  border: 1px dashed #ffffff;
  margin: 12px 0;
  width: 107%;
}

.deposit-funds-page
  .money-deposit-box
  .deposit-amount-box
  .your-balance-box
  .your-balance
  h2 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 0;
}

.deposit-funds-page
  .money-deposit-box
  .deposit-amount-box
  .your-balance-box
  .your-balance
  h6 {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 8px;
  color: #ffffff;
}

.deposit-funds-page
  .money-deposit-box
  .deposit-amount-box
  .your-balance-box
  .your-balance {
  text-align: end;
}

.deposit-funds-page .money-deposit-box .deposit-amount-box .your-balance-box {
  width: 100%;
}

.deposit-funds-page
  .money-deposit-box
  .deposit-amount-box
  .your-balance-box
  .row {
  padding: 9px 9px 9px 0;
  background: #aa8247;
  border-radius: 4px;
}

.deposit-funds-page .money-deposit-box .deposit-amount-box {
  display: flex;
}

.deposit-funds-page
  .money-deposit-box
  .deposit-amount-box
  .money-line
  .enter-money-img {
  margin-top: 16px;
}

.deposit-funds-page
  .money-deposit-box
  .deposit-amount-box
  .money-line
  .get-img {
  margin-top: 328px;
  margin-bottom: 16px;
}

.deposit-funds-page .money-deposit-box .deposit-amount-box .money-line {
  background: #aa8247;
  border-radius: 20px;
  width: 24px;
  height: 409px;
  text-align: center;
}

@media (max-width: 767px) {
  .deposit-funds-page
    .money-deposit-box
    .deposit-amount-box
    .your-balance-box
    .your-balance
    h2 {
    font-size: 22px;
  }

  .deposit-funds-page
    .money-deposit-box
    .deposit-amount-box
    .your-balance-box
    .your-balance
    h6 {
    font-size: 10px;
    margin-bottom: 6px;
  }

  .deposit-funds-page .money-deposit-box .deposit-amount-box .money-line {
    height: 384px;
  }

  .deposit-funds-page
    .money-deposit-box
    .deposit-amount-box
    .money-line
    .get-img {
    margin-top: 303px;
  }

  .deposit-funds-page h3 {
    font-size: 24px;
    margin: 30px 0 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .deposit-funds-page h3 {
    font-size: 26px;
    margin: 50px 0 20px;
  }

  .deposit-funds-page .money-deposit-box {
    padding: 12px 12px 12px 12px;
  }

  .deposit-funds-page
    .money-deposit-box
    .deposit-amount-box
    .your-balance-box
    .your-balance
    h2 {
    font-size: 18px;
  }

  .deposit-funds-page
    .money-deposit-box
    .deposit-amount-box
    .your-balance-box
    .your-balance
    h6 {
    font-size: 10px;
    margin-bottom: 6px;
  }

  .deposit-funds-page .money-deposit-box .deposit-amount-box .money-line {
    height: 374px;
  }

  .deposit-funds-page
    .money-deposit-box
    .deposit-amount-box
    .money-line
    .get-img {
    margin-top: 293px;
  }
}

@media (min-width: 1400px) and (max-width: 1899px) {
  .deposit-funds-page
    .money-deposit-box
    .deposit-amount-box
    .your-balance-box
    .your-balance
    h2 {
    font-size: 24px;
  }

  .deposit-funds-page
    .money-deposit-box
    .deposit-amount-box
    .your-balance-box
    .your-balance
    h6 {
    font-size: 10px;
    margin-bottom: 6px;
  }

  .deposit-funds-page .money-deposit-box .deposit-amount-box .money-line {
    height: 388px;
  }

  .deposit-funds-page
    .money-deposit-box
    .deposit-amount-box
    .money-line
    .get-img {
    margin-top: 306px;
  }
}

.review-page .review-box .review-list .review-originator .originator-list p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  text-align: right;
  color: #000000;
  margin-bottom: 0;
}

.review-page .review-box .review-list .review-originator .originator-list h6 {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
}

.review-page .review-box .review-list .review-originator .originator-list {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}

.review-page .review-box .review-list .review-originator {
  width: 45%;
}

.review-page .review-box .review-list .review-originator h4 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
  margin-bottom: 12px;
  color: #000000;
}

.review-page .review-box .review-list {
  padding: 24px;
  display: flex;
  justify-content: space-between;
}

.review-page .review-box .review-box-head .transfer h2 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 0;
}

.review-page .review-box .review-box-head .transfer p {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0px;
}

.review-page .review-box .review-box-head .transfer .transfer-icon {
  width: 58.52px;
  height: 64px;
  line-height: 64px;
  background: #ffffff;
  border-radius: 4px;
  text-align: center;
}

.review-page .review-box .review-box-head .transfer {
  display: flex;
  align-items: center;
  width: 45%;
}

.review-page .review-box .review-box-head {
  background: #aa8247;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
}

.review-page .review-box {
  background: #ffffff;
  border: 1px solid #aa8247;
  border-radius: 4px;
}

.review-page h3 {
  margin: 40px 0 20px;
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 24px;
  color: #aa8247;
}

.originating-account h4 {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 22px;
}

@media (max-width: 767px) {
  .originating-account h4 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .review-page h3 {
    margin: 30px 0 20px;
    font-size: 20px;
  }

  .review-page .review-box .review-box-head {
    display: block;
    padding: 10px 10px;
  }

  .review-page .review-box .review-box-head .transfer {
    width: 100%;
  }

  .review-page .review-box .review-box-head .transfer h2 {
    font-size: 22px;
  }

  .review-page .review-box .review-box-head .transfer p {
    margin-bottom: 6px;
  }

  .review-page .review-box .review-list {
    padding: 15px;
    display: block;
  }

  .review-page .review-box .review-list .review-originator {
    width: 100%;
  }

  .review-page .review-box .review-list .review-originator .originator-list h6 {
    font-size: 12px;
  }

  .review-page .review-box .review-list .review-originator .originator-list p {
    font-size: 14px;
  }

  .review-page .review-box .review-list .review-originator .originator-list {
    margin-bottom: 12px;
  }

  .review-page .review-box .review-list .review-originator h4 {
    font-size: 18px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .review-page .review-box .review-box-head .transfer h2 {
    font-size: 22px;
  }

  .originating-account h4 {
    font-size: 16px;
    margin-bottom: 24px;
  }

  .review-page h3 {
    margin: 50px 0 30px;
    font-size: 26px;
  }

  .review-page .review-box .review-list {
    padding: 24px 14px;
  }

  .review-page .review-box .review-list .review-originator .originator-list h6 {
    font-size: 12px;
  }

  .review-page .review-box .review-list .review-originator .originator-list p {
    font-size: 13px;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
  .review-page .review-box .review-box-head .transfer h2 {
    font-size: 20px;
  }
}

.disableElement .nav-link .review::after,
.disableElement .nav-link .money::after,
.disableElement .nav-link .beneficiary::after,
.disableElement .nav-link .originator::after {
  background: #aa8247;
  opacity: 0.5;
}

.disableElement .nav-link .review,
.disableElement .nav-link .money,
.disableElement .nav-link .beneficiary,
.disableElement .nav-link .originator {
  border: 3px solid #aa8247;
  color: #fff;
  background: #aa8247 !important;
  opacity: 0.5;
}

.disableElement .nav-link h6 {
  color: #aa8247;
  opacity: 0.5;
}

.disableElement .money i,
.disableElement .beneficiary i,
.disableElement .review i,
.disableElement .originator i {
  display: block;
}

.disableElement .money h3,
.disableElement .beneficiary h3,
.disableElement .review h3,
.disableElement .originator h3 {
  display: none;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .token-supply .wallet-balances-box .table thead tr th {
    padding: 25px 25px !important;
  }

  .token-supply .wallet-balances-box .table tbody td {
    padding: 20px 25px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .token-supply .wallet-balances-box .table thead tr th {
    padding: 25px 6px !important;
  }

  .token-supply .wallet-balances-box .table tbody td {
    padding: 20px 6px !important;
  }
}

.reject-page {
  margin-left: 95px;
}

.reject-page h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  margin-bottom: 20px;
}

.reject-page .reject-box {
  background: #ffffff;
  border: 1px solid #f1f0f0;
  border-radius: 4px;
}

.reject-page .reject-box .reject-head {
  background: #aa8247;
  border-radius: 4px 4px 0px 0px;
  padding: 12px 24px;
}

.reject-page .reject-box .reject-head .form-control,
.reject-page .reject-box .reject-head .input-group-text {
  background: #ffffff;
  border: 0;
}

.reject-page .reject-box .reject-details {
  padding: 24px 24px 10px;
}

.reject-page .reject-box .reject-details .reject-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.reject-page .reject-box .reject-details .reject-list h6 {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
}

.reject-page .reject-box .reject-details .reject-list p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .reject-page .reject-box .reject-details .reject-list {
    display: block !important;
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .reject-page {
    margin-left: 0px;
  }

  .reject .sidebar {
    height: 360px;
  }

  .reject .main-heading h3 {
    font-size: 18px;
  }

  .reject-page h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .reject-page .reject-box .reject-details {
    padding: 16px 16px 6px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .reject .main-heading h3 {
    font-size: 26px;
  }

  .reject-page {
    margin-left: 40px;
  }

  .reject-page h3 {
    font-size: 22px;
    margin-bottom: 15px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .reject-page .reject-box .reject-details {
    padding: 17px 8px 4px;
  }
}

.modify-token .reject-page p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 28px;
  color: #000000;
}

.modify-token .reject-page h3 {
  margin-top: 0;
}

.modify-token .modify-token-head {
  width: 38%;
}

.modify-token .review-box-head label {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-bottom: 8px;
}

.modify-token .review-list {
  padding: 24px 24px 12px !important;
}

.modify-token .form-check {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.modify-token .form-check label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
  margin-left: 16px;
}

.modify-token .form-check .form-check-input:checked {
  background-color: #aa8247;
  border-color: #aa8247;
}

.modify-token
  .wallet-information-box
  .wallet-information-form
  .form-check-input {
  border: 1px solid #cecccc !important;
  border-radius: 10px !important;
}

.modify-token .form-check .form-check-input {
  width: 24px;
  height: 24px;
  border: 1px solid #aa8247;
  border-radius: 50% !important;
}

.modify-token .review-originator {
  width: 38% !important;
}

.modify-token .sidebar {
  height: 920px;
}

.create-issuer-information .wallet-information-form .mb-xl-0:last-child {
  margin-bottom: 0;
}

.create-issuer-information .wallet-information-form .mb-xl-0 {
  margin-bottom: 60px !important;
}

.create-issuer-information .sidebar {
  height: 1720px;
}

.review-new-token .review-wallet-info-box h5 {
  font-family: "Source Sans Pro";
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #000000 !important;
}

.review-new-token .sidebar {
  height: 1230px !important;
}

.post-review-rate .sidebar {
  height: 822px;
}

.create-review-issuer .review-wallet-info-box .col-xl-3:last-child {
  margin-bottom: 0;
}

.create-review-issuer .review-wallet-info-box .col-xl-3 {
  margin-bottom: 60px;
}

.create-review-issuer .sidebar {
  height: 1420px !important;
}

@media (max-width: 575px) {
  .modify-token .modify-token-head {
    width: 100%;
  }

  .modify-token .review-box-head label {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .modify-token .review-list {
    padding: 14px 14px 2px !important;
  }

  .modify-token .review-originator {
    width: 100% !important;
  }

  .wallet-information-box .wallet-information-form .input-group {
    width: 100%;
  }

  .create-issuer-information .wallet-information-form .mb-xl-0 {
    margin-bottom: 0px !important;
  }

  .review-new-token .sidebar {
    height: 350px !important;
  }

  .post-review-rate .sidebar {
    height: 350px !important;
  }

  .create-review-issuer .review-wallet-info-box .col-xl-3 {
    margin-bottom: 0px;
  }

  .create-review-issuer .sidebar {
    height: 350px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .modify-token .review-box-head label {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .create-issuer-information .wallet-information-form .mb-xl-0 {
    margin-bottom: 30px !important;
  }

  .modify-token .review-box .review-list {
    display: flex;
  }

  .modify-token .review-box .review-box-head {
    display: flex;
  }

  .modify-token .review-originator {
    width: 45% !important;
  }

  .modify-token .modify-token-head {
    width: 45%;
  }

  .modify-token .form-check label {
    font-size: 15px;
  }

  .wallet-information-box .wallet-information-form .input-group {
    width: 100%;
  }

  .review-new-token .sidebar {
    height: 350px !important;
  }

  .post-review-rate .sidebar {
    height: 350px !important;
  }

  .create-review-issuer .review-wallet-info-box .col-xl-3 {
    margin-bottom: 30px;
  }

  .create-review-issuer .sidebar {
    height: 350px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .modify-token .modify-token-head {
    width: 45%;
  }

  .create-issuer-information .wallet-information-form .mb-xl-0 {
    margin-bottom: 50px !important;
  }

  .modify-token .review-originator {
    width: 45% !important;
  }

  .modify-token .form-check .form-check-input {
    width: 20px;
    height: 20px;
  }

  .modify-token .form-check label {
    font-size: 14px;
    margin-left: 12px;
  }

  .modify-token .sidebar {
    height: 820px;
  }

  .create-issuer-information .sidebar {
    height: 2250px;
  }

  .post-review-rate .sidebar {
    height: 726px !important;
  }

  .create-review-issuer .review-wallet-info-box .col-xl-3 {
    margin-bottom: 40px;
  }

  .create-review-issuer .sidebar {
    height: 1780px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .wallet-information-box .wallet-information-form .input-group {
    width: 100%;
  }

  .create-review-issuer .review-wallet-info-box .col-xl-3 {
    margin-bottom: 40px;
  }

  .create-review-issuer .sidebar {
    height: 1380px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .modify-token .modify-token-head {
    width: 42%;
  }

  .create-issuer-information .wallet-information-form .mb-xl-0 {
    margin-bottom: 50px !important;
  }

  .modify-token .review-originator {
    width: 42% !important;
  }

  .create-issuer-information .sidebar {
    height: 2370px;
  }

  .post-review-rate .sidebar {
    height: 726px !important;
  }

  .create-review-issuer .review-wallet-info-box .col-xl-3 {
    margin-bottom: 40px;
  }

  .create-review-issuer .sidebar {
    height: 1300px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1799px) {
  .modify-token .reject-page .col-xxl-6 {
    width: 60%;
  }

  .modify-token .form-check label {
    font-size: 15px;
    margin-left: 9px;
  }
}

@media (min-width: 1400px) and (max-width: 1899px) {
  .post-review-rate .sidebar {
    height: 726px !important;
  }
}

.money-transfer .sidebar {
  height: 1150px !important;
}

@media (max-width: 767px) {
  .money-transfer .sidebar {
    height: 350px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .money-transfer .sidebar {
    height: 1000px !important;
  }
}

.tab-pane .wallet-information-form i {
  position: absolute;
  top: 61%;
  transform: translateY(-50%);
  right: 0%;
  color: #46a4b6;
  font-size: 24px;
}

.nav-tabs .escrow-information-mr {
  margin-right: 30px;
}

.nav-tabs .originator-mr {
  margin-right: 71px;
}

.nav-tabs .beneficiary-mr {
  margin-right: 85px;
}

.nav-tabs .money-mr {
  margin-right: 105px;
}

.nav-tabs .review-mr {
  margin-right: 88px;
}

.form-control-file {
  position: relative;
  height: 34px;
}

[type="file"]::-webkit-file-upload-button {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  border: 0;
  padding: 5px 20px;
  margin-right: 20px;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #ffffff;
  position: absolute;
  right: 0;
  z-index: 99;
}

.create-escrow .sidebar {
  height: 1140px !important;
}

.fx-country {
  display: flex;
}

.fx-country .fx-country-box {
  background: #ffffff;
  border: 1px solid #aa8247;
  box-sizing: border-box;
  border-radius: 4px;
  width: 200px;
  height: 120px;
  align-content: center;
  display: flex;
  position: relative;
  padding: 10px;
}

.fx-country .fx-country-box .fx-country-card h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  align-items: center;
  letter-spacing: 0.03em;
  color: #aa8247;
  margin-bottom: 0;
}

.fx-country .fx-country-box .fx-country-card h5 {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 2px;
  margin-top: 0;
}

.fx-country .fx-country-box .fx-country-card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fx-country .fx-country-box .fx-country-card .fx-country-flag h6 {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
}

.fx-country .fx-country-box .fx-country-card .fx-country-icon {
  background: #aa8247;
  height: 24px;
  width: 24px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  right: 6px;
}

.fx-country .fx-country-box .fx-country-card .fx-country-flag {
  background: rgba(10, 157, 225, 0.2);
  width: 62px;
  height: 62px;
  border-radius: 100%;
  line-height: 40px;
  text-align: center;
}

.balance-box hr {
  border: 1px solid #aa8247;
  margin-top: 0;
}

.fx-rates-box {
  padding: 8px 16px 22px;
}

.fx-rates-box .account {
  margin-left: -8px;
}

.fx-rates-box h3 {
  margin-bottom: 25px;
}

@media (max-width: 450px) {
  .fx-country {
    display: inline-block;
  }
}

.unicoin-function p {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 65px;
}

.unicoin-function h4 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 28px;
  color: #000000;
  margin: 54px 0 20px;
}

@media (max-width: 767px) {
  .unicoin-function h4 {
    font-size: 22px;
    margin: 32px 0 12px;
  }

  .unicoin-function p {
    font-family: "Oxygen";
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: #000000;
    margin-bottom: 40px;
  }
}

.transfer-rates-page .main-heading {
  padding-bottom: 0;
}

.transfer-rates-page .nav-tabs .nav-link h3 {
  margin-bottom: 0;
  margin-left: 10px;
}

.transfer-rates-page .nav-tabs .nav-link {
  margin-bottom: 0px;
  background: 0 0;
  border: 0px solid transparent;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 28px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  height: 76px;
}

.transfer-rates-page .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #aa8247;
}

.transfer-rates-page
  .transfer-rates
  .nav-tabs
  .nav-item.show
  .nav-link
  .domestic
  .show,
.transfer-rates-page
  .transfer-rates
  .nav-tabs
  .nav-link.active
  .domestic
  .show {
  display: none;
}

.transfer-rates-page
  .transfer-rates
  .nav-tabs
  .nav-item.show
  .nav-link
  .domestic
  .none,
.transfer-rates-page
  .transfer-rates
  .nav-tabs
  .nav-link.active
  .domestic
  .none {
  display: block;
  margin: auto;
}

.transfer-rates-page
  .transfer-rates
  .nav-tabs
  .nav-item.show
  .nav-link
  .domestic,
.transfer-rates-page .transfer-rates .nav-tabs .nav-link.active .domestic {
  line-height: 48px;
  background: #aa824733;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transfer-rates-page .transfer-rates .nav-tabs .nav-item.show .nav-link,
.transfer-rates-page .transfer-rates .nav-tabs .nav-link.active {
  color: #000000 !important;
  background-color: #fff;
  border-bottom: 4px solid #aa8247;
}

.transfer-rates-page .transfer-rates .domestic .none {
  display: none;
}

.transfer-rates-page .transfer-rates .domestic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.transfer-rates-page .transfer-rates .nav-tabs .nav-link.active h3 {
  color: #000;
}

.nav-link h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.5);
}

.transfer-rates-page .transfer-rates p {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.03em;
  color: #000000;
  width: 88%;
  margin: 50px 0 30px !important;
}

.transfer-rates-page .sidebar {
  height: 950px;
}

@media (max-width: 767px) {
  .transfer-rates-page .transfer-rates p {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    margin: 30px 0 20px !important;
  }

  .transfer-rates-page .transfer-rates .domestic {
    width: 45px;
    height: 45px;
  }

  .transfer-rates-page .nav-tabs li {
    width: 100%;
  }

  .transfer-rates-page .nav-tabs li .nav-link {
    width: 100%;
  }

  .transfer-rates-page .nav-tabs .nav-link {
    padding: 0;
  }

  .transfer-rates-page .sidebar {
    height: 350px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .transfer-rates-page .transfer-rates p {
    font-size: 16px;
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .transfer-rates-page .transfer-rates p {
    font-size: 16px;
    width: 100%;
  }
}

.transaction-result-error .transaction-result-error-body p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 0;
  margin-left: 10px;
}

.transaction-result-error .transaction-result-error-body {
  padding: 25px 40px;
}

.transaction-result-error .transaction-result-error-head h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 0;
  margin-left: 15px;
  color: #ffffff;
}

.transaction-result-error .transaction-result-error-head {
  background: #dd3232;
  display: flex;
  padding: 20px 20px 20px 40px;
  justify-content: space-between;
}

.transaction-result-error {
  background: #f7c4c4;
}

.transaction-successful .transaction-successful-head h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 0;
  margin-left: 20px;
}

.transaction-successful .transaction-successful-head {
  background: #aa8247;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 30px;
}

.transaction-successful .transaction-successful-body p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
}

.transaction-successful .transaction-successful-body h6 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
}

.transaction-successful .transaction-successful-body h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 24px;
}

.transaction-successful .transaction-successful-body {
  padding: 30px;
}

.transaction-successful {
  background: #ffffff;
  border: #aa8247;
  border-radius: 4px;
}

.transaction-result-page .sidebar {
  height: 950px;
}

@media (max-width: 767px) {
  .transaction-result-page .sidebar {
    height: 550px;
  }

  .transaction-result-error .transaction-result-error-head {
    padding: 10px;
  }

  .transaction-result-error .transaction-result-error-head h3 {
    font-size: 18px;
    margin-left: 8px;
  }

  .transaction-result-error .transaction-result-error-body {
    padding: 20px 20px;
  }

  .transaction-result-error .transaction-result-error-body p {
    font-size: 14px;
    margin-left: 10px;
  }

  .transaction-successful .transaction-successful-head {
    padding: 8px 10px;
  }

  .transaction-successful .transaction-successful-head h3 {
    font-size: 22px;
  }

  .transaction-successful .transaction-successful-body {
    padding: 10px;
  }

  .transaction-successful .transaction-successful-body h3 {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .transaction-successful .transaction-successful-body h6 {
    font-size: 12px;
  }

  .transaction-successful .transaction-successful-body p {
    font-size: 12px;
  }
}

.welcome .welcome-bg-img .carousel-caption .input-box img {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 0;
  color: #46a4b6;
  font-size: 24px;
}

.welcome .welcome-bg-img .carousel-caption .form-control {
  display: block;
  width: 100%;
  padding: 0;
  padding-bottom: 10px;
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 23px;
  letter-spacing: 0.03em !important;
  color: #fff !important;
  border: 0;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  transition: unset;
  background: none;
}

.welcome .welcome-bg-img .carousel-caption .validate:hover {
  background-color: #5b5b5b !important;
  color: #fff !important;
}
.welcome .welcome-bg-img .carousel-caption .validate {
  background: #aa8247;
  border-radius: 4px;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  border: 0;
  width: 100%;
  height: 40px;
  margin-top: 36px;
  transition: all 0.5s;
}

.welcome .welcome-bg-img .carousel-caption .form-check label {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.welcome .welcome-bg-img .carousel-caption .form-check-input:checked {
  background-color: #aa8247;
  border-color: #aa8247;
}

.welcome .welcome-bg-img .carousel-caption .form-check-input {
  width: 18px;
  height: 18px;
  border: unset;
}

.welcome .welcome-bg-img .carousel-caption h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-bottom: 45px;
}

.welcome .welcome-bg-img .carousel-caption {
  right: 15px;
  left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.welcome .welcome-bg-img .carousel-caption img {
  margin-bottom: 30px;
}

.welcome .welcome-bg-img .wallet-icon {
  background-color: #080d35;
}

.welcome .welcome-bg-img .carousel-caption .input-box {
  position: relative;
}

.welcome .welcome-bg-img .carousel-caption label {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  text-align: start !important;
  margin-bottom: 15px;
}

.welcome .welcome-bg-img {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999999999;
  transform: translate(-50%, -50%);
}

.welcome-to-the-unicoin-network {
  /*   background: rgba(0, 0, 0, 0.4);
    filter: blur(1.2px);*/
  z-index: 9999999;
  position: relative;
  /*pointer-events: none;*/
}

@media (max-width: 767px) {
  .welcome .welcome-bg-img .wallet-icon img {
    width: 310px;
  }

  .welcome .welcome-bg-img .carousel-caption h3 {
    font-size: 15px;
    margin-bottom: 30px;
  }

  .welcome .welcome-bg-img .carousel-caption img {
    margin-bottom: 15px;
  }

  .welcome .welcome-bg-img .carousel-caption .validate {
    margin-top: 5px;
  }

  .welcome .welcome-bg-img .carousel-caption {
    right: 20px;
    left: 20px;
    padding-bottom: 3px;
  }
}

.documentation .documentation-tabs {
  margin-left: 45px;
}

.documentation .documentation-tabs .nav-tabs {
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  align-items: center;
}

.documentation .documentation-tabs .nav-link {
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #808080;
}

.documentation .documentation-tabs .nav-tabs .nav-link {
  margin-bottom: 0;
  background: 0 0;
  border: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.documentation
  .documentation-tabs
  .transfer-rates-page
  .nav-tabs
  .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: unset !important;
  color: #d9ae69;
}

.documentation .documentation-tabs .nav-tabs .nav-item.show .nav-link,
.documentation .documentation-tabs .nav-tabs .nav-link.active {
  font-family: "Fira Code";
  font-weight: 700;
  font-size: 24px;
  color: #ffffff !important;
  background-color: unset;
  border-color: unset;
  border-bottom: 4px solid #d9ae69 !important;
  padding: 10px 20px;
}

.documentation .main-heading h3 {
  font-family: "Fira Code";
  font-weight: 600;
  font-size: 34px;
  color: #ffffff;
}

.documentation {
  background-color: #18172a;
}

.documentation .navbar-expand-xl .navbar-nav .nav-link.active {
  color: #000000;
}

.tab-pane .wallet-information-form .input-group-text i {
  position: unset;
  top: unset;
  transform: unset;
  right: unset;
  color: unset;
  font-size: unset;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiTableContainer-root.css-175ib4q-MuiPaper-root-MuiTableContainer-root
  .table {
  border: 0;
}
.wallet-balances-box .table {
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  margin-top: 0px;
}
.documentation .navbar-expand-xl .navbar-nav .nav-link {
  color: #00000099;
}

.documentation .root {
  background: #d9ae69;
}

.documentation-accordion .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.documentation-accordion .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.documentation-accordion .accordion-item {
  background-color: transparent;
  border: 0;
}

.documentation-accordion .accordion-header:hover {
  color: #d9ae69;
}

.documentation-accordion .accordion-header i {
  font-size: 25px;
}

.documentation-accordion .accordion-header {
  font-family: "Fira Code";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-bottom: 24px;
  transition: all 0.3s;
}

.documentation-accordion .accordion-body {
  font-family: "Space Mono";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 24px;
  padding: 0;
}

.documentation-accordion .accordion-button {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.documentation-accordion .accordion-button .show {
  display: block;
}

.documentation-accordion .accordion-button .none {
  display: none;
}

.documentation-accordion .collapsed .show {
  display: none;
}

.documentation-accordion .collapsed .none {
  display: block;
}

.documentation-accordion hr {
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin-top: 0;
  margin-bottom: 32px;
}

.documentation-accordion .accordion-button::after {
  content: unset;
}

.documentation-accordion {
  margin-left: 57px;
  margin-top: 60px;
}

.documentation .sidebar {
  height: 100%;
}

@media (max-width: 767px) {
  .documentation .main-heading h3 {
    font-family: "Fira Code";
    font-weight: 600;
    font-size: 28px;
    color: #ffffff;
    margin-left: 0;
  }

  .documentation .documentation-tabs .nav-tabs {
    display: block;
  }

  .documentation .documentation-tabs {
    margin-left: 0px;
  }

  .documentation-accordion {
    margin-left: 0px;
    margin-top: 30px;
  }

  .documentation-accordion .accordion-header {
    margin-bottom: 14px;
  }

  .documentation-accordion .accordion-header i {
    font-size: 20px;
  }

  .documentation-accordion .accordion-body {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .documentation .documentation-tabs {
    margin-left: 0px;
  }

  .documentation-accordion {
    margin-left: 12px;
    margin-top: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .documentation-accordion {
    margin-left: 30px;
    margin-top: 40px;
    margin-right: 15px;
  }

  .documentation .documentation-tabs {
    margin-left: 16px;
  }
}

.login-page .login-box .login-text .form-check a {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 16px !important;
  line-height: 20px;
  color: #aa8247;
}

.login-page .login-box .login-text .form-check label {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  margin: 3px 0 0 8px;
}

.login-page .login-box .login-text .form-check input {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  width: 24px;
  height: 24px;
}

.login-page .login-box .login-text #btn a {
  color: #ffffff !important;
}

.login-page .login-box .login-text #btn {
  font-family: "Source Sans Pro";
  font-weight: 600 !important;
  font-size: 16px !important;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff !important;
  background: #aa8247 !important;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  margin-top: 60px;
}

.login-page .login-box .login-text .form-check-input:checked {
  background-color: #aa8247;
  border-color: #aa8247;
}

.login-page .login-box .login-text .form-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-page .login-box .login-text .password-eye i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  font-size: 20px;
  color: #00000080;
  cursor: pointer;
}

.login-page .login-box .login-text .password-eye {
  position: relative;
}

.login-page .login-box .login-text p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 12px;
  color: #dd3232;
  margin-top: 2px;
  margin-bottom: 0;
}

.login-page .login-box .login-text input {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.login-page .login-box .login-text label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0px;
}

.login-page .login-box .login-text h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 38px;
  letter-spacing: 0.05em;
  color: #000000;
  margin-bottom: 60px;
}

.login-page .login-box .login-text img {
  margin-bottom: 142px;
}

.login-page .login-box {
  padding: 0 80px;
}

.login-page .login-img img {
  height: 100vh;
}

.login-img {
  background-image: url(../../public/images/dl.beatsnoop2.png);
  width: 100%;
  height: 100vh;
  background-size: cover;
}

@media (max-width: 767px) {
  .login-page .login-img {
    height: 270px;
  }

  .login-page .login-img img {
    height: 100%;
  }

  .login-page .login-box {
    padding: 50px 0 30px;
  }

  .login-page .login-box .login-text img {
    margin-bottom: 40px;
  }

  .login-page .login-box .login-text h3 {
    font-size: 26px;
    margin-bottom: 30px;
  }

  .login-page .login-box .login-text .form-check a {
    font-size: 14px !important;
    line-height: 0px;
  }

  .login-page .login-box .login-text .form-check label {
    font-size: 14px;
    margin: 6px 0 0 8px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .login-page .login-box {
    padding: 0 20px;
  }

  .login-page .login-box .login-text img {
    margin-bottom: 60px;
  }

  .login-page .login-box .login-text h3 {
    font-size: 28px;
    margin-bottom: 38px;
  }

  .login-page .login-box .login-text .form-check a {
    font-size: 12px !important;
  }

  .login-page .login-box .login-text .form-check label {
    font-size: 14px;
    margin: 2px 0 0 0px;
  }

  .login-page .login-box .login-text .form-check input {
    width: 20px;
    height: 20px;
  }

  .login-page .login-box .login-text .btn {
    margin-top: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .login-page .login-box {
    padding: 0 10px;
  }

  .login-page .login-box .login-text img {
    margin-bottom: 30px;
  }

  .login-page .login-box .login-text h3 {
    font-size: 32px;
    margin-bottom: 45px;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .login-page .login-box {
    padding: 0 20px;
  }
}

@media (min-width: 1500px) and (max-width: 1899px) {
  .login-page .login-box {
    padding: 0 60px;
  }
}

.documentation .issuers .accordion-header.active h3 {
  color: #fff;
}

.documentation .issuers .accordion-header.active {
  background: #444348;
}

.faq-list .tab-content {
  width: 995px;
}

.faq-list {
  margin-left: 100px;
}

.faq-list h3 {
  font-family: "Fira Code";
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 16px;
  color: #ffffff;
}

.faq-list .nav-pills .nav-link {
  font-family: "Fira Code";
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #808080;
  text-align: start;
}

.faq-list .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background: unset !important;
}

.faq-list h2 {
  font-family: "Fira Code";
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 40px;
}

.faq .sidebar {
  height: 100%;
}

.faq-list .nav-pills .nav-link {
  padding-left: 0;
}

@media (max-width: 767px) {
  .faq-list {
    margin-left: 0;
  }

  .faq-list .tab-content {
    width: unset;
  }

  .faq-list h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .faq-list {
    margin-left: 0px;
  }

  .faq-list .tab-content {
    width: unset;
  }

  .faq-list h3 {
    font-size: 18px;
    margin-bottom: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .faq-list {
    margin-left: 10px;
  }

  .faq-list .tab-content {
    width: unset;
  }

  .faq-list h3 {
    font-size: 18px;
    margin-bottom: 14px;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .faq-list {
    margin-left: 20px;
  }

  .faq-list .tab-content {
    width: 620px;
  }

  .faq-list h3 {
    font-size: 18px;
    margin-bottom: 14px;
  }
}

@media (min-width: 1500px) and (max-width: 1699px) {
  .faq-list .tab-content {
    width: 870px;
  }

  .faq-list h3 {
    font-size: 24px;
  }
}

.news-page h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 22px;
  margin-top: 60px;
}

.news-page p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.news .sidebar {
  height: 100%;
}

.news-page-2 a {
  background: #aa8247;
  border-radius: 4px;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
  margin-top: 40px;
  padding: 6px 38px;
}

.news-page-2 {
  margin-top: 92px;
  margin-bottom: 30px;
}

.news-page-2 h3 {
  margin: 0 !important;
}

@media (max-width: 767px) {
  .news-page-2 h3 {
    margin: 14px 0 0 0 !important;
  }

  .news .main-heading h3 {
    margin-left: 0 !important;
  }

  .news-page img {
    width: 100%;
  }

  .news-page p {
    font-size: 14px;
  }

  .news-page h3 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 18px;
    margin-top: 50px;
  }

  .news-page-2 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .news-page-2 a {
    margin-top: 20px;
    padding: 8px 36px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .news-page img {
    width: 100%;
  }

  .news .main-heading h3 {
    margin-left: 0px;
  }

  .news-page h3 {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .news-page img {
    width: 100%;
  }

  .news .main-heading h3 {
    margin-left: 0px;
  }

  .news-page-2 h3 {
    font-size: 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .news-page img {
    width: 100%;
  }

  .news-page-2 h3 {
    font-size: 22px;
  }
}

@media (min-width: 1500px) and (max-width: 1799px) {
  .news-page img {
    width: 100%;
  }
}

.smart-card .smart-card-text h3 {
  font-family: "Libre Franklin";
  font-weight: 500;
  font-size: 40px;
  color: #ffffff;
  line-height: 55px;
}

.smart-card .smart-card-text h6 {
  font-family: "Quicksand";
  font-weight: 500;
  font-size: 24px;
  color: #d9ae69;
  margin-bottom: 18px;
}

.smart-card .technologies h2 {
  font-family: "Libre Franklin";
  font-weight: 500;
  font-size: 40px;
  text-align: center;
  margin-bottom: 50px;
  color: #ffffff;
}

.smart-card .technologies-box p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 0;
}

.smart-card .technologies-box h4 {
  font-family: "Libre Franklin";
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  margin: 18px 0 18px;
}

.smart-card .technologies-box {
  background: #000000;
  height: 100%;
  padding: 30px;
}

.smart-card .smart-card-bg {
  background-image: url(../../public/images/smart-card-bg-img.png);
  padding: 100px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.smart-card .smart-card-bg .smart-img {
  position: relative;
  z-index: 9;
}

.smart-card .smart-card-bg .smart-img::after {
  content: "";
  background-image: url(../../public/icons/slider-mobile-bg.png);
  height: 732px;
  width: 1318px;
  position: absolute;
  z-index: -9;
  top: -20%;
  background-repeat: no-repeat;
  right: -39px;
}

.smart-card {
  background: #121212;
}

@media (min-width: 1400px) and (max-width: 1799px) {
  .container {
    max-width: 1400px !important;
  }
}

@media (min-width: 1800px) {
  .smart-card .container {
    max-width: 1650px;
  }
}

@media (max-width: 320px) {
  .smart-card .smart-card-bg .smart-img::after {
    height: 462px;
    width: 320px;
    top: -9px;
    right: 33px;
    background-size: contain;
  }
}

@media (min-width: 321px) and (max-width: 767px) {
  .smart-card .smart-card-bg .smart-img::after {
    height: 462px;
    width: 705px;
    top: -57px;
    right: -10px;
    background-size: contain;
  }
}

@media (max-width: 767px) {
  .smart-card .smart-card-text {
    padding-top: 30px;
  }

  .smart-card .smart-card-text h6 {
    font-size: 16px;
    margin-bottom: 18px;
  }

  .smart-card .smart-card-text h3 {
    font-size: 30px;
  }

  .smart-card .technologies h2 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  .smart-card .technologies-box {
    padding: 20px;
  }

  .smart-card .technologies-box p {
    font-size: 16px;
  }

  .smart-card .technologies-box h4 {
    font-size: 24px;
    margin: 12px 0 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .smart-card .smart-card-text h6 {
    font-size: 15px;
    margin-bottom: 18px;
  }

  .smart-card .smart-card-text h3 {
    font-size: 36px;
  }

  .smart-card .technologies h2 {
    font-size: 40px;
    margin-bottom: 36px;
  }

  .smart-card .technologies-box {
    padding: 20px;
  }

  .smart-card .smart-card-bg .smart-img::after {
    height: 462px;
    width: 517px;
    top: -57px;
    right: 0;
    background-size: contain;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .smart-card .smart-card-text h3 {
    font-size: 40px;
  }

  .smart-card .smart-card-text h6 {
    font-size: 25px;
    margin-bottom: 30px;
  }

  .smart-card .technologies h2 {
    font-size: 40px;
    margin-bottom: 40px;
  }

  .smart-card .smart-card-bg .smart-img::after {
    height: 462px;
    width: 747px;
    top: -92px;
    right: -28px;
    background-size: contain;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .smart-card .smart-card-bg .smart-img::after {
    height: 462px;
    width: 867px;
    top: -102px;
    right: -28px;
    background-size: contain;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
  .smart-card .smart-card-bg .smart-img::after {
    height: 462px;
    width: 907px;
    top: -71px;
    right: -11px;
    background-size: contain;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .smart-card .smart-card-text h6 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  .smart-card .smart-card-text h3 {
    font-size: 60px;
  }

  .smart-card .technologies h2 {
    font-size: 50px;
    margin-bottom: 46px;
  }

  .smart-card .technologies-box {
    padding: 16px;
  }

  .smart-card .technologies-box h4 {
    font-size: 21px;
    margin: 18px 0 22px;
  }

  .smart-card .technologies-box p {
    font-size: 18px;
  }
}

@media (min-width: 1500px) and (max-width: 1799px) {
}

.pt-300 {
  padding-top: 300px;
}

.global {
  background: #011d44;
}

.global .global-img {
  position: relative;
}

.global .global-img .global-text {
  background: #104ea5;
  padding: 130px 100px;
  position: absolute;
  left: 0;
  bottom: -22%;
}

.global .global-img .global-text h3 {
  font-family: "Libre Franklin";
  font-weight: 500;
  font-size: 40px;
  color: #ffffff;
}

.global .global-img .global-text h6 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
}

.global .global-img .global-text h2 {
  font-family: "Libre Franklin";
  font-weight: 500;
  font-size: 60px;
  color: #30b1e8;
}

.global .global-img .global-text h5 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 26px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-top: 20px;
}

.global .global-img .global-text p {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 0;
}

.global .global-img .global-text p span {
  color: #b8a816;
}

.global .technology-heading {
  text-align: center;
  margin-bottom: 80px;
}

.global .technology-heading p {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 24px;
  color: #b8a816;
  margin-bottom: 0;
}

.global .technology-heading h3 {
  font-family: "IBM Plex Serif";
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
}

.global .t-b-1 {
  background-color: #052859;
}

.global .t-b-2 {
  background-color: #073371;
}

.global .t-b-3 {
  background-color: #0a4393;
}

.global .t-b-4 {
  background-color: #1d51b7;
}

.global .t-b-5 {
  background-color: #062c61;
}

.global .t-b-6 {
  background-color: #093b82;
}

.global .t-b-7 {
  background-color: #104ea5;
}

.global .t-b-8 {
  background-color: #225bca;
}

.global .technology-box {
  text-align: center;
  padding: 80px 40px;
  height: 100%;
}

.global .technology-box h3 {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 0;
  margin-top: 20px;
}

.global .footer {
  background-color: #093b82;
}

.network .global-img .global-text h3 span {
  font-style: italic;
  font-weight: 700;
}

.network .global-img .summit-text img {
  width: 70%;
}

.network .global-img .summit-text h2 {
  font-family: "Libre Franklin";
  font-weight: 500;
  font-size: 60px;
  color: #ffffff;
}

.network .global-img .summit-text {
  right: 0 !important;
  text-align: start !important;
  width: 100% !important;
  top: 10% !important;
}

.network .global-img .global-text {
  background: unset;
  padding: unset;
  position: absolute;
  left: unset;
  bottom: unset;
  right: 5%;
  top: 20%;
  text-align: end;
}

.fingerprint-img {
  position: relative;
}

.fingerprint-img .global-left {
  position: absolute;
  width: 628px;
  height: 424px;
  padding: 45px;
  background: #093b82;
  bottom: 4%;
  left: 8%;
}

.fingerprint-img .global-right {
  position: absolute;
  width: 628px;
  height: 424px;
  padding: 45px;
  background: #093b82;
  bottom: 4%;
  left: unset;
  right: 3%;
}

.fingerprint-img .global-left p {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}

.fingerprint-img .global-left hr {
  width: 100px;
  height: 3px;
  margin: 24px 0 32px;
  opacity: 1;
  background-color: #ee9321;
}

.fingerprint-img .global-left h3 {
  font-family: "IBM Plex Serif";
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 0;
}

.digital-page .footer {
  margin-top: 0;
}

.digital .digital-img-bg {
  margin-top: 150px;
}

.digital .digital-img-bg .carousel-caption {
  top: 50%;
  transform: translateY(-50%);
}

.digital .digital-img-bg .digital-text p {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.digital .digital-img-bg .digital-text h3 {
  font-family: "IBM Plex Serif";
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  color: #ffffff;
  margin-bottom: 10px;
}

.digital .digital-img-bg .digital-text {
  text-align: start;
}

.digital .digital-img-bg {
  position: relative;
}

@media (max-width: 767px) {
  .fingerprint-img .global-left {
    position: unset;
    width: auto;
    height: auto;
    padding: 15px;
  }

  .network .global-img .global-text {
    position: absolute !important;
    padding: 18px 18px !important;
  }

  .digital-page .pt-100 {
    padding-top: 00px;
  }

  .digital .digital-img-bg {
    margin-top: 50px;
  }

  .digital .digital-img-bg .digital-text h3 {
    font-size: 25px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .digital .digital-img-bg .digital-text p {
    font-size: 16px;
    line-height: 22px;
  }

  .digital .digital-img-bg .carousel-caption {
    top: 0%;
    transform: unset;
    position: unset;
  }

  .network .global-img .global-text {
    right: 0%;
    top: 12%;
  }

  .global .global-img .global-text {
    position: unset;
    padding: 30px 20px;
  }

  .pt-300 {
    padding-top: 0;
  }

  .global .global-img .global-text h3 {
    font-size: 20px;
  }

  .global .global-img .global-text p {
    font-size: 16px;
  }

  .global .technology-heading h3 {
    font-size: 24px;
  }

  .global .technology-heading p {
    font-size: 15px;
  }

  .global .technology-heading {
    margin-bottom: 50px;
  }

  .global .technology-box {
    padding: 80px 50px;
  }

  .global .technology-box h3 {
    font-size: 22px;
    margin-top: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .network .global-img .global-text {
    position: absolute !important;
  }

  .network .global-img .global-text h3 {
    font-size: 36px !important;
  }

  .network .technology-heading {
    margin-top: 0;
  }

  .fingerprint-img .global-left {
    position: unset;
    height: auto;
    padding: 15px;
    margin: auto;
  }

  .digital .digital-img-bg {
    margin-top: 100px;
  }

  .digital .digital-img-bg .carousel-caption {
    width: 96%;
    left: 0;
  }

  .digital .digital-img-bg .digital-text h3 {
    font-size: 35px;
    line-height: 42px;
    margin-bottom: 20px;
  }

  .digital .digital-img-bg .digital-text p {
    font-size: 15px;
    line-height: 22px;
  }

  .digital .digital-img-bg .carousel-caption {
    top: 38%;
  }

  .global .global-img .global-text {
    position: unset;
    padding: 30px 20px;
  }

  .global .global-img .global-text h3 {
    font-size: 38px;
  }

  .global .global-img .global-text p {
    font-size: 22px;
  }

  .pt-300 {
    padding-top: 0;
  }

  .global .technology-heading h3 {
    font-size: 38px;
  }

  .global .technology-heading p {
    font-size: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .fingerprint-img .global-right {
    left: 64% !important;
  }

  .fingerprint-img .global-left {
    width: 350px;
    height: 514px;
    padding: 20px;
    bottom: -31%;
    left: 6%;
  }

  .digital .digital-img-bg .carousel-caption {
    width: 96%;
    left: 0;
  }

  .digital .digital-img-bg .digital-text h3 {
    font-size: 45px;
    line-height: 42px;
    margin-bottom: 20px;
  }

  .digital .digital-img-bg .digital-text p {
    font-size: 15px;
    line-height: 22px;
  }

  .digital .digital-img-bg .carousel-caption {
    top: 38%;
  }

  .global .global-img .global-text h3 {
    font-size: 40px;
  }

  .global .global-img .global-text {
    padding: 50px 50px;
  }

  .pt-300 {
    padding-top: 200px;
  }

  .global .technology-heading h3 {
    font-size: 45px;
  }

  .global .technology-heading p {
    font-size: 22px;
  }

  .global .technology-box h3 {
    font-size: 23px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .fingerprint-img .global-right {
    left: 63% !important;
  }

  .digital .digital-img-bg .carousel-caption {
    top: 40%;
  }

  .digital .digital-img-bg .digital-text h3 {
    font-size: 52px;
  }

  .fingerprint-img .global-left {
    width: 430px;
    height: 444px;
    padding: 20px;
    bottom: -19%;
    left: 6%;
  }

  .digital .digital-img-bg .carousel-caption {
    width: 96%;
    left: 0;
  }

  .global .global-img .global-text h3 {
    font-size: 45px;
  }

  .global .global-img .global-text {
    padding: 75px 70px;
  }

  .global .global-img .global-text p {
    font-size: 26px;
  }

  .global .technology-heading h3 {
    font-size: 54px;
  }

  .global .technology-heading p {
    font-size: 26px;
  }

  .global .technology-box {
    padding: 80px 30px;
  }
}

@media (min-width: 1400px) and (max-width: 1799px) {
  .fingerprint-img .global-left {
    width: 505px;
    height: 484px;
    bottom: -13%;
  }

  .digital .digital-img-bg .carousel-caption {
    width: 80%;
    left: 8%;
    top: 45%;
  }
}

.pt-100 {
  padding-top: 100px;
}

.about {
  background-color: #011d44;
}

.about .about-text p {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 0;
}

.about .about-text h3 {
  font-family: "IBM Plex Serif";
  font-weight: 600;
  font-size: 60px;
  line-height: 78px;
  color: #ffffff;
  margin-bottom: 65px;
}

.about .about-img .client-name {
  position: absolute;
  bottom: 0;
  right: 28%;
}

.about .about-img .client-name h3 {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 0;
  color: #ffffff;
}

.about .about-img .client-name h6 {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 18px;
  color: #ff9f10;
}

.about .about-img {
  z-index: 99;
  position: relative;
}

.about .dcma-logo h5 {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 40px;
  color: #ff9f10;
}

.about .dcma-logo img {
  padding: 200px 0 100px;
}

.about .about-img::after {
  content: "";
  position: absolute;
  width: 65%;
  height: 125%;
  left: 104px;
  top: -44px;
  background: #093b82;
  z-index: -9;
}

.about .footer {
  background-color: #093b82;
}

@media (max-width: 320px) {
  .about .about-img::after {
    width: 78%;
    height: 120%;
    left: 54px;
  }

  .about .about-img .client-name h3 {
    font-size: 20px;
  }

  .about .about-img .client-name h6 {
    font-size: 16px;
  }

  .about .about-img img {
    width: 100%;
  }

  .about .about-img .client-name {
    right: 30px;
  }

  .about .about-text h3 {
    font-size: 30px;
    line-height: 48px;
    margin-bottom: 25px;
    margin-top: 40px;
  }

  .about .about-text p {
    font-size: 16px;
    line-height: 28px;
  }

  .about .dcma-logo img {
    padding: 100px 0 50px;
  }

  .about .dcma-logo h5 {
    font-size: 30px;
  }
}

@media (min-width: 321px) and (max-width: 575px) {
  .about .about-img::after {
    width: 84%;
    height: 113%;
    left: 54px;
  }

  .about .about-img .client-name h3 {
    font-size: 20px;
  }

  .about .about-img .client-name h6 {
    font-size: 16px;
  }

  .about .about-img img {
    width: 100%;
  }

  .about .about-img .client-name {
    right: 50px;
  }

  .about .about-text h3 {
    font-size: 30px;
    line-height: 48px;
    margin-bottom: 25px;
    margin-top: 40px;
  }

  .about .about-text p {
    font-size: 16px;
    line-height: 28px;
  }

  .about .dcma-logo img {
    padding: 100px 0 50px;
  }

  .about .dcma-logo h5 {
    font-size: 30px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .about .about-img::after {
    width: 75%;
    height: 105%;
  }

  .about .about-img .client-name {
    bottom: 5%;
    right: 11%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .about .about-img::after {
    width: 75%;
    height: 115%;
    left: 64px;
  }

  .about .about-img .client-name h3 {
    font-size: 22px;
  }

  .about .about-img .client-name h6 {
    font-size: 16px;
  }

  .about .about-img .client-name {
    right: 10%;
  }

  .about .about-text h3 {
    font-size: 39px;
    line-height: 51px;
    margin-bottom: 15px;
  }

  .about .about-text p {
    font-size: 14px;
    line-height: 23px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .about .about-img::after {
    width: 80%;
    height: 112%;
    left: 64px;
  }

  .about .about-img .client-name {
    right: 11%;
  }

  .about .about-text h3 {
    font-size: 52px;
    line-height: 68px;
    margin-bottom: 25px;
  }

  .about .about-text p {
    font-size: 16px;
    line-height: 26px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .about .about-img::after {
    width: 74%;
    height: 115%;
  }

  .about .about-img .client-name {
    right: 13%;
  }
}

@media (min-width: 1400px) {
  .about .container {
    max-width: 1460px;
  }
}

.download-page {
  background: #18172a;
}

.download-page .download-head {
  margin: 40px 0 60px;
}

.download-page .download-head h6 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 0;
  color: #ffffff;
}

.download-page .download-head h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 38px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-bottom: 24px;
}

.download-page .download .windows-box .windows img {
  margin-right: 26px;
}

.download-page .download .windows-box .windows p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0;
  color: #000000;
}

.download-page .download .windows-box .windows h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: #000000;
}

.download-page .download .windows-box .windows {
  display: flex;
  align-items: center;
  background: #ffffff;
  width: 300px;
  height: 160px;
  padding: 30px;
  margin-right: 132px;
}

.download-page .download {
  display: flex;
  align-items: center;
}

.web-wallet-downloads {
  background-image: url(../../public/images/download-bg-img.png);
  padding: 78px 0;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 9;
}

.web-wallet-downloads .unicoin-logo {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -9;
}

@media (max-width: 767px) {
  .web-wallet-downloads .unicoin-logo img {
    width: 100%;
  }

  .web-wallet-downloads {
    height: 828px;
  }

  .web-wallet-downloads .download-head {
    margin: 0px 0 40px !important;
  }

  .download-page .download {
    display: block;
  }

  .download-page .download .windows-box .windows {
    margin: auto;
    margin-bottom: 30px;
  }

  .download-page .download-head h3 {
    font-size: 34px;
    margin-bottom: 22px;
  }

  .download-page .download-head h6 {
    font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .web-wallet-downloads .unicoin-logo img {
    width: 100%;
  }

  .download-page .download .windows-box .windows {
    width: 220px;
    height: 130px;
    padding: 15px;
    margin-right: 24px;
  }

  .download-page .download .windows-box .windows img {
    margin-right: 4px;
  }

  .download-page .download .windows-box .windows h3 {
    font-size: 18px;
  }

  .web-wallet-downloads .unicoin-logo {
    width: 60%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .download-page .download .windows-box .windows {
    width: 292px;
    margin-right: 30px;
  }

  .web-wallet-downloads .unicoin-logo {
    width: 60%;
  }

  .web-wallet-downloads .unicoin-logo img {
    width: 100%;
  }

  .web-wallet-downloads {
    height: 798px;
  }

  .web-wallet-downloads .download-head {
    margin: 0px 0 40px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .download-page .download .windows-box .windows {
    margin-right: 108px;
  }

  .web-wallet-downloads .unicoin-logo img {
    width: 100%;
  }

  .web-wallet-downloads .download-head {
    margin: 0px 0 40px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .web-wallet-downloads .unicoin-logo img {
    width: 100%;
  }

  .web-wallet-downloads .download-head {
    margin: 0px 0 40px !important;
  }
}

.digital-currencies {
  background-color: #011d44;
}

.digital-currencies .currencies-img {
  position: relative;
}

.digital-currencies .currencies-img .currencies-text {
  position: absolute;
  top: 26%;
  left: 10%;
}

.digital-currencies .currencies-img .currencies-text h3 {
  font-family: "Libre Franklin";
  font-weight: 500;
  font-size: 50px;
  line-height: 77px;
  color: #ffffff;
}

.our-digital {
  background-color: #073371;
  padding: 100px 0;
}

.our-digital .our-digital-head {
  margin-bottom: 70px;
}

.our-digital .our-digital-head p {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 24px;
  color: #ff9f10;
  margin-bottom: 0;
}

.our-digital .our-digital-head h3 {
  font-family: "IBM Plex Serif";
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
}

.our-digital .our-digital-list {
  display: flex;
}

.our-digital .our-digital-list .our-digital-number h3 {
  font-family: "IBM Plex Serif";
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
  line-height: 52px;
}

.our-digital .our-digital-list .our-digital-number {
  width: 60px;
  height: 60px;
  border: 3px solid #011d44;
  border-radius: 500px;
}

.our-digital .our-digital-list .our-digital-text h3 {
  font-family: "IBM Plex Serif";
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.8);
}

.our-digital .our-digital-list .our-digital-text {
  margin-left: 26px;
}

.our-digital .our-digital-list .our-digital-text ul {
  margin-bottom: 0;
}

.our-digital .our-digital-list .our-digital-text ul li {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ff9f10;
}

.ms-100 {
  margin-left: 100px;
}

.our-digital .currency-innovations {
  padding: 150px 0 0px;
}

.our-digital .currency-innovations .currency-innovations-img {
  position: relative;
}

.our-digital
  .currency-innovations
  .currency-innovations-img
  .currency-innovations-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 36%;
  padding-right: 50px;
}

.our-digital
  .currency-innovations
  .currency-innovations-img
  .currency-innovations-text
  p {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.our-digital
  .currency-innovations
  .currency-innovations-img
  .currency-innovations-text
  h3 {
  font-family: "IBM Plex Serif";
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
}

.our-digital .currency-innovations .coin-box h6 {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 20px;
  color: #ff9f10;
}

.our-digital .currency-innovations .coin-box h3 {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  margin: 16px 0 10px;
}

.our-digital .currency-innovations .coin-box img {
  width: 60%;
}

.our-digital .currency-innovations .coin-box {
  background: radial-gradient(
    79.04% 79.04% at 62.28% 20.96%,
    #1a4f9c 0%,
    #030f21 100%
  );
  text-align: center;
  padding: 40px 40px;
}

.composite-digital .composite-digital-img .composite-digital-main {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.composite-digital
  .composite-digital-img
  .composite-digital-main
  .composite-digital-text
  p {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  opacity: 0.8;
  width: 75%;
}

.composite-digital
  .composite-digital-img
  .composite-digital-main
  .composite-digital-text
  h3 {
  font-family: "IBM Plex Serif";
  font-weight: 600;
  font-size: 40px;
  line-height: 58px;
  color: #ffffff;
  margin-bottom: 20px;
}

.composite-digital .composite-digital-img {
  position: relative;
}

.the-benefit-section .the-benefit-text .the-benefit .list-icon i {
  position: absolute;
  right: -16%;
  top: -23%;
  font-size: 20px;
  color: #fff;
}

.the-benefit-section .the-benefit-text .the-benefit p {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
  color: #ffffff;
  margin-bottom: 22px;
}

.the-benefit-section .the-benefit-text .the-benefit .list-icon {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #011d44;
  position: relative;
  margin-right: 24px;
  margin-bottom: 24px;
}

.the-benefit-section .the-benefit-text .the-benefit {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #011d44;
  margin-bottom: 24px;
  width: 100%;
}

.the-benefit-section .the-benefit-text h3 {
  font-family: "IBM Plex Serif";
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 40px;
}

.the-benefit-section .the-benefit-img {
  position: absolute;
  left: 12%;
  width: 30%;
  top: 50%;
  transform: translateY(-50%);
}

.the-benefit-section {
  position: relative;
  margin: 150px 0;
}

.the-benefit-section .the-benefit-text {
  background: #093b82;
  padding: 100px 140px 100px 215px;
}

.prevention-protocol .prevention-protocol-head h3 {
  font-family: "IBM Plex Serif";
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 40px;
  text-align: center;
  color: #ffffff;
}

.prevention-protocol .container-fluid {
  width: 90%;
}

.prevention-protocol .prevention-protocol-box .prevention-protocol-head-box h3 {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  white-space: nowrap;
  margin-top: 8px;
  margin-bottom: 0;
}

.prevention-protocol
  .prevention-protocol-box
  .prevention-protocol-head-box:last-child {
  box-shadow: inset 5px 0px 6px rgba(0, 0, 0, 0.25);
  position: relative;
  padding-left: 40px;
}

.prevention-protocol
  .prevention-protocol-box
  .prevention-protocol-head-box:last-child
  img {
  margin-left: 25%;
}

.prevention-protocol .prevention-protocol-box .prevention-protocol-head-box {
  background: #011d44;
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding: 40px 0;
  text-align: center;
}

.prevention-protocol .prevention-protocol-box .prevention-protocol-arrow {
  position: absolute;
  right: 0;
  width: 0;
  top: 0%;
  height: 0;
  border-top: 160px solid transparent;
  border-bottom: 160px solid transparent;
  border-left: 140px solid #011d44;
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 1);
}

.prevention-protocol .box-arrow {
  width: 72%;
}

.color-box-2 {
  background: #073371 !important;
}

.color-box-3 {
  background: #093b82 !important;
}

.color-box-4 {
  background: #0a4393 !important;
}

.color-box-5 {
  background: #104ea5 !important;
}

.color-box-6 {
  background: #1d51b7 !important;
}

.prevention-protocol
  .prevention-protocol-box
  .prevention-protocol-text
  p:last-child {
  margin-bottom: 0;
}

.prevention-protocol .prevention-protocol-box .prevention-protocol-text p {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 20px;
  color: #ffffff;
}

.prevention-protocol .prevention-protocol-box .prevention-protocol-text {
  padding: 80px 16px 0;
}

.prevention-protocol .prevention-protocol-box {
  padding: 78px 0;
  background: #052859;
  position: relative;
  height: 100%;
}

.prevention-protocol {
  padding: 200px 0;
}

.cash-backed .cash-backed-img .cash-backed-head p {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 100px;
  opacity: 0.8;
}

.cash-backed .cash-backed-img .cash-backed-head h3 {
  font-family: "IBM Plex Serif";
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 20px;
  color: #ffffff;
}

.cash-backed .cash-backed-img .cash-backed-head img {
  width: 70%;
}

.cash-backed .cash-backed-img .cash-backed-head {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 96%;
}

.cash-backed .cash-backed-img {
  position: relative;
}

.cross-cbdc .cross-cbdc-head img {
  margin-left: 56px;
}

.cross-cbdc .cross-cbdc-head h3 {
  font-family: "IBM Plex Serif";
  font-weight: 600;
  font-size: 46px;
  line-height: 58px;
  color: #ffffff;
  margin-bottom: 50px;
}

.cross-cbdc .cross-cbdc-head {
  text-align: center;
}

.cross-cbdc .cross-cbdc-content .cross-cbdc-text p {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 0;
  opacity: 0.8;
}

.cross-cbdc
  .cross-cbdc-content
  .cross-cbdc-table
  .table-bordered
  > :not(caption)
  > *
  > * {
  border-width: 0 4px;
}

.cross-cbdc
  .cross-cbdc-content
  .cross-cbdc-table
  .table
  > :not(caption)
  > *
  > * {
  border-bottom-width: 4px !important;
}

.cross-cbdc .cross-cbdc-content .cross-cbdc-table .table-bordered {
  border: 4px solid #011d44;
}

.cross-cbdc .cross-cbdc-content .cross-cbdc-table .table tbody tr td {
  padding: 20px 0;
  width: 192px;
}

.cross-cbdc .cross-cbdc-content .cross-cbdc-table .table tbody tr td h6 {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
  margin-bottom: 0;
}

.cross-cbdc .cross-cbdc-content .cross-cbdc-table .table tbody tr td h3 {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 22px;
  color: #ffffff;
}

.cross-cbdc .cross-cbdc-content .cross-cbdc-table .table thead tr th {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
}

.cross-cbdc .cross-cbdc-content {
  background: #093b82;
  padding: 80px 0 110px;
  margin-top: -90px;
}

.cross-cbdc {
  padding-top: 100px;
}

.digital-currencies .footer {
  background: #093b82;
}

.the-benefit-section .the-benefit-text {
  background: #093b82;
  padding: 80px 40px 70px 220px;
}

@media (min-width: 1400px) {
  .our-digital .currency-innovations .container {
    max-width: 1460px;
  }
}

@media (max-width: 575px) {
  .prevention-protocol .box-arrow {
    width: 77% !important;
  }
}

@media (max-width: 991px) {
  .digital-currencies .currencies-img .currencies-text h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .our-digital .our-digital-head h3 {
    font-size: 30px;
  }

  .our-digital .our-digital-head p {
    font-size: 16px;
  }

  .our-digital .our-digital-head {
    margin-bottom: 30px;
  }

  .our-digital {
    padding: 50px 0;
  }

  .our-digital .our-digital-list {
    display: flex;
  }

  .our-digital .our-digital-list .our-digital-number {
    width: 50px;
    height: 50px;
  }

  .cash-backed .cash-backed-img .cash-backed-head img {
    width: 100%;
  }

  .our-digital .our-digital-list .our-digital-number h3 {
    font-size: 30px;
    line-height: 44px;
  }

  .our-digital .our-digital-list .our-digital-text h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .our-digital .our-digital-list .our-digital-text {
    margin-left: 16px;
  }

  .cross-cbdc .cross-cbdc-head img {
    width: 100%;
    margin-left: 0px;
  }

  .our-digital .our-digital-list .our-digital-text ul {
    padding-left: 14px;
  }

  .our-digital .our-digital-list .our-digital-text ul li {
    font-size: 16px;
    line-height: 24px;
  }

  .ms-100 {
    margin-left: 0;
  }

  .our-digital
    .currency-innovations
    .currency-innovations-img
    .currency-innovations-text {
    position: unset;
    top: unset;
    transform: unset;
    left: unset;
  }

  .our-digital .currency-innovations {
    padding: 50px 0 50px;
  }

  .our-digital
    .currency-innovations
    .currency-innovations-img
    .currency-innovations-text
    h3 {
    font-size: 30px;
  }

  .our-digital .currency-innovations .coin-box img {
    width: 50%;
  }

  .our-digital .currency-innovations .coin-box {
    padding: 25px 50px;
  }

  .our-digital .currency-innovations .coin-box h6 {
    font-size: 20px;
  }

  .composite-digital
    .composite-digital-img
    .composite-digital-main
    .composite-digital-text
    h3 {
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 10px;
  }

  .composite-digital
    .composite-digital-img
    .composite-digital-main
    .composite-digital-text
    p {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
  }

  .the-benefit-section .the-benefit-text {
    background: #093b82;
    padding: 30px 15px;
  }

  .the-benefit-section .the-benefit-text h3 {
    font-size: 20px;
    line-height: 38px;
    margin-bottom: 20px;
  }

  .the-benefit-section .the-benefit-text .the-benefit p {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .the-benefit-section .the-benefit-text .the-benefit .list-icon {
    margin-right: 18px;
    margin-bottom: 18px;
  }

  .icons-top {
    width: 38px;
  }

  .the-benefit-section .the-benefit-img {
    position: unset;
    left: unset;
    width: unset;
    top: unset;
    transform: unset;
  }

  .the-benefit-section {
    margin: 50px 0 50px;
  }

  .composite-digital .composite-digital-img .composite-digital-main {
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
    width: unset;
  }

  .prevention-protocol {
    padding: 50px 0;
  }

  .prevention-protocol .prevention-protocol-head h3 {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .prevention-protocol .prevention-protocol-box {
    padding: 50px 0;
  }

  .prevention-protocol .prevention-protocol-box .prevention-protocol-head-box {
    padding: 30px 0;
  }

  .prevention-protocol .prevention-protocol-box .prevention-protocol-text {
    padding: 50px 14px 0;
  }

  .prevention-protocol .prevention-protocol-box .prevention-protocol-text p {
    margin-bottom: 18px;
  }

  .prevention-protocol .prevention-protocol-box .prevention-protocol-arrow {
    border-top: 124px solid transparent;
    border-bottom: 120px solid transparent;
    border-left: 120px solid #011d44;
  }

  .cash-backed .cash-backed-img .cash-backed-head {
    top: 80%;
    width: 100%;
  }

  .cash-backed .cash-backed-img .cash-backed-head h3 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .cash-backed .cash-backed-img .cash-backed-head p {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .cross-cbdc .cross-cbdc-head h3 {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 20px;
  }

  .cross-cbdc .cross-cbdc-content {
    padding: 60px 0 60px;
    margin-top: 0;
    margin-bottom: 150px;
  }

  .cross-cbdc .cross-cbdc-content .cross-cbdc-text p {
    font-size: 20px;
    line-height: 30px;
    text-align: start;
  }

  .cross-cbdc .cross-cbdc-content .cross-cbdc-table .table tbody tr td h3 {
    font-size: 16px;
    white-space: nowrap;
  }

  .cross-cbdc .cross-cbdc-content .cross-cbdc-table .table tbody tr td h6 {
    font-size: 12px;
  }

  .cross-cbdc .cross-cbdc-content .cross-cbdc-table .table tbody tr td {
    padding: 28px 20px;
    width: unset;
  }

  .our-digital-number-width {
    width: 50px;
  }

  .prevention-protocol
    .prevention-protocol-box
    .prevention-protocol-head-box
    h3 {
    font-size: 16px;
  }

  .prevention-protocol
    .prevention-protocol-box
    .prevention-protocol-head-box:last-child
    img {
    margin-left: 4%;
  }

  .prevention-protocol .box-arrow {
    width: 64%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .composite-digital .composite-digital-img .composite-digital-main {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .prevention-protocol .box-arrow {
    width: 72%;
  }

  .cash-backed .cash-backed-img .cash-backed-head {
    top: 50%;
    width: 100%;
  }

  .cross-cbdc {
    padding-top: 150px;
  }

  .cross-cbdc .cross-cbdc-content .cross-cbdc-table .table thead tr th {
    font-size: 22px;
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  .cash-backed .cash-backed-img .cash-backed-head img {
    width: 100%;
  }

  .digital-currencies .currencies-img .currencies-text h3 {
    font-size: 48px;
    line-height: 60px;
  }

  .our-digital .our-digital-head h3 {
    font-size: 55px;
  }

  .our-digital .our-digital-head p {
    font-size: 26px;
  }

  .our-digital .our-digital-head {
    margin-bottom: 50px;
  }

  .our-digital .our-digital-list .our-digital-number {
    width: 50px;
    height: 50px;
  }

  .our-digital .our-digital-list .our-digital-number h3 {
    font-size: 34px;
    line-height: 44px;
  }

  .our-digital .our-digital-list .our-digital-text h3 {
    font-size: 22px;
    margin-bottom: 12px;
  }

  .our-digital
    .currency-innovations
    .currency-innovations-img
    .currency-innovations-text
    h3 {
    font-size: 38px;
  }

  .our-digital
    .currency-innovations
    .currency-innovations-img
    .currency-innovations-text
    p {
    font-size: 14px;
    line-height: 18px;
  }

  .our-digital .currency-innovations .coin-box img {
    width: 60%;
  }

  .our-digital .currency-innovations .coin-box {
    padding: 30px 22px;
  }

  .our-digital .currency-innovations .coin-box h3 {
    font-size: 21px;
    line-height: 28px;
  }

  .our-digital .currency-innovations .coin-box h6 {
    font-size: 20px;
  }

  .composite-digital
    .composite-digital-img
    .composite-digital-main
    .composite-digital-text
    h3 {
    font-size: 35px;
    line-height: 60px;
    margin-bottom: 12px;
  }

  .composite-digital
    .composite-digital-img
    .composite-digital-main
    .composite-digital-text
    p {
    font-size: 22px;
    line-height: 35px;
    width: 79%;
  }

  .the-benefit-section .the-benefit-img {
    position: unset;
    left: unset;
    width: unset;
    top: unset;
    transform: unset;
  }

  .the-benefit-section .the-benefit-text {
    padding: 50px;
  }

  .cash-backed .cash-backed-img .cash-backed-head p {
    margin-bottom: 8px;
  }

  .cash-backed .cash-backed-img .cash-backed-head {
    width: 100%;
  }

  .cross-cbdc .cross-cbdc-head img {
    margin-left: 28px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .the-benefit-section .the-benefit-img {
    position: absolute;
    left: unset;
    width: 30%;
    top: 50%;
    transform: translateY(-50%);
  }

  .the-benefit-section .the-benefit-text {
    padding: 100px 70px 100px 175px;
  }

  .web-wallet-downloads {
    padding: 70px 0;
    height: 838px;
  }

  .web-wallet-downloads .unicoin-logo {
    width: 60%;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
  .cash-backed .cash-backed-img .cash-backed-head {
    width: 90%;
  }

  .cash-backed .cash-backed-img .cash-backed-head img {
    width: 100%;
  }

  .prevention-protocol .container-fluid {
    width: 100%;
  }

  .prevention-protocol .prevention-protocol-box .prevention-protocol-arrow {
    border-left: 100px solid #011d44;
  }

  .prevention-protocol
    .prevention-protocol-box
    .prevention-protocol-head-box:last-child {
    padding-left: 30px;
  }

  .prevention-protocol .prevention-protocol-box .prevention-protocol-text {
    padding: 80px 14px 0;
  }

  .the-benefit-section .the-benefit-img {
    left: 6%;
  }

  .web-wallet-downloads {
    padding: 44px 0;
  }

  .web-wallet-downloads {
    height: 872px;
  }

  .web-wallet-downloads .unicoin-logo {
    width: 50%;
  }

  .web-wallet-downloads .unicoin-logo img {
    width: 100%;
  }
}

@media (min-width: 1700px) and (max-width: 1999px) {
  .web-wallet-downloads {
    height: 906px;
  }
}

.sidebar-documention .accordion-header::after {
  background: #aa8247;
}

.sidebar-documention {
  overflow-y: unset;
}

.download-page .footer {
  margin-top: 0;
}

.footer img {
  width: 35%;
}

.download-page-height {
  height: 100%;
}

.news .footer {
  margin-top: 0;
}

.documentation .footer {
  margin-top: 0;
}

.fxrates-price-page .fxrates-price {
  margin-left: 50px;
}

.fxrates-price-page .fxrates-price-logo {
  text-align: center;
  margin-top: 68px;
}

.fxrates-price-page .fxrates-price-logo h2 span {
  position: absolute;
  vertical-align: top;
  font-family: "Libre Franklin";
  font-weight: 600;
  font-size: 20px;
  color: #b6904a;
}

.fxrates-price-page .fxrates-price-logo h6 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: #000000;
}

.fxrates-price-page .best-price-fx-rates p span {
  color: #aa8247;
}

.fxrates-price-page .best-price-fx-rates p {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #000000;
}

.fxrates-price-page .best-price-fx-rates h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 38px;
  letter-spacing: 0.05em;
  color: #000000;
}

.fxrates-price-page .fxrates-price-logo h2 {
  font-family: "Libre Franklin";
  font-weight: 600;
  font-size: 60px;
  color: #b6904a;
}

.fxrates-price-page .best-price-fx-rates .form-select {
  width: 150px;
  background-color: #f4f6f7;
  border-radius: 4px;
  border: 0;
}

.fxrates-price-box .icon-price .icon-price-box .price h3 {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 0;
}

.fxrates-price-box .icon-price .icon-price-box .price h4 {
  font-family: "Source Sans Pro";
  font-weight: 600;
  margin-bottom: 1px;
  font-size: 14px;
  color: #ffffff;
}

.fxrates-price-box .icon-price .icon-price-box .price {
  margin-top: 14px;
  width: 120px;
  padding: 6px 0;
  background: #aa8247;
}

.fxrates-price-box .icon-price .icon-price-box h5 {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 12px;
  padding: 6px 15px;
  color: #4d8e58;
  background: #b4f5be;
  border-radius: 20px;
  margin: 6px 11px 14px;
}

.fxrates-price-box .icon-price .icon-price-box {
  border: 1px solid rgba(170, 130, 71, 0.8);
  border-radius: 4px;
  margin-right: 24px;
  text-align: center;
}

.fxrates-price-box .icon-price {
  display: flex;
  justify-content: center;
}

.fxrates-price-box h6 {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  margin: 10px 0 26px;
}

.fxrates-price-box {
  background: #ffffff;
  border: 1px solid rgba(170, 130, 71, 0.8);
  border-radius: 4px;
  padding: 24px 16px 16px;
  text-align: center;
}

.unicoin-bg h5 {
  color: #a52e2e !important;
  background: #ffadad !important;
}

@media (max-width: 767px) {
  .fxrates-price-page .fxrates-price-logo h2 {
    font-size: 30px;
  }

  .fxrates-price-page .fxrates-price-logo h2 span {
    font-size: 16px;
  }

  .fxrates-price-page .fxrates-price {
    margin-left: 0px;
  }

  .fxrates-price-page .fxrates-price-logo img {
    width: 100%;
  }

  .fxrates-price-page .best-price-fx-rates h3 {
    font-size: 26px;
  }

  .fxrates-price-page .fxrates-price-logo h6 {
    font-size: 18px;
  }

  .fxrates-price-page .best-price-fx-rates p {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .fxrates-price-box .icon-price .icon-price-box {
    margin-right: 12px;
  }

  .fxrates-price-page .fxrates-price {
    margin-left: 0px;
  }
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: unset !important;
}

.suspend-text p {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #000000;
  margin: 0px 0 30px;
}

.monetary-policy .monetary-policy-slider {
  position: relative;
}

.monetary-policy .monetary-policy-slider .monetary-policy-slider-text {
  position: absolute;
  top: 25%;
  left: 6%;
}

.monetary-policy .monetary-policy-slider .monetary-policy-slider-text h1 {
  font-family: "Libre Franklin";
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.05em;
  color: #ffffff;
  width: 90%;
  margin-bottom: 0;
}

.monetary-policy .monetary-policy-slider .monetary-policy-slider-text p {
  font-family: "Quicksand";
  font-weight: 500;
  font-size: 22px;
  color: #dbd5f2;
  padding: 18px 0;
  margin-bottom: 0;
}

.monetary-policy
  .monetary-policy-question
  .monetary-policy-question-two
  ul
  li::marker {
  font-size: 30px;
}

.monetary-policy .monetary-policy-question ul li {
  font-family: "Courier Prime";
  margin-bottom: 16px;
  margin-left: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.monetary-policy .monetary-policy-question ul li ul li {
  font-family: "Courier Prime";
  margin-bottom: 16px;
  margin-left: -15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  list-style: disc;
}

.monetary-policy .monetary-policy-question ul li::marker {
  font-size: 30px;
}

.monetary-policy .monetary-policy-question ul li ul li::marker {
  font-size: 20px;
}

.monetary-policy .monetary-policy-question .monetary-policy-question-two ul li {
  font-family: "Courier Prime";
  font-weight: 400;
  font-size: 18px;
  margin-left: 133px;
  line-height: 20px;
  margin-bottom: 16px;
  color: #000 !important;
}

.monetary-policy .monetary-policy-question .monetary-policy-question-two p {
  margin-left: 165px;
}

.monetary-policy
  .monetary-policy-question
  .monetary-policy-question-two
  h2
  span {
  font-size: 30px;
}

.monetary-policy .monetary-policy-question .monetary-policy-question-two h2 {
  margin-left: 78px;
  font-size: 28px;
}

.monetary-policy .monetary-policy-question p a {
  text-decoration-line: underline;
  color: #56ccf2;
}

.monetary-policy .monetary-policy-question p {
  font-family: "Courier Prime";
  margin-left: 83px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.monetary-policy .monetary-policy-question h2 span {
  font-family: "Courier Prime";
  font-weight: 700;
  vertical-align: middle;
  font-size: 40px;
  margin-right: 32px;
  color: #000000;
}

.monetary-policy .monetary-policy-question h2 {
  font-family: "Courier Prime";
  font-weight: 700;
  font-size: 40px;
  color: #000000;
}

.monetary-policy .footer {
  background: #093b82;
  margin-top: 100px;
}

@media (max-width: 767px) {
  .monetary-policy .monetary-policy-question ul li {
    font-size: 14px;
    line-height: 18px;
    margin-left: 0;
  }

  .fxrates-price-logo img {
    width: 100%;
  }

  .monetary-policy .monetary-policy-question ul li ul li {
    margin-left: -20px;
    font-size: 14px;
    line-height: 18px;
  }

  .crypto2-page .monetary-policy-slider .monetary-policy-slider-text h1 {
    font-size: 20px !important;
    line-height: 8px !important;
  }

  .monetary-policy .monetary-policy-slider .monetary-policy-slider-text {
    top: 5%;
  }

  .monetary-policy .monetary-policy-slider .monetary-policy-slider-text p {
    font-size: 15px;
    padding: 10px 0;
  }

  .monetary-policy .monetary-policy-slider .monetary-policy-slider-text h1 {
    font-size: 22px;
    line-height: 28px;
    width: 100%;
  }

  .monetary-policy .monetary-policy-question h2 span {
    font-size: 30px;
    margin-right: 14px;
  }

  .monetary-policy .monetary-policy-question h2 {
    font-size: 16px;
  }

  .monetary-policy .monetary-policy-question p {
    margin-left: 0;
    font-size: 14px;
    line-height: 18px;
  }

  .monetary-policy
    .monetary-policy-question
    .monetary-policy-question-two
    h2
    span {
    font-size: 30px;
  }

  .monetary-policy .monetary-policy-question .monetary-policy-question-two h2 {
    margin-left: 0;
    font-size: 16px;
  }

  .monetary-policy .monetary-policy-question .monetary-policy-question-two p {
    margin-left: 0;
  }

  .monetary-policy
    .monetary-policy-question
    .monetary-policy-question-two
    ul
    li {
    font-size: 14px;
    line-height: 18px;
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .crypto2-page .monetary-policy-slider .monetary-policy-slider-text h1 {
    font-size: 40px !important;
    line-height: 20px !important;
  }

  .monetary-policy .monetary-policy-slider .monetary-policy-slider-text h1 {
    font-size: 44px;
    line-height: 60px;
  }

  .monetary-policy .monetary-policy-slider .monetary-policy-slider-text p {
    font-size: 18px;
    padding: 10px 0;
  }

  .monetary-policy .monetary-policy-slider .monetary-policy-slider-text {
    top: 15%;
  }

  .monetary-policy .monetary-policy-question h2 span {
    font-size: 50px;
    margin-right: 22px;
  }

  .monetary-policy .monetary-policy-question h2 {
    font-size: 30px;
  }

  .monetary-policy .monetary-policy-question p {
    margin-left: 78px;
    font-size: 16px;
    line-height: 18px;
  }

  .monetary-policy .monetary-policy-question .monetary-policy-question-two h2 {
    margin-left: 58px;
    font-size: 22px;
  }

  .monetary-policy
    .monetary-policy-question
    .monetary-policy-question-two
    h2
    span {
    font-size: 38px;
  }

  .monetary-policy .monetary-policy-question .monetary-policy-question-two p {
    margin-left: 144px;
  }

  .monetary-policy
    .monetary-policy-question
    .monetary-policy-question-two
    ul
    li {
    font-weight: 400;
    font-size: 16px;
    margin-left: 112px;
    line-height: 18px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .monetary-policy .monetary-policy-slider .monetary-policy-slider-text h1 {
    font-size: 52px;
    line-height: 68px;
  }

  .monetary-policy .monetary-policy-slider .monetary-policy-slider-text {
    top: 16%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .monetary-policy .monetary-policy-slider .monetary-policy-slider-text {
    top: 13%;
  }
}

.community {
  background: #011d44;
}

.community .community-question .community-question-text h4 {
  font-family: "Courier Prime";
  font-weight: 700;
  font-size: 20px;
  margin-left: 165px;
  color: #ffffff;
}

.community .community-question p,
.community .community-question h2 span,
.community .community-question h2 {
  color: #fff;
}

.community .about .about-text p {
  font-family: "Courier Prime";
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 16px !important;
}

.community .about .about-text h3 {
  font-family: "Courier Prime";
  font-weight: 700;
  font-size: 40px;
  color: #ffffff;
  margin-bottom: 40px;
  line-height: 0;
}

.community .about .about-img .client-name h3 {
  font-family: "Courier Prime";
  font-weight: 400;
  font-size: 28px;
  color: #ffffff;
}

.community .about .about-img .client-name {
  bottom: 5%;
  right: 16%;
}

.community .about .about-img .client-name h6 {
  font-family: "Courier Prime";
  font-weight: 400;
  font-size: 18px;
  color: #ff9f10;
}

.community .about-img::after {
  content: unset;
}

@media (max-width: 767px) {
  .community .community-question .community-question-text h4 {
    font-size: 18px;
    margin-left: 0;
  }

  .community .about .about-img .client-name img {
    width: unset;
  }

  .community .about .about-img .client-name h6 {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .community .about .about-img .client-name h3 {
    font-size: 18px;
  }

  .community .about .about-img .client-name {
    bottom: 3%;
    right: 2%;
  }

  .community .about .about-text h3 {
    font-size: 26px;
    margin-bottom: 30px;
  }

  /* element.style {
    } */

  .community .about .about-text p {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .community .community-question .community-question-text h4 {
    margin-left: 144px;
  }

  .community .about .about-img .client-name {
    right: 20%;
  }

  .community .about .about-text h3 {
    margin-bottom: 0px;
    line-height: 110px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .community .about .about-img .client-name {
    bottom: 4%;
    right: 5%;
  }

  .community .about .about-text h3 {
    font-size: 43px;
    margin-bottom: 40px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .community .about .about-img .client-name {
    right: 5%;
  }

  .community .about .about-text h3 {
    font-size: 50px;
    margin-bottom: 50px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .community .about .about-img .client-name {
    bottom: 6%;
    right: 18%;
  }
}

.bg-darks {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: #00000087 !important;
}

.error-box {
  width: 497px !important;
  height: 246px !important;
  background: #ffffff;
  border-radius: 4px;
  padding: 0 !important;
  position: absolute;
  z-index: 999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-box p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 35px;
}

.error-box .error-box-head h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #dd3232;
  margin-bottom: 0;
}

.error-box .error-box-head i {
  color: #dd3232;
  margin-right: 20px;
  font-size: 30px;
}

.error-box .error-box-head {
  background: #f7c4c4;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  padding: 5px 24px;
  align-items: center;
}

.error-box .wallet-information-box .cancel-btn {
  color: #dd3232 !important;
  border: 1px solid #dd3232;
  transition: all 0.5s;
}

.error-box .wallet-information-box .next-btn {
  background-color: #dd3232 !important;
  transition: all 0.5s;
}

@media (max-width: 567px) {
  .error-box {
    width: 300px !important;
    height: auto !important;
  }
}

.originator-mr-u {
  margin-right: 85px;
}

.mony-last:after {
  content: unset !important;
}

.deposit-funds-tabs p {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 30px;
}

.deposit-funds-tabs h6 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}

.money-transaction-icon h3 i {
  border-radius: 100%;
  font-size: 12px;
  color: #fff;
  background: #aa8247;
  padding: 4.5px 6px;
  margin-right: 16px;
}

.money-transaction-icon h3 {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
}

.money-transaction-text p span {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #4d8e58;
  background: #b4f5be;
  border-radius: 20px;
  padding: 2px 22px;
  margin-left: 12px;
}

.money-transaction-text p h2 {
  display: inline-block;
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: #aa8247;
  margin-left: 12px;
  margin-bottom: 0;
}

.money-transaction-text p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  margin-bottom: 0;
}

.money-transaction-text h6 span {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #e4db00;
  display: inline-block;
  margin-right: 8px;
}

.money-transaction-text textarea::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #000000;
}

.money-transaction-text textarea {
  background: #e6e5e5;
  border-radius: 4px;
  border: 0;
  width: 300px;
  padding: 9px 12px;
}

.money-transaction-text input::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #000000;
  text-align: end;
}

.money-transaction-text input {
  background: #e6e5e5;
  border-radius: 4px;
  border: 0;
  padding: 4px 12px;
}

.money-transaction-text h6 {
  font-family: "Source Sans Pro" !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #e4db00 !important;
  margin-bottom: 0 !important;
}

.money-heading h3 p {
  display: inline-block;
  margin-left: 267px;
  margin-bottom: 0;
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: #aa8247;
}

.money-heading h3 {
  color: #000000 !important;
}

@media (max-width: 767px) {
  .money-heading h3 p {
    display: unset;
    margin-left: 0;
    font-size: 12px;
  }

  .money-transaction-text textarea {
    width: 260px;
  }

  .modal-logo {
    width: 35%;
  }

  .originator-mr-u {
    margin-right: 26px;
  }

  .nav-tabs .review-mr {
    margin-right: 0;
  }

  .nav-tabs .money-mr {
    margin-right: 37px;
  }

  .deposit-funds-tabs h6 {
    font-size: 20px;
  }

  .deposit-funds-tabs p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .money-heading h3 p {
    margin-left: 48px;
    font-size: 16px;
  }

  .originator-mr-u {
    margin-right: 75px;
  }

  .nav-tabs .money-mr {
    margin-right: 92px;
  }

  .money-transaction-icon h3 {
    font-size: 16px;
  }

  .money-transaction-icon h3 i {
    margin-right: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .money-heading h3 p {
    margin-left: 68px;
  }

  .originator-mr-u {
    margin-right: 75px;
  }

  .nav-tabs .money-mr {
    margin-right: 93px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .money-heading h3 p {
    margin-left: 130px;
  }

  .originator-mr-u {
    margin-right: 139px;
  }

  .nav-tabs .money-mr {
    margin-right: 159px;
  }

  .deposit-funds-tabs {
    margin-left: 50px;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
  .money-heading h3 p {
    margin-left: 189px;
  }
}

.table-responsive hr {
  width: 100%;
  opacity: 1;
  height: 2px;
  background: #aa8247;
}

.wallet-balances-box .table thead td .input-group {
  border: 2px solid #cecccc;
  border-radius: 4px;
  box-shadow: none;
}

.welcome-to-the-unicoin-network .sidebar {
  pointer-events: none;
}
/* Modals
----------------------------------------------*/
.modal-content {
  position: unset !important;
  flex-direction: unset !important;
  pointer-events: auto;
  background-color: unset !important;
  background-clip: unset !important;
  border: unset !important;
  border-radius: unset !important;
  outline: 0;
  width: 49% !important;
  margin: auto;
}

[data-ml-modal] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 999;
  width: 0;
  height: 0;
  opacity: 0;
}

[data-ml-modal]:target {
  width: auto;
  height: auto;
  opacity: 1;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
}

[data-ml-modal]:target .modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
}

[data-ml-modal] .modal-dialog {
  margin: 10% auto;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2;
}

.modal-dialog-lg {
  max-width: 820px !important;
}

[data-ml-modal] .modal-dialog > h3 {
  background-color: #eee;
  border-bottom: 1px solid #b3b3b3;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  padding: 0.8em 56px 0.8em 27px;
}

[data-ml-modal] .modal-content {
  height: 513px;
}

[data-ml-modal] .modal-close {
  position: absolute;
  top: 13px;
  right: 13px;
  color: #0085a6;
  background-color: #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

[data-ml-modal] .modal-close:hover {
  background-color: #0085a6;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 767px) {
  [data-ml-modal] .modal-dialog {
    margin: 20% auto;
  }
}

.Toastify__toast-container {
  z-index: 9999999 !important;
}

.subscription .form-check label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-left: 10px;
}

.subscription .form-check {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.subscription h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  color: #000000;
  margin-bottom: 16px;
}

.umbrella {
  background-color: #18172a;
}

.umbrella .digital-financial {
  text-align: center;
  position: relative;
  z-index: 99;
  padding: 150px 0;
}

.umbrella .digital-financial h4 {
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  color: #ffffff;
}

.umbrella .digital-financial h3 {
  font-family: "Heebo";
  font-weight: 500;
  font-size: 50px;
  color: #ffffff;
}

.umbrella .digital-financial h2 {
  font-family: "Heebo";
  font-weight: 500;
  font-size: 50px;
  color: rgba(255, 255, 255, 0.6);
}

.umbrella .digital-financial p {
  font-family: "Heebo";
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  width: 70%;
  color: rgba(255, 255, 255, 0.8);
  margin: auto;
}

.umbrella .digital-financial .next-btn-video i {
  color: rgba(58, 49, 221, 0.57);
  font-size: 30px;
  margin-right: 16px;
}

.umbrella .digital-financial .next-btn-video p {
  font-family: "Heebo";
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.05em;
  color: #fff;
  opacity: 0.8;
  line-height: 14px;
  text-align: start;
  margin-bottom: 0;
  width: 100%;
}

.umbrella .digital-financial .next-btn-video h3 {
  font-family: "Heebo";
  font-weight: 500;
  font-size: 16px;
  text-align: start;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(58, 49, 221, 0.8);
  margin-bottom: 2px;
}

.umbrella .digital-financial .btn {
  height: 56px;
}

.umbrella .digital-financial .next-btn-video {
  display: flex;
  align-items: center;
  border: 3px solid rgba(58, 49, 221, 0.8);
  border-radius: 4px;
}
.cancel-btn {
    position: relative;
    top: 0;
    transition: all 0.2s;
    background: #aa8247 !important;
}
.cancel-btn:hover {
    background-color: #0055cf !important;
    color: #fff !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.7);
    text-decoration: underline;
    top: 5px;
}
.umbrella .digital-financial .subscribr-btn {
  background: rgba(58, 49, 221, 0.57);
  border-radius: 4px;
  font-family: "Heebo";
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 65px;
  margin-right: 50px;
  transition: all 0.5s;
}

.unicoin-stakeholders {
  padding-bottom: 150px;
}

.unicoin-services {
  padding: 150px 0;
  position: relative;
  z-index: 99;
}

.unicoin-solutions {
  padding-top: 150px;
}

.unicoin-services h3,
.unicoin-solutions h3,
.unicoin-technology h3 {
  font-family: "Heebo";
  font-weight: 500;
  font-size: 40px;
  color: #ffffff;
  margin-bottom: 40px;
  margin-left: 119px;
}

.unicoin-tab img {
  width: 12%;
}

.unicoin-tab {
  background: #032a6b;
  border-radius: 20px;
  padding: 30px;
  height: 100%;
  display: block;
  text-decoration: none;
}

.unicoin-tab h4 {
  font-family: "Heebo";
  font-weight: 500;
  font-size: 24px;
  text-transform: capitalize;
  color: #ffffff;
  margin: 12px 0;
  line-height: 32px;
}

.unicoin-tab p {
  font-family: "Heebo";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.9);
  opacity: 0.8;
  margin-bottom: 0;
}

.round-bg {
  position: absolute;
  top: 150%;
  left: -40%;
  border-radius: 100%;
  width: 500px;
  height: 500px;
  background: #494587;
  filter: blur(200px);
  z-index: -9;
}

.unicoin-technology {
  position: relative;
  z-index: 99;
}

.round-bg-1 {
  position: absolute;
  top: -10%;
  right: 0;
  border-radius: 100%;
  width: 500px;
  height: 500px;
  background: #494587;
  filter: blur(200px);
  z-index: -9;
}

.round-bg-2 {
  position: absolute;
  top: 10%;
  left: 25%;
  border-radius: 100%;
  width: 500px;
  height: 500px;
  background: #494587;
  filter: blur(200px);
  z-index: -9;
}

.sub-heading p {
  font-family: "Oxygen";
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 30px;
}

.sub-heading h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 26px;
  color: #000000;
}

.sub-heading {
  margin-left: 80px;
}

.money-mr-w {
  margin-right: 102px;
}

.originator-mr-w {
  margin-right: 110px;
}

.originator-mr-t {
  margin-right: 92px;
}

.money-mr-t {
  margin-right: 86px;
}

.batch-mr-t {
  margin-right: 90px;
}

@media (max-width: 767px) {
  .summit-page .global-img img {
    height: unset !important;
  }

  .batch-mr-t {
    margin-right: 31px;
  }

  .network .global-img .summit-text h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .network .global-img .summit-text img {
    width: 70% !important;
  }

  .global .global-img .global-text h5 {
    font-size: 18px;
    margin-top: 16px;
  }

  .global .global-img .global-text h6 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .summit-page .global-img .global-text p {
    color: #ffffff !important;
    margin-top: 16px;
  }

  .round-bg {
    top: 0;
    left: 0%;
    width: 50px;
    height: 50px;
    filter: blur(100px);
  }

  .originator-mr-w {
    margin-right: 45px;
  }

  .money-mr-w {
    margin-right: 40px;
  }

  .originator-mr-t {
    margin-right: 30px;
  }

  .money-mr-t {
    margin-right: 26px;
  }

  .umbrella .global-img .global-text img {
    height: auto;
    width: 50%;
  }

  .umbrella .global-img img {
    height: 360px;
  }

  .round-bg-1 {
    top: 0%;
    right: 0;
    width: 50px;
    height: 50px;
    filter: blur(100px);
  }

  .round-bg-2 {
    top: 0%;
    left: 0%;
    width: 50px;
    height: 50px;
    filter: blur(100px);
  }

  .umbrella .digital-financial h4 {
    font-size: 20px;
  }

  .umbrella .digital-financial h3 {
    font-size: 30px;
  }

  .umbrella .digital-financial h2 {
    font-size: 30px;
  }

  .umbrella .digital-financial p {
    font-size: 14px;
    line-height: 24px;
  }

  .sub-heading h3 {
    font-size: 20px;
  }

  .sub-heading {
    margin-left: 0px;
  }

  .sub-heading p {
    font-size: 12px;
    margin-bottom: 18px;
  }

  .umbrella .digital-financial {
    padding: 80px 0;
  }

  .unicoin-services h3,
  .unicoin-solutions h3,
  .unicoin-technology h3 {
    font-size: 30px;
    margin-bottom: 30px;
    margin-left: 0;
  }

  .umbrella .digital-financial .cancel-btn {
    margin-right: 0px;
  }

  .unicoin-tab {
    padding: 20px;
  }

  .unicoin-tab h4 {
    font-size: 20px;
    margin: 18px 0;
    line-height: 35px;
  }

  .unicoin-tab p {
    font-size: 14px;
    line-height: 24px;
  }

  .unicoin-solutions {
    padding-top: 80px;
  }

  .unicoin-stakeholders {
    padding-bottom: 80px;
  }

  .unicoin-services {
    padding: 80px 0;
  }

  .nav-tabs .escrow-information-mr {
    margin-right: 10px;
  }

  .nav-tabs .originator-mr {
    margin-right: 15px;
  }

  .nav-tabs .beneficiary-mr {
    margin-right: 25px;
  }

  .nav-tabs .review-mr {
    margin-right: 28px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .summit-page .global-img .global-text p {
    color: #ffffff !important;
  }

  .summit-page .global-img img {
    height: unset !important;
  }

  .network .global-img .summit-text h2 {
    font-size: 30px;
  }

  .umbrella .digital-financial h4 {
    font-size: 40px;
  }

  .originator-mr-t {
    margin-right: 82px;
  }

  .batch-mr-t {
    margin-right: 84px;
  }

  .money-mr-t {
    margin-right: 74px;
  }

  .umbrella .global-img .global-text img {
    height: auto;
    width: 50%;
  }

  .umbrella .global-img img {
    height: 510px;
  }

  .umbrella .global-img .global-text {
    top: 8%;
  }

  .sub-heading {
    margin-left: 0;
  }

  .sub-heading h3 {
    font-size: 22px;
  }

  .umbrella .digital-financial h3 {
    font-size: 50px;
  }

  .umbrella .digital-financial h2 {
    font-size: 50px;
  }

  .umbrella .digital-financial p {
    font-size: 22px;
    line-height: 34px;
  }

  .umbrella .digital-financial .cancel-btn {
    margin-right: 50px;
  }

  .unicoin-tab {
    padding: 20px;
  }

  .unicoin-tab h4 {
    font-size: 16.9px;
    line-height: 30px;
  }

  .unicoin-tab p {
    font-size: 14px;
    line-height: 22px;
  }

  .unicoin-services h3,
  .unicoin-solutions h3,
  .unicoin-technology h3 {
    font-size: 40px;
    margin-bottom: 40px;
    margin-left: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .umbrella .digital-financial h4 {
    font-size: 40px;
  }

  .network .global-img .summit-text h2 {
    font-size: 40px;
  }

  .originator-mr-t {
    margin-right: 83px;
  }

  .batch-mr-t {
    margin-right: 82px;
  }

  .money-mr-t {
    margin-right: 74px;
  }

  .umbrella .global-img .global-text img {
    height: auto;
    width: 50%;
  }

  .umbrella .global-img .global-text {
    padding: 0;
  }

  .umbrella .global-img .global-text {
    top: 6%;
  }

  .umbrella .digital-financial h3 {
    font-size: 60px;
  }

  .umbrella .digital-financial h2 {
    font-size: 60px;
  }

  .unicoin-tab {
    padding: 30px;
  }

  .unicoin-tab h4 {
    font-size: 23px;
    line-height: 42px;
  }

  .unicoin-tab p {
    font-size: 15px;
    line-height: 25px;
  }

  .unicoin-services h3,
  .unicoin-solutions h3,
  .unicoin-technology h3 {
    font-size: 45px;
    margin-bottom: 40px;
    margin-left: 0;
  }

  .create-escrow-page .deposit-funds-tabs .money::after,
  .create-escrow-page .deposit-funds-tabs .beneficiary::after,
  .create-escrow-page .deposit-funds-tabs .originator::after {
    width: 100px !important;
  }

  .create-escrow-page .nav-tabs .escrow-information-mr {
    margin-right: 12px;
  }

  .create-escrow-page .nav-tabs .originator-mr {
    margin-right: 48px;
  }

  .create-escrow-page .nav-tabs .beneficiary-mr {
    margin-right: 64px;
  }

  .create-escrow-page .nav-tabs .money-mr {
    margin-right: 82px;
  }

  .create-escrow-page .nav-tabs .review-mr {
    margin-right: 68px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .unicoin-tab h4 {
    font-size: 25px;
    line-height: 46px;
  }

  .originator-mr-w {
    margin-right: 170px;
  }

  .money-mr-w {
    margin-right: 162px;
  }

  .money-mr-t {
    margin-right: 141px;
  }

  .originator-mr-t {
    margin-right: 148px;
  }

  .batch-mr-t {
    margin-right: 150px;
  }

  .umbrella .global-img .global-text img {
    height: auto;
    width: 50%;
  }

  .umbrella .global-img .global-text {
    padding: 0;
  }

  .umbrella .global-img .global-text {
    top: 12%;
  }

  .create-escrow-page .deposit-funds-tabs .money::after,
  .create-escrow-page .deposit-funds-tabs .beneficiary::after,
  .create-escrow-page .deposit-funds-tabs .originator::after {
    width: 75px !important;
    left: 55px !important;
  }

  .create-escrow-page .nav-tabs .escrow-information-mr {
    margin-right: 8px;
  }

  .create-escrow-page .nav-tabs .originator-mr {
    margin-right: 42px;
  }

  .create-escrow-page .nav-tabs .beneficiary-mr {
    margin-right: 58px;
  }

  .create-escrow-page .nav-tabs .money-mr {
    margin-right: 76px;
  }

  .create-escrow-page .nav-tabs .review-mr {
    margin-right: 58px;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
  .unicoin-tab {
    padding: 30px;
  }

  .unicoin-tab h4 {
    line-height: 44px;
    font-size: 26px;
  }
}

.react-responsive-modal-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.react-responsive-modal-container {
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}

/* Used to trick the browser to center the modal content properly  */
.react-responsive-modal-containerCenter:after {
  width: 0;
  height: 100%;
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.react-responsive-modal-modal {
  max-width: 800px;
  display: contents;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}

@keyframes react-responsive-modal-overlay-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes react-responsive-modal-overlay-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes react-responsive-modal-modal-in {
  0% {
    transform: scale(0.96);
    opacity: 0;
  }

  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

@keyframes react-responsive-modal-modal-out {
  0% {
    transform: scale(100%);
    opacity: 1;
  }

  100% {
    transform: scale(0.96);
    opacity: 0;
  }
}

.brown-p {
  color: #aa8247 !important;
}

.accordion-header a {
  text-decoration: none;
}

.icons {
  background: #aa8247;
  border-radius: 50%;
  height: 24px;
  line-height: 20px;
  list-style: none;
  text-align: center;
  width: 24px;
}

.introducing {
  width: 95%;
  color: #daae69 !important;
}

.summit-page .global-img .global-text p {
  font-weight: 500;
  color: #001f55;
  margin-top: 20px;
}

.global .global-img .summit-text p {
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 0;
  margin-top: 0;
}

.crypto2-page .monetary-policy-slider .monetary-policy-slider-text h1 {
  font-size: 60px;
}

.crypto2-page {
  background-color: #18172a !important;
}

.administration {
  text-decoration: none;
}

    .administration .administration-text p {
        margin-bottom: 0;
        font-family: "Heebo";
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.9);
        opacity: 0.8;
        margin-left: 16px;
    }

.administration .administration-text.last {
  padding: 7px 20px;
}

.administration .administration-text {
  background: #032a6b;
  border-radius: 12px;
  margin-top: -50px;
  z-index: 9999;
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
}

.administration h4 {
  font-family: "Heebo";
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 30px;
}

.umbrella-box {
  background: linear-gradient(180deg, #052b6d 14.06%, #027eeb 32.29%);
}

@media (max-width: 767px) {
  .administration h4 {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .administration .administration-text {
    display: inline-block;
    padding: 10px;
    text-align: center;
  }

  .administration .administration-text p {
    font-size: 14px;
    line-height: 22px;
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .administration h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .administration .administration-text {
    display: inline-block;
    padding: 10px;
    text-align: center;
  }

  .administration .administration-text p {
    font-size: 14px;
    line-height: 22px;
    margin-left: 0;
    margin-top: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .administration h4 {
    font-size: 25px;
    margin-bottom: 14px;
  }

  .administration .administration-text p {
    font-size: 14px;
    line-height: 22px;
    margin-left: 12px;
  }

  .administration .administration-text {
    padding: 17px 20px;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
  .administration h4 {
    font-size: 28px;
  }
}

.custom-file-button input[type="file"] {
  margin-left: -2px !important;
}

.custom-file-button input[type="file"]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type="file"]::file-selector-button {
  display: none;
}

.custom-file-button:hover label {
  background-color: #dde0e3;
  cursor: pointer;
}

.user-photo {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.review-wallet-box h1 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 28px;
  color: #aa8247;
  margin: 25px 0 15px;
}

.beneficiary-box a {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: #0a9de1;
  text-decoration: none;
}

.beneficiary-box p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0px;
  color: #000000;
}

.beneficiary-box label {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: #808080;
  margin: 20px 0;
  display: block;
}

.reference-box h3 {
  background: #f41717 !important;
}

.rates-box h3 {
  background: #fe9e68 !important;
}

.payment-box h3 {
  background: #35c54c !important;
}

.beneficiary-box h3 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  background: #6892fe;
  border-radius: 4px;
  padding: 10px 0;
}

.beneficiary-box {
  background: #f0f1f2;
  border-radius: 4px;
  padding: 24px;
  height: 100%;
}

.bi.bi-check-circle-fill {
  font-size: 24px;
  color: #9e854e;
}

.wallet-information-box .wallet-information-form .table i {
  position: unset;
  top: unset;
  transform: unset;
  right: unset !important;
  font-size: 24px;
  color: #9e854e;
}

.tab-btn h3 {
  margin: 0 !important;
}

.tab-btn {
  display: unset !important;
  border-bottom: 0 !important;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1 !important;
  transform: scale(0.95) translateY(-1rem) translateX(0) !important;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-right: 22px;
}

.attach-device {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  text-align: center;
  width: 600px;
  margin: auto;
  padding: 20px 0;
}

.attach-device h3 {
  font-family: "Oxygen";
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 20px;
}

.attach-device img {
  border-radius: 10px;
  max-width: 250px;
}

.attach-device h6 {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0px;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .attach-device {
    width: auto;
    padding: 20px;
  }

  .attach-device img {
    max-width: 220px;
  }

  .attach-device h3 {
    font-size: 18px;
  }

  .attach-device h6 {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .attach-device {
    width: auto;
    padding: 20px;
  }

  .attach-device img {
    max-width: 220px;
  }

  .attach-device h3 {
    font-size: 18px;
  }

  .attach-device h6 {
    font-size: 16px;
  }
}

.login-qr {
  /* border: 3px solid #aa8247; */
  width: 502px;
  padding: 34px 0 0 0;
}

.monetary-authorities .global-img {
  text-align: end;
}

.monetary-authorities {
  background: #0f202a;
  height: 100vh;
  align-items: center;
  display: flex;
}

.fintechs {
  background: linear-gradient(
    179.62deg,
    #010206 0.32%,
    #000b19 5.31%,
    #000f24 9.75%,
    #00112a 12.04%,
    #011633 15.24%,
    #001938 18.06%,
    #001a3b 20.28%,
    #001c41 22.83%,
    #011d45 25.1%,
    #001f46 27.73%,
    #00214c 30.69%,
    #00224f 33.58%,
    #022452 36.89%,
    #002454 39.93%,
    #002657 42.94%,
    #002657 46.38%,
    #002657 50.52%,
    #002454 54.83%,
    #002454 57.08%,
    #002552 59.91%,
    #00224f 63.45%,
    #002049 67.66%,
    #011e46 70.68%,
    #011d44 73.2%,
    #001a3b 77.33%,
    #001736 80.78%,
    #01112a 85.27%,
    #000f24 89.56%,
    #010914 94.33%,
    #010409 97.54%,
    #000000 99.67%
  );
}

.monetary-authorities .global-img .global-text h1 {
  font-family: "Libre Franklin";
  font-weight: 500;
  font-size: 50px;
  line-height: 63px;
  color: #ffffff;
  margin-bottom: 0;
}

.monetary-authorities .global-img .global-text p {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
}

.monetary-authorities .global-img .global-text {
  background: unset;
  padding: unset;
  position: absolute;
  right: unset;
  bottom: unset;
  left: 8%;
  top: 20%;
  text-align: start;
}

.crypto-exchanges {
  background: #000002;
}

.crypto-exchanges .global-img .global-text {
  top: 40%;
  transform: translateY(-50%);
}

.merchants {
  background: #25282e !important;
}

@media (max-width: 767px) {
  .monetary-authorities .global-img .global-text h1 {
    font-size: 22px;
    line-height: 25px;
  }

  .monetary-authorities .global-img .global-text p {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .monetary-authorities .global-img .global-text {
    left: 50%;
    transform: translateX(-50%);
    top: -130px;
    text-align: center;
    width: 100%;
  }

  .monetary-authorities {
    height: unset;
    align-items: unset;
    display: block;
    padding: 200px 0 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .monetary-authorities .global-img img {
    width: 70%;
  }

  .crypto-exchanges .global-img img {
    width: unset !important;
  }

  .crypto-exchanges .global-img .global-text {
    left: unset;
    top: 40% !important;
  }

  .monetary-authorities .global-img .global-text p {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .monetary-authorities .global-img .global-text h1 {
    font-size: 28px !important;
    line-height: 44px;
  }

  .monetary-authorities .global-img .global-text {
    left: 100px;
    top: 50px;
  }

  .fintechs .global-img img {
    width: 60%;
  }

  .fintechs .global-img .global-text {
    top: 200px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .monetary-authorities .global-img img {
    width: 80%;
  }

  .crypto-exchanges .global-img img {
    width: unset !important;
  }

  .crypto-exchanges .global-img .global-text {
    left: unset;
    top: 40% !important;
  }

  .monetary-authorities .global-img .global-text p {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .monetary-authorities .global-img .global-text h1 {
    font-size: 32px !important;
    line-height: 48px;
  }

  .monetary-authorities .global-img .global-text {
    left: 80px;
    top: 100px;
  }

  .fintechs .global-img img {
    width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .monetary-authorities .global-img img {
    width: 70%;
  }

  .crypto-exchanges .global-img img {
    width: unset !important;
  }

  .crypto-exchanges .global-img .global-text {
    left: unset;
    top: 40% !important;
  }

  .monetary-authorities .global-img .global-text p {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .monetary-authorities .global-img .global-text h1 {
    font-size: 38px !important;
    line-height: 52px;
  }

  .monetary-authorities .global-img .global-text {
    left: 170px;
    top: 170px;
  }

  .fintechs .global-img img {
    width: 45%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .monetary-authorities .global-img .global-text {
    left: 56px;
    top: 20%;
  }

  .monetary-authorities .global-img .global-text h1 {
    font-size: 56px;
  }

  .fintechs .global-img img {
    width: 40%;
  }

  .crypto-exchanges .global-img .global-text {
    top: 40%;
    transform: translateY(-50%);
  }
}

.nav-item .dropdown-toggle::after {
  display: inline-block;
  margin-left: 13px !important;
  vertical-align: 0.055em;
  content: "";
  margin-top: -2px;
  width: 10px;
  height: 10px;
  border-top: 2px solid #000 !important;
  border-left: 2px solid #000 !important;
  transform: rotate(225deg);
  border-right: unset !important;
  border-bottom: unset !important;
}

.nav-item .dropdown-item:focus,
.nav-item .dropdown-item:hover {
  background-color: #fff0cb;
  border-left: 5px solid #a47e1f;
}

.nav-item ul li:last-child .dropdown-item {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.nav-item ul li:first-child .dropdown-item {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.nav-item .dropdown-item .nav-link {
  color: #212529 !important;
  padding: 0 !important;
}

.nav-item .dropdown-item {
  color: #212529 !important;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.03em;
  font-family: "Oxygen";
  transition: all 0.2s;
  padding: 10px 15px;
  border-left: 5px solid #fff;
}

.nav-item .dropdown-menu[data-bs-popper] {
  top: 40px;
  border-radius: 8px;
  padding: 0;
}

@media (max-width: 1199px) {
  .nav-item .dropdown-menu.show {
    display: inline-block;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .nav-item .dropdown-toggle::after {
    margin-left: 6px !important;
    width: 5px;
    height: 5px;
    border-top: 1px solid #000 !important;
    border-left: 1px solid #000 !important;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
  .nav-item .dropdown-toggle::after {
    margin-left: 9px !important;
    margin-bottom: 4px;
    width: 8px;
    height: 8px;
    border-top: 1.5px solid #000 !important;
    border-left: 1.5px solid #000 !important;
  }
}

.section-0 {
  padding: 20px 0 60px;
  min-height: 65vh;
  background: url(../../public/images/header.png) no-repeat 50%;
  background-size: cover;
}

.two-column-block p {
  font-size: 16px;
  margin-top: 20px;
}

.text-red {
  color: red;
}

.large-heading {
  font-size: 35px;
  line-height: 45px;
  font-weight: 300;
}

.large-heading span {
  font-weight: 600;
}

.small-heading {
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
}

.pd-30-20 {
  padding: 30px 20px;
}

.small-divider {
  width: 85px;
  height: 8px;
  background: #01546f;
  display: inline-block;
  margin: 30px 0 0;
}

.plr-0 {
  padding-left: 0;
  padding-right: 0;
}

.section {
  min-height: 600px;
  color: #fff;
  padding: 60px 0;
  position: relative;
}

.section-2 {
  background: url(../../public/images/section-2.png) no-repeat 50%;
  background-size: cover;
}

.two-column-block {
  color: #666;
  display: flex;
  align-items: center;
  padding: 60px 0;
}

.two-column-block p {
  line-height: 140%;
}

.section-3 {
  background: url(../../public/images/section-3.png) no-repeat 50%;
  background-size: cover;
}

.section-3 p {
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
}

.section-3 .small-divider {
  background: #fff;
}

.section-4 {
  padding: 60px 0;
  color: #666;
}

.section-4 p {
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
}

.section-5 {
  min-height: 500px;
  position: relative;
  background: url(../../public/images/section-5.png) no-repeat 50%;
  background-size: cover;
}

.section-5 p {
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
}

.section-5 .bold {
  font-size: 20px;
}

.section-6 {
  color: #666;
  min-height: 500px;
  position: relative;
  background: url(../../public/images/section-6.png) no-repeat 50%;
  background-size: cover;
}

.section-6 p {
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
}

.section-6 .form-control {
  display: block;
  width: 100%;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: auto !important;
}

.section-6 .btn-1,
.section-6 .form-control {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.section-6 .btn-1 {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.min-h-600 {
  min-height: 600px;
}

.bg-white {
  background: #fff;
}

.bg-white-50 {
  background: hsla(0, 0%, 100%, 0.5);
}

.text-green {
  color: #366 !important;
}

.section-5 .img-fix {
  margin-top: 10%;
}

.desktop-img {
  display: block !important;
}

.mobile-img {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .desktop-img {
    display: none !important;
  }

  .mobile-img {
    display: block !important;
    margin-top: 20px;
  }

  .section-2:before,
  .section-3:before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(16, 62, 85, 0);
  }
}

.kyc-section {
  min-height: 600px;
  color: #fff;
  padding: 30px 0;
  position: relative;
}

.kyc-section-1 {
  background: #193751;
  color: #fff;
}

.kyc-section-1 p {
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
}

.kyc-section-1 .content {
  max-width: 450px;
}

.kyc-section-1 .hero-btn {
  background: #ee9321;
  padding: 12px 24px;
  color: #fff;
  font-size: 15px !important;
  display: inline-block;
  margin-top: 30px;
}

.divider-gray {
  width: 100%;
  background: #ccc;
  height: 30px;
}

.kyc-section-2 {
  /* background: url(img-2-hd.1f9f3942c232ff44460e.png) no-repeat 50%; */
  background-size: cover;
}

.kyc-section-2 p {
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
}

.kyc-section-2 h2 {
  font-size: 28px;
  line-height: 38px;
}

.kyc-section-3 {
  /* background: url(img-3-hd.55e0400e942746586258.png) no-repeat 50%; */
  background-size: cover;
}

.kyc-section-3 p {
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
}

.cta-btn {
  background: #ee9321;
  padding: 12px 24px;
  color: #fff;
  font-size: 15px !important;
  display: inline-block;
  margin-top: 30px;
}

.gdpc-section {
  padding: 30px 0;
  position: relative;
}

.gdpc-section-1 {
  background: #fff;
  color: #333;
}

.gdpc-section-1 .content {
  max-width: 450px;
}

.gdpc-section-1 p {
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
}

.gdpc-section-1 h1 {
  font-size: 45px;
  line-height: 58px;
  text-transform: uppercase;
}

.gdpc-cta-btn {
  color: #ee9321;
  font-size: 15px;
  display: inline-block;
  margin-top: 30px;
  text-transform: uppercase;
  font-weight: 700;
}

.gdpc-cta-btn:hover {
  color: #ee9321;
  text-decoration: none;
}

.text-orange {
  color: #ee9321;
}

.gdpc-section-2 {
  padding: 60px 0;
  background: #f2f2f2;
}

.gdpc-section-2 h2 {
  font-size: 30px;
  line-height: 39px;
  text-transform: uppercase;
  padding-bottom: 30px;
}

.sec-2 h2 span {
  font-weight: 700;
}

.gdpc-section-2 p {
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
}

.gdpc-section-3 {
  background: url(../../public/images/gdpcFormBg.png) no-repeat 50%;
  background-size: cover;
}

.gdpc-section-3 .cta-btn-block {
  margin-top: 0;
  outline: none !important;
  border: none !important;
}

.cta-btn-block {
  background: #ee9321;
  color: #fff;
  border-radius: 100px;
  font-size: 15px;
  display: inline-block;
  margin-top: 30px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 12px 24px;
}

.cta-btn-block:hover {
  background: #ee9321;
  color: #fff;
  text-decoration: none;
}

.gdpc-bg-white {
  background: #fff;
  padding: 30px 40px;
}

.gdpc-section-3 p {
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
}

.gdpc-section-3 h2 {
  font-size: 30px;
  line-height: 39px;
  text-transform: uppercase;
  padding-bottom: 30px;
}

.gdpc-section-3 .form {
  padding: 30px 0;
}

.gdpc-section-3 .form .form-control {
  background: #fbfbfb;
  border: 1px solid #e5e4e4;
  border-radius: 0 !important;
  height: auto !important;
}

.formError {
  color: red;
}

.form-control {
  height: 50px;
  border-radius: 0;
  color: #000 !important;
}

.form-control,
.form-select {
  border-color: #ed9321;
  box-shadow: none;
}

.form-select {
  height: 50px !important;
  border-radius: 0 !important;
  color: #000;
}

#countryDropdown {
  background: #fff !important;
  color: #000 !important;
  text-align: left;
  width: 100%;
  border-radius: 0 !important;
  border: 1px solid #ed9321 !important;
}

#countryDropdown:focus {
  color: #212529;
  background-color: #fff;
  border-color: #0054b7 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(253 201 13/25%) !important;
}

.errorSummary {
  color: rgba(211, 47, 47, 0.918);
  font: inherit;
}

.error-summary-background {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border: 0.1rem solid red;
}

.language {
  font-size: 16px;
  background: inherit;
  color: #fff;
  font-weight: 600;
  border: none;
  outline: none;
}

.language-img {
  width: 20px;
  margin-left: 28px;
}

.user-details {
  padding: 54px 35px;
  overflow-x: hidden !important;
  width: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.user-details .tab {
  position: sticky;
  top: 0;
}

.user-details .tabcontent select {
  height: 50px !important;
  padding: 10px 25px;
}

.user-details label {
  font-size: 20px;
}

.user-details .tabcontent input:focus,
.user-details .tabcontent select:focus {
  background: #fff;
  border: 1px solid hsla(0, 0%, 100%, 0.35);
  color: #26263a;
}

.user-details .tabcontent .btn:first-child {
  background: transparent !important;
  color: #000;
  border-radius: 0;
}

.user-details .tabcontent .btn:hover {
  background: #00aef0 !important;
  color: #fff !important;
  font-size: 20px;
}

.user-details .tabcontent .btn {
  border-radius: 6px;
  width: 170px;
  padding: 12px 0;
  font-size: 20px;
  color: #fff;
  background: linear-gradient(
    264.95deg,
    #0ae5fe -24.77%,
    #00aef0 93.46%
  ) !important;
  border: 1px solid #565663;
  box-sizing: border-box;
  border-radius: 0;
  margin: 10px;
}

.user-details .tabcontent .item .add-account {
  padding: 10px;
  width: auto;
  font-size: 16px;
  color: #fff;
  border: none;
  box-sizing: border-box;
  border-radius: 0;
  margin: 0;
}

.user-details .tabcontent .btn:first-child {
  background: transparent;
}

.user-details .tabcontent .btn:hover {
  background: #00aef0;
}

.tabcontent .add-account-form {
  display: none;
}

.user-details .tabcontent .account-list .alert {
  background: rgba(75, 78, 99, 0.7);
}

.user-details .tabcontent .account-list .alert button.close {
  padding: 8px 10px;
  outline: none;
}

.user-details .tabcontent .account-list .alert i.fa {
  padding: 10px 11px;
  background-color: #13567b;
  border-radius: 100%;
  font-size: 14px;
  color: #fff;
  outline: none;
}

.user-details .tab button {
  width: 100%;
  display: block;
  padding: 12px 16px;
  margin: 5px 0;
  font-size: 18px;
  border-radius: 10px;
  text-align: left;
  background-color: var(--color-blue);
  border: 1px solid hsla(0, 0%, 100%, 0);
  color: rgb(12 12 12/93%);
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}

.user-details .tabcontent {
  padding: 0 20px;
  color: #000;
}

.user-details .tab button.active {
  background: rgba(75, 78, 99, 0.7);
  border-color: hsla(0, 0%, 100%, 0.1);
  background: #1393c3;
  color: #f1f1f1;
  font-weight: 700;
}

.user-profile {
  width: 100%;
  text-align: center;
  font-family: var(--font-rubik);
}

.user-profile .image {
  width: 153px;
  height: 153px;
  margin: auto auto 10px;
  overflow: hidden;
  border-radius: 100%;
}

.user-profile .image img {
  width: 100%;
  height: 100%;
}

.form-group-date {
  width: 100%;
  height: 50px;
  padding: 10px 25px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ed9321;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-group-date:disabled,
.form-group-date[readonly] {
  background-color: #ed9321;
  opacity: 1;
}

mat-datepicker-toggle
  button.mat-focus-indicator.mat-icon-button.mat-button-base {
  outline: none;
  margin-left: -44px !important;
  color: #ed9321;
}

.btn-upload {
  background: #26263a !important;
  color: #ed9321;
  border: 1px solid hsla(0, 0%, 100%, 0.35);
  position: relative;
}

.btn-upload input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.hero {
  background-size: cover;
  background-position: 100% 100%;
  color: #fff;
  display: flex;
  padding: 2rem;
  margin-bottom: 2rem;
  justify-content: left;
  align-items: center;
}

.bw {
  border: 1px solid #fff !important;
}

.column {
  flex: 1 0 auto;
  position: relative;
}

.hero,
.hero-content {
  padding: 4rem;
}

.hero-title[data-v-bb71eb2c] {
  line-height: 1.2;
}

@media (min-width: 64em) {
  .column-50 {
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
  }
}

@media screen and (min-width: 64em) {
  .display-1 {
    font-size: 2.6rem;
  }
}

.display-1 {
  font-weight: 600;
  margin: 0 0 1rem;
  line-height: 1.2;
  display: block;
  color: #fff;
}

.privacy-policy .container table thead {
  background-color: #86837f;
  color: #fff;
}

.privacy-policy .container h2 {
  color: #4e4e4e;
  font-size: 24px;
  font-weight: 600;
}

.term-condition .container h2 {
  color: #4e4e4e;
  font-size: 24px;
  font-weight: 600;
}

@media only screen and (max-width: 1399px) {
  .text-box {
    padding-right: 0px !important;
  }

  .section-biggest-untapped-opportunity h2 {
    padding-right: 0 !important;
  }

  .section .container p {
    padding-right: 0 !important;
  }

  .coins-imgs {
    padding-right: 0 !important;
  }
}

@media (min-width: 991px) {
  .payment-form {
    margin-left: -138px;
  }
}

.demo-landing {
  background: linear-gradient(180deg, #072c6d 0%, #047eea 100%);
}

.componentized-architecture h3 {
  font-family: "Heebo";
  font-weight: 500;
  font-size: 42px;
  color: #ffffff;
  margin-bottom: 30px;
}

.componentized-architecture h6 {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 12px;
  color: #ffffff;
}

.componentized-architecture-box .card .list-group {
  flex-direction: unset;
  justify-content: space-around;
}

.componentized-architecture-box .card .list-group .list-group-box h3 {
  font-size: 18px;
  padding: 10px 0 0;
  text-align: center;
  line-height: 24px;
  margin-bottom: 16px;
}

.componentized-architecture-box .card .list-group .list-group-box .box-bg {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  text-align: center;
  line-height: 98px;
  margin: auto;
}

.componentized-architecture-box .card .list-group {
  border: 0;
}

.componentized-architecture-box .card .card-img-top {
  border-radius: 10px;
}

.componentized-architecture-box .card {
  background: #032a6b;
  height: 100%;
  border-radius: 10px;
}

.componentized-architecture-box .card-body {
  flex: none;
}

.componentized-architecture-box {
  height: 100%;
}

.componentized-architecture-box .card-body h5 {
  font-family: "Heebo";
  font-weight: 500;
  font-size: 28px;
  text-transform: capitalize;
  color: #ffffff;
}

.componentized-architecture-head {
  margin-left: -40px;
}

.mobile-device {
  padding-top: 100px;
}

.mobile-device .mobile-device-box .payment p {
  font-family: "Heebo";
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.8;
  margin-bottom: 0;
}

.mobile-device .mobile-device-box .payment h6 {
  font-family: "Heebo";
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  color: #1c10d6;
  margin-bottom: 6px;
  opacity: 0.8;
}

.mobile-device .mobile-device-box .payment {
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 20px;
}

.mobile-device .mobile-device-box hr {
  opacity: 1;
  background: #fff;
  margin: 0;
}

.mobile-device .mobile-device-box h3 {
  font-family: "Heebo";
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 0;
  padding: 18px;
  text-transform: capitalize;
}

.mobile-device .mobile-device-box {
  background: #032a6b;
  border-radius: 10px;
  padding: 0 0 1px;
}

.digital-identifier span i {
  color: #032a6b;
  margin-right: 6px;
}

.digital-identifier .arrow-landing {
  width: 80%;
  text-align: center;
}

.digital-identifier span {
  font-family: "Heebo";
  font-weight: 400;
  font-size: 20px;
  text-transform: capitalize;
  color: #ffffff;
  opacity: 0.8;
  margin: 0 auto;
  display: block;
  margin-bottom: 6px;
  width: 60%;
}

.payment-messaging {
  margin-top: 100px;
}

.payment-messaging-box h3 {
  font-family: "Heebo";
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 0;
  margin-top: 10px;
}

.payment-messaging-box {
  display: inline-block;
  text-align: center;
}

.payment-messaging-rouned.old {
  margin-bottom: 0;
  margin-top: 30px;
}

.payment-messaging-rouned {
  width: 32px;
  height: 32px;
  background: #1c10d6;
  margin: 0 auto;
  border-radius: 50%;
  border: 3px solid #fff;
  margin-bottom: 30px;
  position: relative;
  z-index: 999;
}

.payment-messaging-box .payment-messaging-box-1 {
  background: #ffffff;
  border-radius: 5px;
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 98px;
}

.payment-messaging-line:after {
  content: "";
  width: 1490px;
  border-bottom: 2px dashed #fff;
  position: absolute;
  top: 266px;
  left: 190px;
}

.payment-messaging-line {
  position: relative;
  padding: 0 0 500px;
}

.even-1,
.even-2,
.even-3,
.even-4 {
  position: absolute;
  top: 250px;
}

.old-1,
.old-2,
.old-3,
.old-4 {
  position: absolute;
  top: 58px;
}

.even-1 {
  left: 130px;
}

.even-2 {
  left: 530px;
}

.even-3 {
  left: 930px;
}

.even-4 {
  left: 1330px;
}

.old-1 {
  left: 330px;
}

.old-2 {
  left: 724px;
}

.old-3 {
  left: 1130px;
}

.old-4 {
  left: 1610px;
}

.demo-landing .digital-financial .next-btn {
  border: 3px solid #3a31dd;
}

.demo-landing .digital-financial .next-btn i {
  color: #3a31dd;
}

.demo-landing .digital-financial .next-btn h3 {
  color: #3a31dd;
}

.demo-landing .digital-financial .cancel-btn {
  background: #3a31dd;
}

@media (max-width: 767px) {
  .payment-messaging {
    margin-top: 70px;
  }
  .payment-messaging-line {
    padding: 0;
  }
  .payment-messaging-box {
    width: 250px;
    margin-left: 60px;
  }
  .payment-messaging-rouned {
    position: absolute;
  }
  .payment-messaging-box.even-4,
  .payment-messaging-box.even-3,
  .payment-messaging-box.even-2,
  .payment-messaging-box.even-1 {
    position: relative;
    top: 0;
  }
  .payment-messaging-box.even-1 .payment-messaging-rouned,
  .payment-messaging-box.even-2 .payment-messaging-rouned,
  .payment-messaging-box.even-3 .payment-messaging-rouned,
  .payment-messaging-box.even-4 .payment-messaging-rouned {
    left: -50px;
    top: 30px;
  }
  .payment-messaging-box.old-1,
  .payment-messaging-box.old-2,
  .payment-messaging-box.old-3,
  .payment-messaging-box.old-4 {
    position: relative;
    top: 0;
  }
  .payment-messaging-box.old-1 .payment-messaging-rouned.old,
  .payment-messaging-box.old-2 .payment-messaging-rouned,
  .payment-messaging-box.old-3 .payment-messaging-rouned,
  .payment-messaging-box.old-4 .payment-messaging-rouned {
    left: -50px;
    top: 30px;
  }
  .even-1,
  .even-2,
  .even-3,
  .even-4 {
    position: unset;
    right: 0;
    left: 0;
  }
  .payment-messaging-line:after {
    content: "";
    width: 0;
    border-left: 2px dashed #fff;
    position: absolute;
    top: 40px;
    left: 26px;
    height: 1223px;
  }
  .payment-messaging-rouned.old {
    margin: 0;
    position: absolute;
  }
  .old-1,
  .old-2,
  .old-3,
  .old-4 {
    position: unset;
    right: 0;
    left: 0;
  }
  .componentized-architecture-head {
    margin-left: 0px;
    text-align: center;
  }

  .componentized-architecture h6 {
    font-size: 16px;
  }

  .componentized-architecture h3 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .componentized-architecture-box .card-body {
    padding: 10px;
  }

  .componentized-architecture-box .card-body h5 {
    font-size: 20px;
    margin-bottom: 0;
  }

  .componentized-architecture-box
    .card
    .list-group
    .list-group-box
    .box-bg
    img {
    width: 50%;
  }

  .componentized-architecture-box .card .list-group .list-group-box .box-bg {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    line-height: 68px;
  }

  .componentized-architecture-box .card .list-group .list-group-box h3 {
    font-size: 13px;
    padding: 8px 0 0;
    line-height: 18px;
  }

  .demo-landing .digital-financial p {
    width: 100%;
  }

  .mobile-device {
    padding-top: 70px;
  }

  .mobile-device .mobile-device-box h3 {
    font-size: 22px;
    padding: 12px;
  }

  .mobile-device .mobile-device-box .payment img {
    width: 70%;
  }

  .mobile-device .mobile-device-box .payment {
    margin: 10px;
  }

  .mobile-device .mobile-device-box .payment h6 {
    font-size: 13px;
    margin-bottom: 2px;
  }

  .mobile-device .mobile-device-box .payment p {
    font-size: 12px;
  }

  .digital-identifier span {
    font-size: 16px;
    width: 75%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .payment-messaging-box {
    width: 500px;
    margin-left: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .even-1 {
    left: 0;
  }
  .even-4 {
    left: 510px;
  }
  .even-3 {
    left: 338px;
  }
  .even-2 {
    left: 160px;
  }
  .old-1 {
    right: unset;
    left: 82px;
  }
  .old-2 {
    right: unset;
    left: 240px;
  }
  .old-3 {
    right: unset;
    left: 430px;
  }
  .old-4 {
    right: unset;
    left: 615px;
  }
  .payment-messaging-box h3 {
    font-size: 14px !important;
    line-height: 18px;
  }
  .even-1,
  .even-2,
  .even-3,
  .even-4 {
    top: 214px;
  }
  .payment-messaging-line:after {
    width: 618px;
    top: 230px;
    left: 50px;
  }
  .payment-messaging-line {
    padding: 0 0 400px;
  }
  .payment-messaging-box .payment-messaging-box-1 img {
    width: 50%;
  }
  .payment-messaging-box .payment-messaging-box-1 {
    width: 80px;
    height: 80px;
    line-height: 78px;
  }
  .componentized-architecture h6 {
    font-size: 22px;
  }

  .componentized-architecture h3 {
    font-size: 30px;
  }

  .componentized-architecture-box .card-body h5 {
    font-size: 24px;
  }

  .componentized-architecture-box .card .list-group .list-group-box h3 {
    font-size: 15px;
    line-height: 22px;
  }

  .componentized-architecture-head {
    margin-left: 0px;
    text-align: center;
  }

  .digital-identifier span {
    width: 80%;
  }

  .mobile-device .mobile-device-box .payment h6 {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .even-1 {
    left: 0;
  }

  .even-4 {
    left: 692px;
  }

  .even-3 {
    left: 457px;
  }

  .even-2 {
    left: 236px;
  }

  .old-1 {
    right: unset;
    left: 122px;
  }

  .old-2 {
    right: unset;
    left: 340px;
  }

  .old-3 {
    right: unset;
    left: 580px;
  }

  .old-4 {
    right: unset;
    left: 835px;
  }

  .payment-messaging-box h3 {
    font-size: 14px !important;
    line-height: 18px;
  }

  .even-1,
  .even-2,
  .even-3,
  .even-4 {
    top: 214px;
  }

  .payment-messaging-line:after {
    width: 848px;
    top: 230px;
    left: 50px;
  }

  .payment-messaging-line {
    padding: 0 0 400px;
  }

  .payment-messaging-box .payment-messaging-box-1 img {
    width: 50%;
  }

  .payment-messaging-box .payment-messaging-box-1 {
    width: 80px;
    height: 80px;
    line-height: 78px;
  }

  .componentized-architecture-head {
    margin-left: 0;
  }

  .componentized-architecture-box .card-body h5 {
    font-size: 22px;
  }

  .componentized-architecture-box .card .list-group .list-group-box h3 {
    font-size: 15px;
    line-height: 21px;
  }

  .componentized-architecture-box
    .card
    .list-group
    .list-group-box
    .box-bg
    img {
    width: 50%;
  }

  .componentized-architecture-box .card .list-group .list-group-box .box-bg {
    width: 80px;
    height: 80px;
    line-height: 78px;
  }

  .mobile-device .mobile-device-box .payment {
    margin: 10px;
  }

  .mobile-device .mobile-device-box h3 {
    font-size: 24px;
    padding: 16px;
  }

  .mobile-device .mobile-device-box .payment img {
    width: 80%;
  }

  .mobile-device .mobile-device-box .payment h6 {
    font-size: 13px;
  }

  .mobile-device .mobile-device-box .payment p {
    font-size: 14px;
  }

  .digital-identifier span {
    font-size: 16px;
    width: 73%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .componentized-architecture-head {
    margin-left: 0;
  }
  .digital-identifier span {
    width: 72%;
  }
  .even-1 {
    left: 0;
  }

  .even-4 {
    left: 852px;
  }

  .even-3 {
    left: 587px;
  }

  .even-2 {
    left: 306px;
  }

  .old-1 {
    right: unset;
    left: 162px;
  }

  .old-2 {
    right: unset;
    left: 442px;
  }

  .old-3 {
    right: unset;
    left: 730px;
  }

  .old-4 {
    right: unset;
    left: 1035px;
  }
  .even-1,
  .even-2,
  .even-3,
  .even-4 {
    top: 214px;
  }

  .payment-messaging-line:after {
    width: 1058px;
    top: 230px;
    left: 60px;
  }

  .payment-messaging-line {
    padding: 0 0 400px;
  }
  .old-1,
  .old-2,
  .old-3,
  .old-4 {
    top: 22px;
  }
}

@media (min-width: 1400px) and (max-width: 1799px) {
  .componentized-architecture-box .card-body h5 {
    font-size: 24px;
  }
  .componentized-architecture-box .card .list-group .list-group-box .box-bg {
    width: 80px;
    height: 80px;
    line-height: 78px;
  }
  .digital-identifier span {
    width: 84%;
  }
  .mobile-device .mobile-device-box .payment img {
    width: 70%;
  }
  .mobile-device .mobile-device-box .payment p {
    font-size: 14px;
  }
  .mobile-device .mobile-device-box .payment h6 {
    font-size: 12px;
  }
  .old-1 {
    left: 290px;
  }
  .old-2 {
    left: 575px;
  }
  .old-3 {
    left: 890px;
  }
  .old-4 {
    left: 1220px;
  }
  .even-4 {
    left: 1030px;
  }
  .even-3 {
    left: 740px;
  }

  .even-2 {
    left: 430px;
  }
  .payment-messaging-line:after {
    width: 1100px;
  }
}
.bg-red {
  background-color: red !important;
}
.table-form-check {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.table-form-check .form-check-input {
  width: 24px;
  height: 24px;
  border: 1px solid #aa8247;
  /* border-radius: 50% !important; */
}
.table-form-check .form-check-input:checked {
  background-color: #aa8247;
  border-color: #aa8247;
}
.table-lable {
  color: #000;
  font-size: 16px;
}
.form-check-input[type="radio"]:checked::before {
  border: 5px solid transparent;
  border-radius: 100%;
  outline-offset: -6px;
  background: #000;
}

.custom-pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #aa8247;
  border-color: #aa8247;
}
.custom-pagination .page-link {
  color: #000;
  border: none;
}

.custom-pagination .page-link:hover {
  z-index: 2;
  color: #fff;
  background-color: #aa8247;
  border-color: #aa8247;
}
.mn-w-132 {
  min-width: 132px;
}
.mi-w-165 {
  min-width: 165px;
}
.bg-salmon {
  background-color: salmon;
  color: white;
}
.table-form-check {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.table-form-check .form-check-input {
  width: 24px;
  height: 24px;
  border: 1px solid #aa8247;
}
.form-check-input[type="radio"] {
  border-radius: 0 !important;
  width: 24px;
  height: 24px;
  border: 1px solid #aa8247;
  /* border-radius: 50% !important; */
}
.table-form-check .form-check-input:checked {
  background-color: #aa8247;
  border-color: #aa8247;
}
.data-table-row {
  height: 1px;
}
.data-table-head {
  height: inherit;
}
.lable-div {
  height: 100%;
}
/* .is-checked {
    background-color: #901c1c;
    color: white;
  } */

.target-menu {
  font-size: 18px;
  font-weight: 600;
  color: #000 !important;
}
.box-sadow {
  box-shadow: 0px 4px 30px 0 rgba(0, 0, 0, 0.5) !important;
  padding: 30px;
}
.pagination-table {
  justify-content: center;
}
.pagination-table img {
  width: 15px;
  height: 15px;
  margin-right: 7px;
}
.pagination-table input {
  width: 40px;
  background: #fff;
  border: none;
  box-shadow: none;
}
.row-select .MuiInputBase-root fieldset {
  display: none;
}
.pagination-table input:focus-visible {
  outline: none !important;
}

.roteted180 {
  transform: rotate(180deg);
}
.shadow-none {
  box-shadow: none !important;
  border-bottom: 1px solid #808080;
  margin-right: 10px;
}

.shadow-none .input-group-text i {
  left: 0 !important;
  color: #828282;
  font-size: 18px;
  right: auto !important;
}

.form-check-input-square {
  border-radius: 50%;
}
.pagination-select {
  border: none !important;
}
.pointer {
  cursor: pointer;
}
.table-height {
  max-height: 500px;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.text-16 {
  font-size: 16px !important;
}
.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: #aa824752 !important;
}
.loadercoin {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000070;
}
.spiner img {
  width: 150px;
  height: 150px;
  animation: animName 1.5s linear infinite;
}
@keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-heading i {
  position: static !important;
  top: 0 !important;
  transform: translateY(0px) !important;
}
.bline {
  border-bottom: 1px solid #004bad;
  width: 315px;
}
.btnmodal > div > .bg-light {
  background-color: #fff !important;
}

.mobile-num-main {
  position: relative;
}
.mobile-num-main .mobile-num-select {
  position: absolute;
  outline: none;
  border: none;
  background: #fff;
  padding: 5px;
  top: 5px;
  width: 68px;
}
.mobile-num-main .mobile-num-input {
  padding-left: 70px !important;
}
.side-line {
  margin-right: 103px !important;
}
.Deposit-Review{
  left: 96px;
}
.Deposit-Review img {
  width: 120px;
  height: 120px;
}

.input-box .custom-file-button .form-control {
    height: 35px;
}
.input-box .custom-file-button .input-group-text {
    align-items: center !important;
    background-color: #333 !important;
    border: 1px solid #333 !important;
    border-radius: 0 5px 5px 0 !important;
    color: #fff !important;
    display: flex !important;
    font-family: Oxygen;
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: .05em;
    line-height: 1.5 !important;
    margin: 0 !important;
    padding: 0.375rem 0.75rem !important;
    text-align: center !important;
    white-space: nowrap !important;
}